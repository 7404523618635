import React, { useState } from 'react';

import { Container, Input, Label } from './styles';

interface IInputCheckboxProps {
  id: string;
  checkedd?: boolean;
  onChange?(value?: any): void;
}

const InputCheckbox: React.FC<IInputCheckboxProps> = ({id, checkedd, onChange}) => {
  const [checked, setChecked] = useState(checkedd || false);

  return (
    <Container>
      <Input id={id} type="checkbox" onChange={onChange} onInput={() => setChecked(oldValue => !oldValue)} checked={checked} style={{ display: 'none' }} />
      <Label className={id} htmlFor={id}>
        <span>
          <svg width="12px" height="9px" viewBox="0 0 12 9">
            <polyline points="1 5 4 8 11 1"></polyline>
          </svg>
        </span>
      </Label>
    </Container>
  );
}

export default InputCheckbox;
