import React from 'react';

import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <p>
        <a href="https://www.pxtecnologia.com.br" target="_blank" rel="noreferrer">
          {new Date().getFullYear()} Desenvolvido por: Px Tecnologia
        </a>
      </p>
    </Container>
  );
}

export default Footer;
