import React, { useCallback, useState } from 'react';
import { FiMessageCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '../../services/api';

import { Container, FormModalReportContainer } from './styles';

interface IModalReportQuestionProps {
  questionId: string;
}

const ModalReportQuestion: React.FC<IModalReportQuestionProps> = ({ questionId }) => {

  const [loading, setLoading] = useState(false);
  const [reportedQuestions, setReportedQuestions] = useState<IModalReportQuestionProps[]>([]);

  const handleSubmitReport = useCallback(async (event: any) => {
    event.preventDefault();

    try {
      setLoading(true);
      const { reason } = event?.target?.elements;

      const response = await api.post(`/questions/${questionId}/report`, {
        reason: reason.value,
      });

      console.log('response.data', response.data);

      if (response.status === 200) {
        Swal.close();
        toast.success("👍 Questão reportada com sucesso!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
        });

        setReportedQuestions([...reportedQuestions, { questionId }]);
      }
    }
    catch (error) {
      setLoading(false);
      console.log('error', error);
      toast.error("👎 Ocorreu um erro ao reportar a questão!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
      });
    }
    finally {
      setLoading(false);
    }
  }, [questionId, reportedQuestions]);

  const handleReportQuestion = useCallback(() => {
    console.log('Reportar questão', questionId);

    let modalNotes = withReactContent(Swal);

    modalNotes.fire({
      html: (
        <FormModalReportContainer onSubmit={(e: any) => handleSubmitReport(e)}>
          <header>
            <p>Conte ao nosso suporte o que há de errado com a questão no campo abaixo:</p>
          </header>
          <main>
            <input type="hidden" name="questionId" value={questionId} />
            <label>Descreva o problema: </label>
            <textarea
              name="reason"
              autoFocus
              placeholder="Digite aqui..."
              minLength={10}
              maxLength={1500}
              required
            />
          </main>
          <footer>
            <button type="submit" disabled={loading}>{loading ? 'Aguarde...' : 'Reportar Questão'}</button>
          </footer>
        </FormModalReportContainer>
      ),
      background: "#fff",
      backdrop: "rgba(110, 97, 198, .1)",
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, [handleSubmitReport, questionId, loading]);

  return (
    <Container>
      {!reportedQuestions.find((question) => question.questionId === questionId) ? (
        <p>
          <FiMessageCircle /> Encontrou algum problema com a questão? <span onClick={handleReportQuestion}>Reporte para o suporte técnico.</span>
        </p>
      ) : (
        <p>
          🤙 Questão reportada com sucesso!
        </p>
      )}
    </Container>
  );
}

export default ModalReportQuestion;
