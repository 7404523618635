import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FiCheckSquare } from 'react-icons/fi';

import ModalTodo from './ModalTodo';

import { AllContainer, Container, TelegramButton } from './styles';
import { useLocation } from 'react-router-dom';
import { FaTelegramPlane } from 'react-icons/fa';
import TooltipAdapter from '../TooltipAdapter';

const TodoList: React.FC = () => {
  const location = useLocation();
  const localtionSplited = location.pathname.split('/')[1];
  const [hide, setHide] = useState(false);

  const notShow = useMemo(() => [
    'metas',
    'simulado',
    'cronograma'
  ], []);

  useEffect(() => {
    const find = notShow.find((item) => item === localtionSplited);
    if (find) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [localtionSplited, notShow]);

  const handleOpenTodoModal = useCallback(() => {
    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        <ModalTodo />
      ),
      background: '#090029',
      backdrop: '#1d23334e',
      showConfirmButton: false,
      showCloseButton: true,
      showCancelButton: false,
      allowOutsideClick: true,
      allowEscapeKey: true,
    });
  }, []);

  return (
    <AllContainer>
      <TooltipAdapter alt="Canal do Telegram" place="left">
        <TelegramButton href="https://t.me/educacione" target="_blank" rel="noreferrer" style={hide ? { display: 'none' } : {}}>
          <button><FaTelegramPlane color="#fff" size={20} strokeWidth={2.5} /></button>
        </TelegramButton>
      </TooltipAdapter>
      <TooltipAdapter alt="Lista de tarefas" place="left">
        <Container onClick={handleOpenTodoModal} style={hide ? { display: 'none' } : {}}>
          <button><FiCheckSquare color="#fff" size={20} strokeWidth={2.5} /></button>
        </Container>
      </TooltipAdapter>
    </AllContainer>
  );
}

export default TodoList;
