import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  width: 100%;

  > p {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: rgba(158, 164, 193, 0.584);
    flex-flow: wrap;
    line-height: 1.5;
    gap: 0.25rem;

    > span {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  `;

export const FormModalReportContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  > header {
    position: relative;
    display: flex;
    margin-bottom: 1rem;
    justify-content: flex-start;

    > p {
      color: #3e3f5e;
      font-weight: bold;
      text-align: left;
      width: 100%;
      display: flex;
      line-height: 1.5;
      font-size: 18px;
    }
  }

  > main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;

    > textarea {
      width: 100%;
      height: 100px;
      border: 1px solid #3e3f5e;
      color: #3e3f5e;
      border-radius: 6px;
      padding: 1rem;
      max-width: 100%;
      min-height: 120px;
      font-weight: 600;
    }

    > label {
      position: absolute;
      background: #fff;
      color: #3e3f5e;
      font-weight: 600;
      font-size: .8rem;
      transform: translate(10px, -10px);
      padding: 0 5px;
    }
  }

  > footer {
    position: relative;

    > button {
      width: 100%;
      background: #615dfa;
      border-radius: 6px;
      padding: 12px 0;
      z-index: 9999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      transition: all .2s ease-in-out;

      &:hover {
        background: #3531eb;
      }
    }
  }
`;
