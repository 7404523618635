import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FloatingButton = styled.button`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  height: 60px;
  width: 60px;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 2s ease 0s infinite normal none running pulse;
  cursor: pointer;
  transition: all 0.35s ease-in-out;
  z-index: 9999999999999;
  text-decoration: none;
`;

export const CardsSection = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  padding: 2rem;
  backdrop-filter: blur(25px);
  background: rgba(29, 35, 51, 0.306);
  overflow-y: auto;
  transition: all 0.35s ease-in-out;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out forwards;

  > footer {
    position: relative;
    width: 100%;
    max-width: 1280px;
    margin-bottom: 1rem;
    padding: 0 .75%;

    > h1 {
      font-size: 2rem;
      text-align: left;
      width: 100%;
    }
  }

  > aside {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 2rem;
    max-width: 1280px;
    z-index: 9;
    gap: 1rem;
    padding: 0 .75%;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    > h1 {
      color: #ffffff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;

      background: #00000010;
      border: 1px solid #ffffff10;
      backdrop-filter: blur(25px);
      padding: 0.5rem 1rem;
      border-radius: 8px;
      font-size: 14px;
      cursor: pointer;
    }

    > aside {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;

      @media (max-width: 768px) {
        width: 100%;
      }

      > select {
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 16px;
        border: 1px solid #6c23c0;
        color: #ffffff;
        border-radius: 8px;
        font-weight: 600;
        background: #00000020;
        backdrop-filter: blur(25px);
        width: 100%;

        &::placeholder {
          color: #ffffff95;
        }

        > option {
          color: #000;
          background: transparent;
        }
      }
    }

    > div {
      position: relative;
      width: 100%;

      > svg {
        position: absolute;
        top: 50%;
        left: 1.25rem;
        transform: translateY(-50%);
        color: #ffffff;
      }

      > input {
        position: relative;
        width: 100%;
        padding: 0.75rem 1rem 0.75rem 3rem;
        font-size: 16px;
        border: 1px solid #6c23c0;
        color: #ffffff;
        border-radius: 8px;
        font-weight: 600;
        background: #00000020;
        backdrop-filter: blur(25px);

        &::placeholder {
          color: #ffffff95;
        }
      }
    }

    > button {
      width: fit-content;
      text-wrap: nowrap;
      padding: 0.75rem 1rem;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  > main {
    position: relative;
    width: 100%;
    max-width: 1280px;
  }
`;


export const NotFoundFlashCards = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  > div {
    width: 100%;
    height: fit-content;
    min-height: 200px;
  }

  > h1 {
    font-size: 1.4rem;
    color: #ffffff;
  }

  > h2 {
    font-size: 5rem;
  }
`;


export const SlideContainer = styled.section`
  position: relative;
  width: 100%;
  max-width: 1280px;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease-in-out;

  > div {
    width: 100%;
    max-width: 1280px;
    border-radius: 8px;
  }
`;
