import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background: ${props => props.theme.colors.primary};
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-size: cover;
  overflow: hidden;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #3e3f5e !important;
  }

  /* MOBILE */
  @media (max-width: 480px) {
    background: ${props => props.theme.colors.primary};
  }
`;

export const ContentGrid1 = styled.div`
  display: flex;
  justify-content: center;

  /* TABLET */
  @media (max-width: 768px) {
    //grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    //grid-template-columns: repeat(1, 1fr);
  }
`;

export const LogoMobile = styled.div`
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  justify-content: center;

  > h2 {
    color: #3e3f5e;
    font-weight: 700;
    line-height: 1em;
    text-align: center;

    /* TABLET */
    @media (max-width: 1000px) {
      display: none;
    }
  }

  > img {
    width: 200px;
    display: none;

    /* TABLET */
    @media (max-width: 1000px) {
      display: block;
    }
  }
`;

export const Form = styled.form`
  padding: 1rem 0;
  margin-top: 1rem;
  border-radius: 7px;

  > div {
    margin: .5rem 0 0 0;

    > input {
      background: #fff;
      color: #3e3f5e;
    }

    > label {
      color: #3e3f5e;
      background: #fff;
    }

    > button {
      background: #099d72;
    }
  }

  > .forget {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;

    /* TABLET */
    @media (max-width: 477px) {
      > div {
        display: none;
      }
    }
  }

  > .forget p {
    cursor: pointer;
    color: #adafca;
    font-size: .875rem;
    font-weight: 700;
    line-height: 22px;
    text-decoration: none;
    transition: color .3s;
  }

  > .forget a:hover {
    color: #00c7d9;
  }
`;

/*  */
export const LandingInfo = styled.div`
  display: flex;
  width: 40%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-top: -7rem;

  /* TABLET */
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const LandingForm = styled.div`
  display: flex;
  width: 40%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  /* TABLET */
  @media (max-width: 1000px) {
    width: 90%;

    > div {

      > img {
        display: none;
      }
    }
  }

  .access-info {
    color: #fff;
    font-size: 10px;
    text-align: center;
    margin-top: 1rem;
  }

  .access-info > Button {
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: 1rem .4rem;
  }

  .lined-text {
    margin-top: 2rem;
    font-weight: 700;
    text-align: center;
    position: relative;

    color: #9aa4bf80;
    text-align: center;
    font-size: 10px;
  }
`;

export const ContentIntro = styled.div`
  position: relative;
  text-align: center;
  padding: 1rem 2rem;
  margin-right: 1rem;

  > h2 {
    margin-top: 36px;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
  }

  > h1 {
    font-size: 4.5rem;
    line-height: .8;
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
  }

  > p {
    max-width: 480px;
    margin: 2rem auto 0;
    font-size: .8rem;
    line-height: 1.3333333333em;
    font-weight: 500;
    color: #fff;

    > a {
      color: #ff0700;
      transition: all .2s ease-in-out;

      &:hover {
        color: #ff0700;
      }
    }
  }
`;

export const FormBox = styled.div`
  position: relative;
  max-width: 484px;
  padding: 3rem 4rem;
  border-radius: 12px;
  background: #fff !important;
  box-shadow: 0 0 60px 0 rgba(94,92,154,.12);

  > .rocket {
    position: absolute;
    top: -40px;
    left: -90px;
    z-index: 99999999999999999;
  }

  > h2 {
    color: #3e3f5e;
    font-weight: 700;
    line-height: 1em;
    text-align: center;
  }
`;
