import styled from 'styled-components';

interface IModalCropImageProps {
  barColor?: string;
  buttonColor?: string;
  backdropColor?: string;
  backdropOpacity?: number;
  backdropBlur?: string;
}

export const Container = styled.div<IModalCropImageProps>`
  position: fixed;
  z-index: 99999999999999999999999999999;
  background: ${props => props.backdropColor ? props.backdropColor : `#6e61c6${props.backdropOpacity ? props.backdropOpacity : 80}`};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);
`;

export const CloseButton = styled.div`
  position: absolute;
  z-index: 999999999999999999999999999999;
  background: #fff;
  width: 40px;
  height: 40px;
  top: 2rem;
  right: 2rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999999999999999999999999;
  background: linear-gradient(90deg, #615dfa, #8d7aff);
  position: absolute;
  margin: 0 auto;
  width: 100%;
  padding: 1rem 0;
  bottom: 0;

  > div {
    margin: 0 1rem;

    /* MOBILE */
    @media (max-width: 480px) {
      display: none;
    }

    > span {
      text-transform: uppercase;
      font-weight: 700;
      margin-right: 1rem;
      color: #fff;
    }
  }

  > footer {

    > button {
      border: none;
      border-radius: 8px;
      padding: .5rem 1.2rem;
      font-weight: bold;
      align-items: center;
      box-shadow: 4px 7px 12px 0 rgb(97 93 250 / 20%);
      font-size: 1rem;
      transition: all .3s ease-in-out;
      background: #03bb85;
      color: #fff;
    }
  }
`;
