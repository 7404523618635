import React, { useCallback, useEffect, useRef, useState } from 'react';

import api from '../../services/api';

import { useTheme } from '../../hooks/theme';
import { useAuth } from '../../hooks/auth';

import { FaGraduationCap } from 'react-icons/fa';
import { FiEdit, FiLogOut, FiMenu } from 'react-icons/fi';
import DarkModeToggle from "react-dark-mode-toggle";
import ModalEditProfile from './ModalEditProfile';
import ModalWelcome from './ModalWelcome';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import ModalCropImage from '../ModalCropImage';
import BigAvatar from '../BigAvatar';

import { Container, MainContent, Head, AsideMenuMobile, Menu, ArrowBack, Backdrop, HeaderActions, AvatarContainer, AbsEditContainer, ModalNewbie, FakeLink } from './styles';

import toBase64 from '../../utils/toBase64';
import { Link, useHistory, useLocation } from 'react-router-dom';
import menuLinks from '../../utils/menuLinks';
import Loader from '../Loader';
import Educards from '../Educards';
import Flashcard from '../Educards/Flashcard';

interface IGoalsProps {
  id: string;
  user_id: string;
  university_id: string;
  university: {
    id: string;
    name: string;
    acronym: string;
    created_at: string;
    updated_at: string;
  };
  career_id: string;
  career: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
  };
  score: number;
  is_priority: boolean;
  created_at: string;
  updated_at: string;
}

const MainHeader: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const localtionSplited = location.pathname;

  const { user, updateUser } = useAuth();
  const { toggleTheme, theme } = useTheme();

  // SideBar Menu Open
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const [loadingChangeAvatar, setLoadingChangeAvatar] = useState(false);
  const [profilePic, setProfilePic] = useState('');
  const [modalAvatar, setModalAvatar] = useState(false);
  const userAvatarInput = useRef<HTMLInputElement>({} as HTMLInputElement);

  const [first, setFirst] = useState<IGoalsProps>();
  const [second, setSecond] = useState<IGoalsProps>();

  const [headIsVisible, setHeadIsVisible] = useState(false);

  const [openModalFlascards, setOpenModalFlascards] = useState(false);

  const [openModalFlashcards, setOpenModalFlashcards] = useState(false);

  useEffect(() => {
    if (user?.first_name && user.role !== 'newbie' && !user?.language) {
      let MySwal = withReactContent(Swal);
      MySwal.fire({
        html: (
          <ModalWelcome user={user && user} updateUser={updateUser} />
        ),
        background: '#fff',
        backdrop: '#1d23334e',
        showConfirmButton: false,
        showCloseButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        padding: '32px 16px',
      });
    }
    if (user.role === 'newbie') {
      let MySwal = withReactContent(Swal);
      MySwal.fire({
        html: (
          <ModalNewbie>
            <h1>😢 <strong>Poxa, ainda não é possível fazer login na Plataforma...</strong></h1>
            <h2>Alguns dos principais motivos são:</h2>
            <p>
              <strong>1 - Compra em processamento:</strong><br /> Para pagamentos via boleto, por exemplo, <strong>o prazo para a sua compensação é de até 3 dias úteis</strong>. Isso acontece porque os bancos precisam repassar o valor pago para instituição que deve receber. Ou seja, se você pagar numa sexta-feira, o pagamento pode ser reconhecido até a próxima quarta-feira.
            </p>
            <p>
              <strong>2 - Saldo/Limite Insuficiente:</strong><br />
              Para sua compra ser aprovada você precisa ter saldo e limite suficiente em seu cartão de crédito conforme sua bandeira e políticas de uso.
            </p>
            <p>
              <strong>3 - Dados Incorretos:</strong><br /> Você pode ter digitado algum dado errado. Todos os dados solicitados são conferidos pelo sistema e algum número ou letra errada pode ser fator de recusar a sua compra. É importante que todos os dados cadastrados sejam verdadeiros.
            </p>
            <button onClick={() => Swal.close()}>ENTENDI</button>
          </ModalNewbie>
        ),
        background: '#fff',
        backdrop: '#236af020',
        showConfirmButton: false,
        showCloseButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        padding: '32px 16px',
      });
      return;
    }
  }, [user, updateUser]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 100) {
        setHeadIsVisible(true);
      }
      else {
        setHeadIsVisible(false);
      }
    });
  }, []);

  useEffect(() => {
    if (user.goals) {
      setFirst(user.goals.find((goal) => goal.is_priority));
      setSecond(user.goals.find((goal) => !goal.is_priority));
    }
  }, [user]);

  const handleSubmitAvatar = useCallback(async (file: File) => {
    try {
      setLoadingChangeAvatar(true);
      const formData = new FormData();

      formData.append('avatar', file);

      const response = await api.patch('/profiles/avatar', formData);
      console.log(response.data);

      updateUser({ ...user, avatar_url: response.data.avatar_url });

    } catch (err) {
      console.log(err);
    }
    finally {
      setLoadingChangeAvatar(false);
    }
  }, [updateUser, user]);

  const handleChangeAvatar = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setLoadingChangeAvatar(true);
    console.log(file);

    if (!file) {
      return;
    }

    const convertedFile = await toBase64(file);
    console.log(convertedFile);
    setProfilePic(convertedFile);
    setModalAvatar(true);
    setLoadingChangeAvatar(false);
  }, []);

  const handleEditProfile = useCallback(() => {
    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        <ModalEditProfile user={user && user} updateUser={updateUser} />
      ),
      background: '#fff',
      backdrop: '#1d23334e',
      showConfirmButton: false,
      showCloseButton: true,
      showCancelButton: false,
      allowOutsideClick: true,
      allowEscapeKey: true,
      padding: '32px 16px',
    });
  }, [user, updateUser]);

  return (
    <>
      <Container>
        <ModalCropImage
          image={profilePic}
          cropSize={{ width: 200, height: 200 }}
          isOpen={modalAvatar}
          setIsOpen={(value) => setModalAvatar(value)}
          onSubmit={handleSubmitAvatar}
          cropShape="round"
        />
        <Head className={headIsVisible ? 'fixed-head' : ''}>
          <main>
            <section>
              <HeaderActions onClick={showSidebar}>
                <FiMenu size={30} /> Menu
              </HeaderActions>
            </section>
            <section>
              <DarkModeToggle
                onChange={toggleTheme}
                checked={theme.title === 'dark'}
                size={65}
              />
            </section>
          </main>
        </Head>
        <MainContent style={!user?.goals ? { minHeight: '188px' } : {}}>
          {user?.goals ? <>
            <section>
              <div onClick={() => userAvatarInput.current.click()}>
                <input type="file" name="useravatar" ref={userAvatarInput} onChange={handleChangeAvatar} accept="image/png, image/jpeg" style={{ display: 'none' }} />
                {user.avatar_url && <BigAvatar avatar_url={user.avatar_url} />}
              </div>
              <aside>
                <h1>Olá, {user?.show_name}!</h1>
                {first && <p><FaGraduationCap size={18} /> {first.career.name}</p>}
                {second && <p><FaGraduationCap size={18} /> {second.career.name}</p>}
              </aside>
            </section>
            <section>
              <div>
                <ul>
                  <li><span>E-mail</span>{user?.email}</li>
                  <li>
                    <span>Lingua Estrangeira</span>
                    {user?.language ? user.language : 'Ainda não definida'}
                  </li>
                </ul>
                <ul>
                  {(first !== undefined || second !== undefined) && (
                    <li>
                      <span>Objetivo{first && second && <span>s</span>}</span>
                      {first && <p>{first.university.acronym}</p>}
                      {second && <p>{second.university.acronym}</p>}
                    </li>
                  )}
                  {(first !== undefined || second !== undefined) && (
                    <li>
                      <span>Nota{first && second && <span>s</span>} de corte</span>
                      {first && <p>{first.score / 100}</p>}
                      {second && <p>{second.score / 100}</p>}
                    </li>
                  )}
                </ul>
              </div>
            </section>
            <AbsEditContainer>
              <button onClick={handleEditProfile}><FiEdit size={20} strokeWidth={2.5} /></button>
            </AbsEditContainer>
          </>
            :
            (
              <Loader isFixed={false} />
            )
          }
        </MainContent>
      </Container>
      <AsideMenuMobile className={sidebar ? 'sidebar active' : 'sidebar'}>
        <ArrowBack onClick={showSidebar}>
          <span className="icon-icon-back-arrow"></span>
        </ArrowBack>
        <div>
          <AvatarContainer>
            <img src={user.avatar_url} alt="" />
          </AvatarContainer>
          <Link to="/" onClick={showSidebar}>Olá, {user.show_name}! <br /> <span>Bons estudos! 💜</span></Link>
        </div>
        <Menu>
          <h4>Menu</h4>
          {menuLinks.filter((item) => !item.isComponent).map((item, index) => (
            <div key={index} className={localtionSplited === item.location || (localtionSplited === '' && index === 0) ? 'active' : ''}>
                <Link data-tooltip={item.title} to={item.location} onClick={showSidebar} >
                  <item.icon size={20} strokeWidth={2.5} style={{ marginRight: '8px' }} />
                  {item.title}
                </Link>
            </div>
          ))}
          {/* <div>
            <Link to="/logout">
              <FiLogOut size={20} strokeWidth={2.5} style={{marginRight: '8px'}} />
              Sair
            </Link>
          </div> */}
        </Menu>
      </AsideMenuMobile>
      <Backdrop className={sidebar ? 'active' : 'no-active'} onClick={showSidebar} />
    </>
  );
}

export default MainHeader;
