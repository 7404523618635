import React from 'react';

import { Container } from './styles';
interface LoaderProps {
  isFixed?: boolean;
  borderRadius?: string;
  zIndex?: number;
  backgroundTransparent?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isFixed = false, borderRadius, zIndex, backgroundTransparent }) => {
  return (
    <Container isFixed={isFixed} borderRadius={borderRadius} zIndex={zIndex} backgroundTransparent={backgroundTransparent}>
      <div>
        <span />
      </div>
    </Container>
  );
};

export default Loader;
