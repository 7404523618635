import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin: -53px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  > header {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 9999;
    background: #00000060;
    user-select: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.25rem 1rem;
    border-radius: 16px;
    backdrop-filter: blur(7px) brightness(102%);

    @media (max-width: 768px) {
      zoom: 0.8;
    }

    > label {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      font-weight: 600;
    }
  }

  > a {
    position: relative;
    user-select: none;
    text-decoration: none;
    outline: none;
    border: none;
    background: none;
    overflow: hidden;
    height: 100%;
    width: 100%;

    > img {
      position: relative;
      width: 100%;
      height: 100%;
      user-select: none;
    }
  }
`;
