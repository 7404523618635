import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { v4 as uuid_v4 } from "uuid";

import InputAnimate from '../../../components/InputAnimate';
import InputSelectPureCss from '../../../components/InputSelectPureCss';

import { ModalDisciplineDetailsContent, Error } from './styles';
interface IModalDisciplineDetailsProps {
  setColumns(value: any): void;
}

const ModalDisciplineDetails: React.FC<IModalDisciplineDetailsProps> = ({setColumns}) => {
  const [discipline, setDiscipline] = useState('');
  const [dayOfWeek, setDayOfWeek] = useState('');
  const [cardColor, setCardColor] = useState('#6c23c0');
  const [validation, setValidation] = useState(false);

  const daysOfWeek = [
    {value: 'segunda', label: 'Segunda-feira'},
    {value: 'terca', label: 'Terça-feira'},
    {value: 'quarta', label: 'Quarta-feira'},
    {value: 'quinta', label: 'Quinta-feira'},
    {value: 'sexta', label: 'Sexta-feira'},
    {value: 'sabado', label: 'Sábado'},
    {value: 'domingo', label: 'Domingo'},
  ]

  const disciplines = useMemo(() => [
    {value: 'Artes', label: 'Artes', color: '#CDD4F8'},
    {value: 'Biologia', label: 'Biologia', color: '#99E0A9'},
    {value: 'Educação física', label: 'Educação física', color: '#F5C2FC'},
    {value: 'Filosofia', label: 'Filosofia', color: '#E3BFAA'},
    {value: 'Física', label: 'Física', color: '#82BF90'},
    {value: 'Geografia', label: 'Geografia', color: '#E3DFB1'},
    {value: 'História', label: 'História', color: '#E3CEAA'},
    {value: 'Língua estrangeira', label: 'Língua estrangeira (Espanhol/Inglês)', color: '#B2AFE0'},
    {value: 'Língua portuguesa', label: 'Língua portuguesa', color: '#D3C2FC'},
    {value: 'Literatura', label: 'Literatura', color: '#DDCCF7'},
    {value: 'Matemática', label: 'Matemática', color: '#9299E0'},
    {value: 'Redação', label: 'Redação', color: '#8FD5E3'},
    {value: 'Química', label: 'Química', color: '#A1EDB3'},
    {value: 'Sociologia', label: 'Sociologia', color: '#E3D5B1'},
    {value: 'Tecnologia da informação e comunicação', label: 'Tecnologia da informação e comunicação', color: '#CEAFE0'},
  ], []);


  useEffect(() => {
    const fd = disciplines.find((item) => item.label === discipline);
    console.log(fd);
    if(!fd) {
      return;
    }
    setCardColor(fd.color);

  }, [discipline, disciplines]);

  const handleAddToColumn = useCallback((discipline: string, dayOfWeek: string, cardColor: string) => {
    if(discipline !== '' && dayOfWeek !== '') {
      const newColumn = {
        id: uuid_v4(),
        content: discipline,
        color: cardColor
      };

      setColumns((columns: any) => ({
        ...columns,
        [dayOfWeek]: {
          ...columns[dayOfWeek],
          items: [
            ...columns[dayOfWeek].items,
            newColumn
          ]
        }
      }));

      console.log(newColumn);
      Swal.close();
    } else {
      setValidation(true);
    }

  }, [setColumns]);

  return (
    <ModalDisciplineDetailsContent>
      <section>
        <h1>+ Adicionar disciplina</h1>
        <form onSubmit={(event) => {event.preventDefault()}}>
          <div>
            <InputSelectPureCss
              placeholder="Selecione"
              labelText="Disciplina"
              label="Disciplina"
              name="disciplina"
              options={disciplines}
              showLabel
              setValue={setDiscipline}
            />
          </div>
          <div>
            <InputSelectPureCss
              placeholder="Selecione"
              labelText="Dia da Semana"
              label="Dia da Semana"
              name="dia_da_semana"
              options={daysOfWeek}
              showLabel
              setValue={setDayOfWeek}
            />
          </div>
          <div>
            <InputAnimate type="color" label="Cor (opcional)" value={cardColor} onChange={(e) => setCardColor(e.target.value)} style={{height: 55, cursor: 'pointer'}} />
          </div>
          {validation && <Error>Por favor, preencha os campos "disciplina" e "dia da semana".</Error>}
          <div>
            <button onClick={() => handleAddToColumn(discipline, dayOfWeek, cardColor)}>Cadastrar disciplina!</button>
          </div>
        </form>
      </section>
    </ModalDisciplineDetailsContent>
  );
}

export default ModalDisciplineDetails;
