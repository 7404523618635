import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  > h2 {
    text-align: left;
    margin: -1rem 0 2rem 0;
    color: #3e3f5e;
    text-transform: uppercase;
  }

  > form {
    position: relative;

    > button {
      width: 100%;
      background: #615dfa;
      border-radius: 6px;
      padding: 12px 0;
      z-index: 9999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      transition: all .2s ease-in-out;
      margin: 1rem 0 0 0;

      &:hover {
        background: #3531eb;
      }
    }

    > div {
      position: relative;
      text-align: left;
      margin: 1rem 0;

      > p {
        color: #3e3f5e;
        margin: -0.5rem 0 0 0;

        > a {
          text-decoration: none;
          display: flex;
          align-items: center;
          transition: all .2s ease-in-out;

          &:hover {
            color: #232554;
          }

          > svg {
            margin-right: 4px;
          }
        }

      }

      > label {
        position: absolute;
        background: #fff;
        color: #3e3f5e;
        font-weight: 600;
        font-size: .8rem;
        transform: translate(11px, -59px);
        padding: 0 5px;
      }

      .react-select {

        input {
          padding: 7.5px 0 !important;
          font-weight: 600 !important;
          color: #3e3f5e !important;
          margin-left: 4px !important;

          &:after {
            background: blue !important;
          }
        }

        .css-1s2u09g-control {
          border-color: #3e3f5e;
          border-radius: 6px;
        }

        .css-14el2xx-placeholder {
          margin-left: 7px;
          font-weight: 600;
          color: #b1b3cd;
          font-size: 14px;
        }
      }

      input {
        padding: 20px 0;
      }

      > div {

        > input, select {
          padding: .75rem 1rem;
          font-size: 14px;
          background: #fff;
          border: 1px solid #3e3f5e;
          color: #3e3f5e;
          border-radius: 6px;
          font-weight: 600;

          &:focus {
            border-color: #615dfa;
          }

          ::placeholder {
            color: #adafca;
            opacity: 1;
            text-transform: none !important;
          }
        }

        > label {
          background: #fff;
          color: #3e3f5e;
        }

        > span {
          background: transparent;
          color: #3e3f5e;
        }
      }
    }
  }
`;

export const ValidationError = styled.div`
  color: red;
  margin: 1rem 0 0 0;

  > span  {
    font-weight: bold;
  }
`;

export const Pesos = styled.main`
  text-align: left;
  margin: -1rem 0 0 0;

  > h2 {
    color: #3e3f5e;
    font-weight: 600;
    font-size: .8rem;
  }

  > aside {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    > p {
      color: #3e3f5e;
      background: #fff;
      padding-right: .3rem;

      &:after {
        position: absolute;
        content: ' . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
        width: 100%;
        height: 5px;
        z-index: -1;
      }
    }

    > div {
      background: #fff;

      > select {
        padding: 0.1rem 0.4rem;
        font-size: 14px;
        background: #fff;
        border: 1px solid #3e3f5e;
        color: #3e3f5e;
        border-radius: 6px;
        font-weight: 600;
        margin-left: 0.4rem;
        z-index: 1;

        &:before {
          position: absolute;
          content: ' ';
          width: 15px;
          height: 5px;
          z-index: -1;
          background: red;
        }
      }
    }
  }

  > div {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    margin: .5rem 0;

    > div {
      position: relative;
      width: 100%;
      display: grid;
      z-index: 0;

      > label {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #3e3f5e90;
        font-weight: 600;
        font-size: .8rem;
        border: 1px solid #cccccc80;
        border-radius: 6px;
        padding: 8px 10px 8px 31px;
        width: 100%;
        transition: all .25s ease-in-out;
        cursor: pointer;
      }

      > input {
        position: absolute;
        margin-right: .5rem;
        top: calc(50% - 8px);
        left: 10px;
        width: 12px;
        height: 12px;
        visibility: hidden;
        cursor: pointer;
        z-index: 1;

        &:after {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #d1d3d1;
          content: '';
          display: inline-block;
          visibility: visible;
          border: 2px solid #fff;
        }


        &:checked + label {
          background: #615dfa;
          border: 1px solid #615dfa;
          color: #fff;
        }

        &:checked:after {
          content: '✔';
          color: #fff;
          background-color: #542395;
          visibility: visible;
          border: 2px solid #6966f399;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 7px;
          content-visibility: auto;
        }
      }
    }
  }
`;
