import React, { useCallback, useEffect, useState, useRef } from 'react';

import api from '../../../services/api';

import { FiTrash } from 'react-icons/fi';

import Input from '../../Input';

import { Container, Task, ValidationError } from './styles';
import LoaderPureCss from '../../LoaderPureCss';

interface ITodoListProps {
  id: string;
  description: string;
  background: string;
  is_completed: boolean;
}

const ModalTodo: React.FC = () => {
  const [todoList, setTodoList] = useState<ITodoListProps[]>([]);
  const [inputTaskValue, setInputTaskValue] = useState('');
  const [todoLoading, setTodoLoading] = useState(false);
  const [validation, setValidation] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const hadleAddTaskToTodo = useCallback(async (e: any, value: string) => {
    e.preventDefault();

    if(value === '' || value.length < 5) {
      setValidation(true);
      return;
    }

    console.log('Input value submited: ', value);

    try {
      setTodoLoading(true);
      const response = await api.post('/todos', {
        description: value,
      });

      console.log(response.data);

      setTodoList([
        ...todoList,
        {
          id: response.data.id,
          description: value,
          background: '#36c56c',
          is_completed: false,
        }
      ]);

      setValidation(false);
      setInputTaskValue('');

      setTimeout(() => {
        scrollRef.current?.scrollTo(0, (scrollRef.current?.scrollHeight + 45));
      }, 1);
    }
    catch (error) {
      console.log(error)
    }
    finally {
      setTodoLoading(false);
    }

  }, [todoList]);

  const handleResetTodo = useCallback(async() => {
    if (window.confirm("Deseja limpar sua lista de tarefas?")) {
      try {
        const response = await api.delete('/todos');
        console.log(response.data);
        console.log('Todo List clear!');

        setTodoList([]);
        setInputTaskValue('');
        setValidation(false);
      } catch (error) {
        console.log(error)
      }
    }
  }, []);

  const handleCompletedTask = useCallback(async (task: ITodoListProps) => {
    try {
      const response = await api.patch(`/todos/${task.id}`);
      console.log(response.data);
      console.log('tarefa atualizada!');


      task.is_completed = !task.is_completed;
      setTodoList([...todoList]);
    } catch (error) {
      console.log(error)
    }
  }, [todoList]);

  const handleRemoveFromTodo = useCallback(async (index: number, id: string) => {
    console.log('id: ', id);
    console.log('todolist: ', todoList);

    // CHECAR AQUI 77777777777777777777777777777777
    if (window.confirm("Deseja excluir esta tarefa?")) {

      try {
        const response = await api.delete(`/todos/${id}`);
        console.log(response.data);

        console.log('tarefa removida com sucesso!');

        const newTodoList = [...todoList];
        newTodoList.splice(index, 1);
        setTodoList(newTodoList);

        setInputTaskValue('');
        setValidation(false);
      } catch (error) {
        console.log(error);
      }

    }
  }, [todoList]);

  useEffect(() => {
    async function loadData() {
      try {
        setTodoLoading(true);

        const response = await api.get('/todos');
        console.log(response.data);
        setTodoList(response.data);
      } catch (error) {
        console.log(error);
      }
      finally {
        setTodoLoading(false);
      }
    }

    loadData();
  }, []);

  return (
    <Container>
      {todoLoading && <LoaderPureCss isFixed={false} background="#090029" />}
      <h2>Quais são seus planos para hoje? 😊</h2>

      <form onSubmit={(e) => {hadleAddTaskToTodo(e, inputTaskValue)}}>
        <div>
          <header style={todoList.length !== 0 ? { padding: '0 0.5rem'} : {}}>
            <Input type="text" placeholder="Descreva sua atividade" label="Adicione uma tarefa" value={inputTaskValue} onChange={(e) => setInputTaskValue(e.target.value)} />
            <button type="submit">Adicionar</button>
          </header>
          <footer>
            {validation && <ValidationError>⛔ A descrição deve conter pelo menos 5 caracteres.</ValidationError>}
          </footer>
        </div>
      </form>

      {todoList.length > 0 && <h4>Metas do dia: <button onClick={handleResetTodo}>limpar todas as tarefas?</button></h4> }
      <main
        ref={scrollRef}
        style={
          todoList.length === 0 ?
          {overflowY: 'hidden', border: '1px solid #3e3f5e', justifyContent: 'center', alignItems: 'center', marginTop: '1rem'}
        :
          {minHeight: '150px', justifyContent: 'flex-start', alignItems: 'start'}} className={todoList.length > 0 ? 'fade-overlay' : ''}
        >
        {todoList.length > 0 ?
          todoList.map((task, index) => (
            <Task key={index} background={task.is_completed ? '#36c56c' : '#ff0000'} completed={task.is_completed}>
              <main onClick={() => handleCompletedTask(task)}>
                <label htmlFor={String(index)}>
                  <input type="checkbox" id={task.id} defaultChecked={task.is_completed} />
                  {task.description}
                </label>
              </main>
              <aside onClick={() => handleRemoveFromTodo(index, task.id)}>
                <button>
                  <FiTrash color="#fff" size={16} strokeWidth={2.5} />
                </button>
              </aside>
            </Task>
          ))
          :
          'Poxa, você ainda não cadastrou nenhuma tarefa. 😢'
        }
      </main>
    </Container>
  );
}

export default ModalTodo;
