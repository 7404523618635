import React from 'react';
import menuLinks from '../../utils/menuLinks';
import { useLocation } from 'react-router-dom';
import MenuItemFake from '../MenuItemFake';
import MenuItem from '../MenuItem';
import styled from 'styled-components';

const AsideMenuItems: React.FC = () => {
  const location = useLocation();
  const localtionSplited = location.pathname;

  return (
    <Wrapper>
      {menuLinks.map((link, index) => (
        link.isComponent ? (
          <li key={index}>
            <MenuItemFake
              title={link.title}
              location={link.location}
              onClick={() => {
                window.location.href = `/cronograma/${'artes'}/?openFlashcards`;
              }}
              badge={link?.badge}
              icon={link.icon}
              className={link.location === localtionSplited ? 'active' : ''}
            />
          </li>
        ) : (
          <li key={index}>
            <MenuItem
              title={link.title}
              location={link.location}
              badge={link?.badge}
              icon={link.icon}
              className={link.location === localtionSplited ? 'active' : ''}
            />
          </li>
        )
      ))}
    </Wrapper>
  );
}

export default AsideMenuItems;


const Wrapper = styled.ul`
  position: sticky;
  top: 1rem;
  border-radius: 10px;
  background: ${props => props.theme.colors.bgBoxMenu};
  box-shadow: ${props => props.theme.colors.shadowBox};
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  > li {
    margin: .25rem 0;
    display: flex;

    a {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      width: 100%;
      text-decoration: none;
      color: ${props => props.theme.colors.textColor};
      transition: all .25s ease-in-out;
      padding: 1rem;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;

      &:hover {
        color: ${props => props.theme.colors.primary};
        background: ${props => props.theme.colors.primary};
      }

      > svg {
        margin-right: .5rem;
      }
    }
  }

`;
