import styled from 'styled-components';

export const AllContainer = styled.section`
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 999999999;
`;

export const Container = styled.div`
  position: relative;
  height: 60px;
  width: 60px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 2s ease 0s infinite normal none running pulse;
  cursor: pointer;
  transition: all .35s ease-in-out;
  z-index: 99999999999999;

  /* TABLET */
  @media (max-width: 700px) {
    display: none;
  }

  &:hover {
    background: ${props => props.theme.colors.primary}90;
  }

  @keyframes pulse {
    0% {
      box-shadow: #6c23c080 0px 0px 0px 0px;
    }
    70% {
      box-shadow: rgb(255 255 255 / 0%) 0px 0px 0px 1rem;
    }
    100% {
      box-shadow: rgb(255 255 255 / 0%) 0px 0px 0px 0px;
    }
  }

  > button {
    display: flex;
    background: transparent;
    margin: 0;
    padding: 0;
  }
`;

export const TelegramButton = styled.a`
  position: relative;
  height: 60px;
  width: 60px;
  background-color: #2699d1;
  border-radius: 50%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 2s ease 0s infinite normal none running pulse;
  cursor: pointer;
  transition: all .35s ease-in-out;
  z-index: 99999999999999;
  text-decoration: none;


  &:hover {
    background: #2699d190;
  }

  @keyframes pulse {
    0% {
      box-shadow: #6c23c080 0px 0px 0px 0px;
    }
    70% {
      box-shadow: rgb(255 255 255 / 0%) 0px 0px 0px 1rem;
    }
    100% {
      box-shadow: rgb(255 255 255 / 0%) 0px 0px 0px 0px;
    }
  }

  > button {
    display: flex;
    background: transparent;
    margin: 0;
    padding: 0;
  }
`;
