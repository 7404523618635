const light = {
  title: 'light',

  colors: {
    primary: '#6c23c0',
    secondary: '#adafca',
    tertiary: '#00cc91',

    white: '#e6e2c8',
    black: '#ede4ff',
    black2: '#6c23c0',
    gray: '#adafca',
    darkGray: '#293249',

    danger: '#ff0000',
    success: '#77ffaa',
    info: '#6c23c0',
    warning: '#ff6961',
    alteranative: '#6c23c0',
    alteranative2: '#73eaf6',

    textColor: '#3e3f5e',
    secundaryTextColor: '#3e3f5e',

    bgContent: '#f8f8fb',
    bgContent2: '#fff',
    bgBox: '#ffffff',
    bgAnimation: '#eaedf1',
    shadowBox: '0 0 40px 0 rgba(94,92,154,.06)',

    bgBoxMenu: '#ffffff',
    shadowMenu: '3px 5px 30px 0 rgba(94,92,154,.12)',

    shadowAlternative: 'none',

    headerGradient: 'radial-gradient(circle,rgb(255 255 255) 27%,rgb(238 230 255) 39%)',

    progressBarGradient: 'linear-gradient(to right, #8e0eba 20%, #77ffaa)',

    textAreaWizColor: '#fcfcfd',

    separator: '#adafca30',
  }
};

export default light;
