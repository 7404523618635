import React, { useCallback, useEffect, useState } from 'react';

import { User } from '../../../hooks/auth';

import Select, { components } from 'react-select';
import Swal from 'sweetalert2';

import Input from '../../Input';

import { Container, ValidationError } from './styles';
import api from '../../../services/api';
import LoaderPureCss from '../../LoaderPureCss';
import { toast } from 'react-toastify';
import confetti from 'canvas-confetti';

interface IModalWelcomeProps {
  user: User;
  updateUser(user: User): void;
}

const ModalWelcome: React.FC<IModalWelcomeProps> = ({ user, updateUser }) => {
  const [nickname, setNickname] = useState<string | null>(null);
  const [foreignLanguage, setForeignLanguage] = useState<any>([]);

  const [validation, setValidation] = useState('');
  const [loading, setLoading] = useState(false);


  const opts = [
    { value: 'Inglês', label: 'Inglês' },
    { value: 'Espanhol', label: 'Espanhol' },
  ];

  const handleSubmitForm = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log('foreignLanguage: ', foreignLanguage)

    if(foreignLanguage.length <= 0) {
      setValidation('Você precisa selecionar o campo Língua Estrangeira!');

      return;
    }

    try {
      setLoading(true);
      const response = await api.put(`/profiles`, {
        nickname: nickname === '' ? null : nickname,
        language: foreignLanguage,
        password: null,
        password_confirmation: null,
        old_password: null
      });

      console.log(response.data);

      updateUser({
        ...user,
        nickname: response.data.nickname,
        show_name: response.data.show_name,
        language: response.data.language,
      });

      Swal.close();

      toast.success(`🎉 Olá, ${response.data.show_name}! Seja muito bem-vindo(a) a Plataforma Educacione! `, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        confetti({
          particleCount: 100,
          startVelocity: 30,
          spread: 360,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2
          }
        });
      }, 0);

      setTimeout(() => {
        confetti({
          particleCount: 100,
          startVelocity: 30,
          spread: 360,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2
          }
        });
      }, 0);

      setTimeout(() => {
        confetti({
          particleCount: 300,
          startVelocity: 30,
          spread: 360,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2
          }
        });
      }, 0);

      setTimeout(() => {
        confetti({
          particleCount: 150,
          startVelocity: 60,
          spread: 360,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2
          }
        });
      }, 0);

      setTimeout(() => {
        confetti({
          particleCount: 100,
          startVelocity: 80,
          spread: 360,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2
          }
        });
      }, 0);

    } catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }, [nickname, foreignLanguage, user, updateUser]);

  useEffect(() => {
    console.log(user);

    if(user.nickname) {
      setNickname(user.nickname);
    } else {
      setNickname(null);
    }
  }, [user]);

  const customStyles = {
    menu: (base: any) => ({
      ...base,
      marginTop: 6,
      background: '#fff',
      color: '#3e3f5e',
      fontWeight: 600,
    }),
    menuList: (base: any) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    }),
    multiValue: (base: any) => ({
      ...base,
      background: '#615dfa',
      color: '#fff',
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      color: '#fff',
      fontWeight: 500,
    }),
  };

  return (
    <Container>
      {loading && <LoaderPureCss isFixed={false} />}
      <header>
        <h2>Olá, <span>{user.first_name}</span>! ✨</h2>
        <p>
          Antes de comerçarmos, precisamos que você complete o seu cadastro.
          Caso prefira, <strong>escolha um apelido</strong> ou a forma carinhosa que você gosta de ser
          chamado e, obrigatóriamente, a <strong>Língua Estrangeira</strong> preferencial. <br />
          <i style={{fontSize: '13px'}}>(você poderá altertar essas informações a qualquer momento).</i>
        </p>
      </header>

        <form onSubmit={(e) => {handleSubmitForm(e)}} autoComplete="off">
          <div>
            <Input
              type="text"
              placeholder="Como você gostaria de ser chamado?"
              label="Apelido (opcional)"
              defaultValue={user?.nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
          </div>
          <div>
            <Select
              placeholder="Selecione..."
              className="react-select"
              noOptionsMessage={() => foreignLanguage.length >= 1 ? 'Limite máximo atingido' : 'Nenhuma opção encontrada'}
              //@ts-ignore
              options={foreignLanguage.length > 0 ? [] : opts}
              loadingMessage={() => 'Carregando...'}
              isClearable
              isMulti
              defaultValue={() => {
                if(user?.language) {
                  setForeignLanguage(user.language);

                  return {
                    value: user.language, label: user.language
                  }
                }
              }
            }
            onChange={(selected, triggeredAction) => {
                if (triggeredAction.action === 'clear' || triggeredAction.action === 'remove-value') {
                  setForeignLanguage('');
                }
                else {
                  //setForeignLanguage((oldForeignLanguage: any) => [...oldForeignLanguage, selected])}
                  //@ts-ignore
                  setForeignLanguage(selected[0].value);
                }
              }
            }
            styles={customStyles}
          />
            <label>Língua estrangeira</label>
          </div>
          <button type="submit">Completar cadastro</button>

          <footer>
            {validation !== '' && <ValidationError>⛔ <strong>{validation}</strong></ValidationError>}
          </footer>
      </form>
    </Container>
  );
}

export default ModalWelcome;
