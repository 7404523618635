import React, { useEffect, useState } from 'react';

import ContentGrid from "../../components/ContentGrid";
import Footer from '../../components/Footer';
import PlyrAdapter from '../../components/PlyrAdapter';

import { Container, GridContainer, MainContainer, MainContent, PlayerContainer, PuBliCard, Footer as FooterInner } from './styles';

import AsideMenuItems from '../../components/AsideMenuItems';

const Redacione: React.FC = () => {
  const [videoPromoUrl, setPromoUrl] = useState<string>('');

  const LINK_DISCOUT_PROMO = 'https://checkout.redacione.com.br/f6016371-4bb0-4413-a48e-2f55434c6b2d/?coupon=EDUCACIONE';

  useEffect(() => {
    setTimeout(() => {
      setPromoUrl('849990044');
    }, 1000);
  }, []);

  return (
    <Container>
      <GridContainer>
        <aside>
          <AsideMenuItems />
        </aside>
        <main>
          <MainContainer>
            <PuBliCard>
              <a href={LINK_DISCOUT_PROMO} target="_blank" rel="noreferrer">
                <img src={`https://redacione-api.s3.sa-east-1.amazonaws.com/discounts/plataforma-redacione.jpg`} alt="Plataforma educacione" />
              </a>
              <p className="blink-animation">Clique no banner acima e aproveite o desconto especial <span style={{ fontSize: '16px' }}>👆</span></p>
            </PuBliCard>

            <MainContent>
              <h1>Sobre a Plataforma</h1>
              <p>Pela primeira vez, a maior estratégia de redação do Brasil é apresentada detalhadamente em um curso.</p>
              <p>O <strong>Método Redacione</strong> é o resultado da combinação de duas das maiores metodologias de escrita do Brasil: o Método de Modelos Prontos, desenvolvido por Felipe Araújo, e a estratégia Redação Nota 1000, criada por PoxaLulu.</p>
              <p>Com esse curso, nós o capacitaremos a construir modelos de redação e, como brinde, você terá acesso ao <strong>MODELO REDACIONE</strong>: um modelo exclusivo, desenvolvido por nós, totalmente validado e que se adequa a todos os temas.</p>
              <p>O nosso principal objetivo é tirar o peso da redação dos seus ombros, permitindo que você direcione todo o seu foco para as outras áreas da prova. Acreditamos que ao dominar essa estratégia, você estará preparado para enfrentar o maior desafio do ENEM: O TEMPO!</p>
              <p>Com essa estratégia, você não apenas terá a liberdade de concentrar-se plenamente nas provas objetivas do ENEM, mas também adquirirá a habilidade de escrever um texto completo (+900) em até 30 minutos.</p>
              <PlayerContainer>
                {videoPromoUrl.length > 0 && (<PlyrAdapter
                  source={{
                    type: 'video',
                    sources: [
                      {
                        provider: 'vimeo',
                        src: (videoPromoUrl),
                      }
                    ]
                  }}
                />)}
              </PlayerContainer>
              <p style={{ marginBottom: `2rem` }}>
                <strong>Com o curso nós GARANTIMOS uma nota igual ou superior a 900 na redação do ENEM!</strong> Caso você siga todas as orientações e estratégias ensinadas no curso e não obtenha uma nota +900 na redação do ENEM, a Redacione garante a devolução integral do valor pago pelo curso.
              </p>

              <h3>Desconto exclusivo para você aluno Educacione</h3>
              <p>
                Como prova do meu comprometimento com você, aluno(a) da Educacione, estou oferecendo um desconto exclusivo. Agora, você pode aproveitar a plataforma com um incrível desconto de <strong>+77%</strong>.
                Não perca essa oportunidade! Garanta já o seu desconto exclusivo e prepare-se com a Redacione. Junte-se a nós nessa jornada e faça com que esse seja o ano da sua aprovação!
              </p>
            </MainContent>
            <FooterInner>
              <aside>
                <div>
                  <img src={`https://redacione-api.s3.sa-east-1.amazonaws.com/default/felipe-araujo-redacione.jpg`} alt="Felipe Araújo e Poxalulu - Redacione" />
                </div>
                <div>
                  <h5>Felipe Araújo</h5>
                  <p>Idealizador das Plataformas Educacione e Redacione</p>
                </div>
              </aside>
              <main>
                <a href={LINK_DISCOUT_PROMO} target="_blank" rel="noreferrer">Assine com desconto</a>
              </main>
            </FooterInner>
          </MainContainer>
        </main>
      </GridContainer>
      <ContentGrid>
        <Footer />
      </ContentGrid>
    </Container>
  );
}

export default Redacione;
