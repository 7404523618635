import React from 'react';

import ContentGrid from "../../components/ContentGrid";
import Footer from '../../components/Footer';

import { Container, GridContainer } from './styles';

import PlayerLives from '../../components/PlayerLives';
import AsideMenuItems from '../../components/AsideMenuItems';


const Educacione: React.FC = () => {

  return (
    <Container>
      <GridContainer>
        <aside>
          <AsideMenuItems />
        </aside>
        <main>
          <PlayerLives />
        </main>
      </GridContainer>
      <ContentGrid>
        <Footer />
      </ContentGrid>
    </Container>
  );
}

export default Educacione;
