import styled from 'styled-components';

export const Container = styled.div`

  .plyr--full-ui input[type=range] {
    color: #ffffff;
  }

  .plyr__control:hover {
    background-color: #ffffff50;
  }

`;
