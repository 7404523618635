import React, { useCallback, useEffect, useState } from 'react';

import api from '../../services/api';

import { FiChevronDown, FiClock, FiShare, FiTrash2 } from 'react-icons/fi';

import ContentGrid from "../../components/ContentGrid";
import Footer from '../../components/Footer';
import HeaderInfo from '../../components/HeaderInfo';

import { Container, ContentGrid3, GridContainer, NoticeCard, NoticesContainer, NoticesHeader, NoticesStudentContainer, Showing } from './styles';

import { API_S3 } from '../../utils/api_s3';
import { useTheme } from '../../hooks/theme';
import TooltipAdapter from '../../components/TooltipAdapter';
import Loader from '../../components/Loader';
import { formatDistance, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useAuth } from '../../hooks/auth';
import Pagination from '../../components/Pagination';
import { toast } from 'react-toastify';
import AsideMenuItems from '../../components/AsideMenuItems';

export interface IDataProps {
  [key: string]: INoticeProps[]
}

interface INoticeProps {
  id: string;
  title: string;
  category: string;
  provider: string;
  link: string;
  description: string;
  published_at: string;
  thumbnail?: string;
  created_at: string;
  updated_at: string;
}

interface IRecordsProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: (INoticeProps)[];
}

type NewsCategory = 'educacao' | 'ciencia' | 'tecnologia' | 'mundo' | 'politica';
type TabActive = 'student' | 'admin';

const AtualizaEDU: React.FC = () => {
  const { theme } = useTheme();
  const { user } = useAuth();

  const [category, setCategory] = useState<NewsCategory>('educacao');
  const [tabActive, setTabActive] = useState<TabActive>('student');
  const [notices, setNotices] = useState<IDataProps>({} as IDataProps);
  const [records, setRecords] = useState<IRecordsProps>({} as IRecordsProps);
  const [group, setGroup] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingScroll, setLoadingScroll] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);


  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const [responseNews, responseNotices] = await Promise.all([
          api.get(`/news`, {
            params: {
              category: category,
            }
          }),
          api.get(`/notices`, {
            params: {
              pageIndex: currentPage - 1,
              pageSize: 9,
              search: '',
            }
          })
        ]);
        console.log('response.data: ', responseNews.data);
        setNotices(responseNews.data);

        console.log('responseNotices.data: ', responseNotices.data);
        setRecords(responseNotices.data);

        //pagination
        setShowingTo(responseNotices.data.showingTo);
        setShowingFrom(responseNotices.data.showingFrom);
        setMaxPages(responseNotices.data.maxPages);
        setTotalRecords(responseNotices.data.totalRecords);
      } catch (error) {
        console.log('error: ', error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [currentPage, category, tabActive]);

  const handleScroll = useCallback(() => {
    if (loadingScroll) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setLoadingScroll(true);
      setTimeout(() => {
        setGroup(prevGroup => prevGroup + 1);
        setLoadingScroll(false);
      }, 1200);
    }

  }, [loadingScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleAddToPlatform = useCallback(async (notice: INoticeProps) => {
    try {
      if (window.confirm(`Deseja adicionar a notícia "${notice.title}" à sua plataforma?`) === false) {
        return;
      }

      console.log('notice: ', notice.id);

      const response = await api.post(`/notices`, {
        external_id: notice.id,
        title: notice.title,
        category: notice.category,
        provider: notice.provider,
        link: notice.link,
        thumbnail: notice.thumbnail,
        description: notice.description,
        published_at: notice.published_at,
      });

      console.log('response.data: ', response.data);

      toast.success("Notícia adicionada à plataforma do aluno!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
      });

    } catch (error: any) {
      console.log('error: ', error);

      if (error.response.data.error === 'notice-already-exists') {

        toast.error("Esta notícia já foi adicionada à plataforma do aluno!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
        });
      }
    }
  }, []);

  const handleRemoveToPlatform = useCallback(async (notice: INoticeProps) => {
    try {
      if (window.confirm(`Deseja excluir a notícia "${notice.title}" da sua plataforma?`) === false) {
        return;
      }

      const response = await api.delete(`/notices/${notice.id}`);

      console.log('response.data: ', response.data);

      toast.success("Notícia excluída com sucesso!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
      });

      setRecords((prevRecords) => {
        const newRecords = prevRecords.records?.filter((record) => record.id !== notice.id);

        return {
          ...prevRecords,
          records: newRecords,
        };
      });

    } catch (error: any) {
      console.log('error: ', error);

      toast.error("Ops! algo deu errado ao tentar excluir a notícia.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
      });
    }
  }, []);

  return (
    <Container>
      <GridContainer>
        <aside>
          <AsideMenuItems />
        </aside>
        <main >
          <header>
            <HeaderInfo supTitle="Notícias" title={`AtualizaEDU`} margin="0 0 1rem 0" showSendButton={false} />
            {tabActive === 'admin' && (
              <div>
                <select
                  onChange={e => setCategory(e.target.value as NewsCategory)}
                  defaultValue={category}
                >
                  <option value="educacao">Educação</option>
                  <option value="ciencia">Ciência</option>
                  <option value="tecnologia">Tecnologia</option>
                  <option value="mundo">Mundo</option>
                  <option value="politica">Política</option>
                </select>
                <FiChevronDown />
              </div>
            )}
          </header>

          {user.role === 'administrator' && (
            <NoticesHeader>
              <header>
                <ul>
                  <li>
                    <button
                      type="button"
                      className={tabActive === 'student' ? 'active' : ''}
                      onClick={() => {
                        setTabActive('student');
                        setCurrentPage(1);
                      }}
                    >
                      Notícias da Plataforma
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={tabActive === 'admin' ? 'active' : ''}
                      onClick={() => {
                        setTabActive('admin');
                        setCurrentPage(1);
                      }}
                    >
                      Todas as Notícias
                    </button>
                  </li>
                </ul>
              </header>
            </NoticesHeader>
          )}

          {tabActive === 'student' && (
            <>
              <NoticesStudentContainer style={loading ? { height: '300px', overflow: 'hidden' } : {}}>
                {loading && <Loader isFixed={false} borderRadius="16px" />}

                {records.records && records.records?.map((notice, index) => {

                  const { thumbnail } = notice;
                  const defaultImage = thumbnail === null ? (theme.title === 'light' ? `${API_S3}/logo/image-placeholder-light.svg` : `${API_S3}/logo/image-placeholder-dark.svg`) : thumbnail;

                  return (
                    <NoticeCard key={index} background={defaultImage}>
                      {user.role === 'administrator' && (
                        <header>
                          <TooltipAdapter alt="Remover da Plataforma" place="top" cursor="pointer" onClick={() => handleRemoveToPlatform(notice)}>
                            <FiTrash2 color="#ff0000" />
                          </TooltipAdapter>
                        </header>
                      )}
                      <a href={notice.link} target="_blank" rel="noreferrer">
                        <main />
                      </a>
                      <footer>
                        <a href={notice.link} target="_blank" rel="noreferrer">{notice.title}</a>
                        <p>{notice.description}</p>
                        <span><FiClock /> há {formatDistance(parseISO(notice.published_at), new Date(), { locale: ptBR })}</span>
                      </footer>
                    </NoticeCard>
                  )
                })}
              </NoticesStudentContainer>

              <ContentGrid3>
                <Showing>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} nótícias disponíveis`}</Showing>
                {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
              </ContentGrid3>
            </>
          )}

          {tabActive === 'admin' && (
            <NoticesContainer style={loading ? { height: '300px', overflow: 'hidden' } : {}}>
              {loading && <Loader isFixed={false} borderRadius="16px" />}
              {Object.entries(notices).slice(0, (group + 1) * 6).map(([date, newsArray], index) => (
                <aside key={index}>
                  <header>
                    <h2>{date}</h2>
                  </header>
                  <main>
                    {newsArray.map((notice, index) => {
                      const { thumbnail } = notice;
                      const defaultImage = thumbnail === null ? (theme.title === 'light' ? `${API_S3}/logo/image-placeholder-light.svg` : `${API_S3}/logo/image-placeholder-dark.svg`) : thumbnail;

                      return (
                        <NoticeCard key={index} background={defaultImage} >
                          {user.role === 'administrator' && (
                            <header>
                              <TooltipAdapter alt="Compartilhar na plataforma" place="top" cursor="pointer" onClick={() => handleAddToPlatform(notice)}>
                                <FiShare />
                              </TooltipAdapter>
                            </header>
                          )}
                          <a href={notice.link} target="_blank" rel="noreferrer">
                            <main />
                          </a>
                          <footer>
                            <a href={notice.link} target="_blank" rel="noreferrer">{notice.title}</a>
                            <p>{notice.description}</p>
                            <span><FiClock /> há {formatDistance(parseISO(notice.published_at), new Date(), { locale: ptBR })}</span>
                          </footer>
                        </NoticeCard>
                      )
                    })}
                  </main>
                </aside>
              ))}

              {loadingScroll && <p>Carregando...</p>}
            </NoticesContainer>
          )}


        </main>
      </GridContainer>
      <ContentGrid>
        <Footer />
      </ContentGrid>
    </Container>
  );
}

export default AtualizaEDU;
