import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import Slider from "react-slick";

import api from '../../services/api';

import HeaderInfo  from "../../components/HeaderInfo";
import Footer from '../../components/Footer';
import MainHeaderMovie from '../../components/MainHeaderMovie';
import MovieCard from '../../components/MovieCard';

import { Container, GridContainer, SliderContainer, SampleNextArrow, SamplePrevArrow } from './styles';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';

interface IMovieProps {
  id: string;
  channels?: {
    id: string;
    name: string;
    link: string;
    logo: string;
    logo_url: string;
    created_at: string;
    updated_at: string;
  }[];
  genres?: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
  }[];
  reviews?: {
    id: string;
    user_id: string;
    movie_id: string;
    rating: number;
    notes: string;
    created_at: string;
    updated_at: string;
  }[];
  name: string;
  tagline: string;
  duration: number;
  age_group: '18' | '16' | '14' | '12' | '10' | 'L';
  vote_average: number;
  vote_count: number;
  year: number;
  description: string;
  cover: string;
  backdrop: string;
  trailer: string;
  created_at: string;
  updated_at: string;
  duration_formatted: string;
}

const Movie: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);

  const [movie, setMovie] = useState<IMovieProps>({} as IMovieProps);
  const [suggestions, setSuggestions] = useState<IMovieProps[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get(`/movies/${id}`);

        console.log('====== MOVIE ======');
        console.log(response.data);
        setMovie(response.data);
      } catch (error) {
        console.log(error);

        history.push('/eduflix');
        toast.error('🤷‍♂️ Desculpe, a página que você tentou acessar não foi encontrada!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [id, history]);


  useEffect(() => {
    async function loadData() {
      try {
        setLoadingSuggestions(true);
        const response = await api.get(`/movies/random`, {
          params: { quantity: 10 }
        });

        console.log('====== MOVIES SUGGESTIONS ======');
        console.log(response.data);
        setSuggestions(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingSuggestions(false);
      }
    }

    loadData();
  }, [id]);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow>{/* <FiArrowLeft size={22} /> */}</SamplePrevArrow>,
    nextArrow: <SampleNextArrow>{/* <FiArrowRight size={22} /> */}</SampleNextArrow>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return(
    <>
      {loading && <Loader isFixed={true} borderRadius="0px" />}
      <MainHeaderMovie
        id={movie.id}
        age_group={movie.age_group}
        rating={movie.vote_average}
        name={movie.name}
        tagline={movie.tagline}
        year={movie.year}
        trailer={movie.trailer}
        cover_url={`https://www.themoviedb.org/t/p/w1920_and_h800_multi_faces${movie.backdrop}`}
        poster_url={`https://www.themoviedb.org/t/p/w300_and_h450_bestv2${movie.cover}`}
        description={movie.description}
        duration={movie.duration_formatted}
        genres={movie?.genres}
        channels={movie?.channels}
        review={movie?.reviews && movie.reviews[0]}
      />
      <Container style={loading ? {height: '0px', overflow: 'hidden'} : {}}>
        <GridContainer>
          <HeaderInfo supTitle="" title="Outras sugestões" margin="0 0 1rem 0" showSendButton={false} />
          <main>
            <section>
              <SliderContainer>
                {suggestions && <Slider {...settings}>
                  {suggestions.map((suggest) => (
                    <div key={suggest.id}>
                      <MovieCard
                        id={suggest.id}
                        name={suggest.name}
                        age_group={suggest.age_group}
                        cover={`https://image.tmdb.org/t/p/w400${suggest.cover}`}
                        duration={movie.duration}
                        rating={movie.vote_average/20}
                        year="2019"
                        height="270px"
                      />
                    </div>
                  ))}
                </Slider>}
              </SliderContainer>
            </section>
            <section>
              <Footer />
            </section>
          </main>
        </GridContainer>
      </Container>
    </>
  );
}

export default Movie;
