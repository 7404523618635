import React from 'react';

import Plyr, { PlyrProps } from "plyr-react";
import "plyr-react/plyr.css";

import { Container } from './styles';

interface PlyrAdapterProps extends PlyrProps {

}

const PlyrAdapter: React.FC<PlyrAdapterProps> = ({ ...rest }) => {
  return (
    <Container>
      <Plyr
        {...rest}
      />
    </Container>
  );
}

export default PlyrAdapter;
