import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  > header {
    h1 {
      color: #fff;
    }
  }

  > main {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  > footer {
    width: 100%;
    margin: 1rem 0;
    flex-direction: column;

    > textarea {
      width: 100%;
      background: transparent;
      border: 1px solid #615dfa;
      border-radius: 4px;
      color: #fff;
      padding: .85rem 1rem;
      min-height: 100px;
      resize: none;

      ::placeholder {
        color: #ccc;
      }

      &::-webkit-scrollbar {
        visibility: hidden;
        width: 4px;
        height: 4px;
      }

      &:hover::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        opacity: 1;
        transition: all .3s ease-in-out;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: #615dfa9e;
        border-radius: 10px;
      }

      &:hover::-webkit-scrollbar-thumb:hover{
      }

      &:hover::-webkit-scrollbar-track{
        border-radius: 10px;
        box-shadow: inset 7px 10px 12px #615dfa9e;
      }
    }

    > button {
      width: 100%;
      background: rgb(3,187,133);
      color: rgb(255,255,255);
      border: none;
      border-radius: 4px;
      padding: 0.9rem 0px;
      text-align: center;
      justify-content: center;
      font-weight: bold;
      align-items: center;
      font-size: 0.75rem;
      box-shadow: rgb(94 92 154 / 6%) 0px 0px 40px 0px;
      text-transform: uppercase;
      margin: .5rem 0 -1rem 0;
    }
  }
`;
