import styled from 'styled-components';

import cover from '../../assets/inner-cover.png';

export const Container = styled.div`
  grid-area: MH;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  position: relative;
  width: 100%;
  //height: 600px;
  background: rgb(104,33,158);
  background-image: url(${cover}), ${props => props.theme.colors.headerGradient};
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;

  /* TABLET */
  @media (max-width: 768px) {
    background-image: ${props => props.theme.colors.headerGradient};
  }

  &::after {
    content: '';
    width: 100%;
    height: 200px;
    left: 0;
    bottom: -200px;
    position: absolute;
    background-image: linear-gradient(to top, transparent, ${props => props.theme.colors.black} 100%);
    z-index: 0;
  }
`;

export const MainContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  border-radius: 10px;
  margin-top: 340px;
  margin-bottom: -50px;
  padding: 1rem 2rem;
  background: ${props => props.theme.colors.bgBox};
  box-shadow: ${props => props.theme.colors.shadowBox};
  z-index: 9;

  /* TABLET */
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 16px 16px 0 16px;
  }

  > section:nth-child(1) {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;

    /* TABLET */
    @media (max-width: 1100px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &::after {
      background-color: #adafca30;
      bottom: 0;
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: .075rem;

      /* TABLET */
      @media (max-width: 768px) {
        display: none;
      }
    }

    > aside {
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 1rem;

      /* LAPTOP */
      @media (max-width: 1100px) {
        padding: 0;
        justify-content: center;
        display: flex;
        align-items: center;
      }

      > h1 {
        color: ${props => props.theme.colors.textColor};
        font-size: 32px;
        line-height: 1.4;
        font-weight: 700;
        margin: .5rem 0;

        /* MOBILE */
        @media (max-width: 500px) {
          font-size: 18px;
        text-align: center;
        }
      }

      > p {
        color: ${props => props.theme.colors.secondary};
        font-size: 16px;
        display: flex;
        align-items: center;
        font-weight: 600;

        > svg {
          margin-right: .3rem;
        }
      }
    }

    > div {

      > main {
        margin-top: -50px;
        position: relative;
      }


    }
  }

  > section:nth-child(2) {
    position: relative;
    width: 100%;

    /* MOBILE */
    @media (max-width: 620px) {
      display: none;
    }

    > div {
      align-items: start;
      display: flex;
      flex-wrap: wrap;

      > ul {
        list-style: none;
        padding-left: 30px;
        margin: 16px 0;
        padding: 0 0 0 4rem;

        > li {
          color: ${props => props.theme.colors.textColor};
          font-size: 18px;
          font-weight: 600;

          > span {
            color: ${props => props.theme.colors.secondary};
            display: block;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;

            > span {
              text-transform: uppercase;
            }
          }

          > p {
            color: ${props => props.theme.colors.textColor};
            font-size: 18px;
            font-weight: 600;
            display: inline;

            &:after {
              content: ', ';
            }

            &:last-child {

              &:after {
                display: none;
              }
            }
          }

          > button {
            background: none;
            border: none;

            > svg {
              color: ${props => props.theme.colors.info};
            }
          }
        }

        > li:nth-child(2) {
          margin-top: 1rem;
        }
      }

    }
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h4 {
    padding: 0 0 5px 7px;
    color: #adafca;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
  }

  > div {
    margin: .25rem 0;
    width: 100%;
    padding: 12px 1rem;
    display: flex;
    align-items: center;

    &.active {
      border-radius: 12px;
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.primary};
      box-shadow: ${props => props.theme.colors.shadowBox};

      > a, span {
        color: #fff;
      }
    }

    > a {
      text-decoration: none;
      transition: all .2s ease-in-out;
      font-weight: 700;
      text-align: left;
      display: flex;
      align-items: center;
      color: ${props =>props.theme.colors.textColor};

      > span {
        padding-right: 1.5rem;
        font-size: 20px;
        color: ${props =>props.theme.colors.primary};
      }
    }
  }
`;


export const Head = styled.div`
  position: relative;
  width: 100%;
  top: -1px;
  display: flex;
  justify-content: center;
  padding: 1rem 0;

  /* TABLET */
  @media (max-width: 768px) {
    &.fixed-head {
      position: fixed;
      z-index: 99999999;
      padding: 1rem;
      background: ${props =>props.theme.colors.primary};
      animation: menu .3s;
      animation-iteration-count: 1;
      animation-fill-mode:forwards;
      opacity: 0;
      box-shadow: 0 0 40px 0 ${props =>props.theme.colors.bgBoxMenu};


      @keyframes menu {
        0% {
          top: -85px;
          opacity: 0;
        }
        100%{
          top: -1px;
          opacity: 1;
        }
      }

      > main {

        > section {

          > div, svg {

            color: #fff !important;
          }
        }
      }
    }
  }


  > main {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > section:nth-child(1) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    > section:nth-child(2) {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      font-size: 0.7rem;
      text-transform: uppercase;
      font-weight: 700;
      box-sizing: content-box;

      > button {
        overflow: visible !important;
      }
    }
  }
`;

export const AsideMenuMobile = styled.div`
  background: ${props => props.theme.colors.bgBoxMenu};
  width: 300px;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  z-index: 99999999999999;
  padding: 2rem 1rem;
  transition: .5s ease-in-out;
  left: -300px;
  top: 0;

  &.sidebar.active {
    left: 0;
  }

  &::-webkit-scrollbar {
    visibility: hidden;
    width: 4px;
    height: 4px;
  }

  &:hover::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    opacity: 1;
    transition: all .3s ease-in-out;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: #cccccc80;
    border-radius: 10px;
  }

  &:hover::-webkit-scrollbar-thumb:hover {

  }

  &:hover::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #cccccc10;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 1rem;

    > a {
      margin-left: .5rem;
      color: ${props => props.theme.colors.textColor};
      font-weight: 700;
      line-height: 1;
      text-decoration: none;

      > span {
        margin-top: 4px;
        color: #8f91ac;
        font-size: .75rem;
        font-weight: 500;
      }
    }
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(110, 97, 198, .5);
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;
  &.active {
    visibility: visible;
    opacity: 1;
    transition: all .3s ease-in-out;
  }
`;

export const AvatarContainer = styled.div`
  margin-right: 0.5rem;
  background: #54179c4a;
  border-radius: 50%;
  box-shadow: 3px 5px 20px 0 rgb(0 0 0 / 12%);
  padding: 4px;
  display: flex;

  > img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;

export const ArrowBack = styled.button`
  position: absolute;
  height: 15px;
  width: 15px;
  font-size: 14px;
  top: 1.2rem;
  right: 1.2rem;
  border: none;
  background: none;
  color: ${props => props.theme.colors.textColor};
`;

export const HeaderActions = styled.div`
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
  font-weight: 600;
  color: ${props => props.theme.colors.textColor};

  &:hover {
    opacity: .8;
  }

  > svg {
    color: ${props => props.theme.colors.textColor};
    cursor: pointer;
  }
`;

  export const AbsEditContainer = styled.div`
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;

    > button {
      border: none;
      background: ${props => props.theme.colors.bgBox};
      display: flex;
      padding: 8px;
      border-radius: 50%;


      > svg {
        color: ${props => props.theme.colors.info};
      }
    }

  `;

export const ModalNewbie = styled.div`

  > h1 {
    color: #3e3f5e;
    font-size: 16px;
    text-align: left;
    margin-bottom: 1rem;
  }

  > h2 {
    color: #3e3f5e;
    font-size: 14px;
    text-align: left;
  }

  > p {
    color: #3e3f5e;
    text-align: left;
    margin: 1rem 0 0 .5rem;
  }

  > button {
    width: 100%;
    background: #615dfa;
    border-radius: 6px;
    padding: 12px 0;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    transition: all .2s ease-in-out;
    margin-top: 1rem;
  }
`;

export const FakeLink = styled.div`
  position: relative;
  color: ${props => props.theme.colors.textColor};
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;

  > div {
    margin: .25rem 0;
    width: 100%;
    padding: 12px 1rem;
    display: flex;
    align-items: center;

    &.active {
      border-radius: 12px;
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.primary};
      box-shadow: ${props => props.theme.colors.shadowBox};

      > button {
        color: #fff !important;
        cursor: pointer;
      }
    }

    > a {
      text-decoration: none;
      transition: all .2s ease-in-out;
      font-weight: 700;
      text-align: left;
      display: flex;
      align-items: center;
      color: ${props =>props.theme.colors.textColor};

      > span {
        padding-right: 1.5rem;
        font-size: 20px;
        color: ${props =>props.theme.colors.primary};
      }
    }
  }
`;
