import React from 'react';
import { IconBaseProps } from 'react-icons';

import { Links, Badge } from './styles';

interface MenuItemProps {
  title: string;
  location: string;
  icon: React.ComponentType<IconBaseProps>;
  className?: string;
  badge?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({
  location,
  title,
  icon: Icon,
  className,
  badge,
}) => {

  return (
    <Links to={location} className={className}>
      {Icon && <Icon size={20} strokeWidth={2.5} />}
      {title} {badge && <Badge>{badge}</Badge>}
    </Links>
  );
};

export default MenuItem;
