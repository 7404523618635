import styled from 'styled-components';
import { Link } from 'react-router-dom';


export const Container = styled(Link)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: ${props => props.theme.colors.shadowBox};
  border-radius: 10px;
  padding: 1.5rem;
  background: ${props => props.theme.colors.bgContent2};
  transition: all .2s ease-in-out;
  cursor: pointer;
  text-decoration: none;

  &:hover {

    > header {

      > img {
        transform: translateY(-10px);
      }
    }
  }

  > header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    width: 100%;

    > img {
      width: auto;
      height: 200px;
      margin-top: -35px;
      transition: all .2s ease-in-out;
    }

    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 .4rem;
      margin-bottom: .2rem;
      margin-top: 1rem;

      > h1 {
        color: ${props => props.theme.colors.textColor};
        font-weight: 600;
        font-size: 14px;
        margin-right: .5rem;
      }

      > span {
        font-weight: 600;
        font-size: 14px;
        color: ${props => props.theme.colors.textColor};
      }
    }

  }

  > footer {

    > div {

      > div {

        > h2 {
          display: none !important;
        }
      }
    }
  }
`;
