import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import api from '../../services/api';

import ContentGrid from "../../components/ContentGrid";
import WeekCronogram from '../../components/WeekCronogram';
import HeaderInfo from "../../components/HeaderInfo";
import MovieCard from '../../components/MovieCard';
import Footer from '../../components/Footer';

import { Container, GridContainer, MovieSuggestionGrid } from './styles';

import Loader from '../../components/Loader';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import ModalHomeAlert from '../../components/ModalHomeAlert';
import AsideMenuItems from '../../components/AsideMenuItems';

export interface IRandomMovies {
  id: string;
  name: string;
  duration: number;
  age_group: "18" | "16" | "14" | "12" | "10" | "L";
  vote_average: number;
  vote_count: number;
  year: number;
  description: string;
  cover: string;
  backdrop: string;
  trailer: string;
  created_at: string;
  updated_at: string;
  duration_formatted: string;
}

const Dashboard: React.FC = () => {
  const location = useLocation();
  const localtionSplited = location.pathname;

  const [loading, setLoading] = useState(false);
  const [randomMovies, setRandomMovies] = useState<IRandomMovies[]>([]);

  const [showPromotionModal, setShowPromotionModal] = useState(() => {
    const isShow = localStorage.getItem('@Redacione:showPromotionModal');

    if (isShow) {
      return false;
    }

    return true;
  });

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/movies/random', {
          params: { quantity: 5 }
        });

        console.log(response.data);
        setRandomMovies(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    if (!showPromotionModal) return;

    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        <ModalHomeAlert>
          <header>
            <label htmlFor="notShowAgain">
              <input type="checkbox" name="notShowAgain" id="notShowAgain" onChange={() => {
                if (localStorage.getItem('@Redacione:showPromotionModal')) {
                  localStorage.removeItem('@Redacione:showPromotionModal');
                } else {
                  localStorage.setItem('@Redacione:showPromotionModal', 'true');
                }
              }} />
              Não exibir novamente
            </label>
          </header>
          <a href="./redacione">
            <img src="https://redacione-api.s3.sa-east-1.amazonaws.com/discounts/redacione-promocao.jpg" alt="Promoção Redacione" />
          </a>
        </ModalHomeAlert>
      ),
      background: 'transparent',
      backdrop: '#1d23334e',
      showConfirmButton: false,
      showCloseButton: true,
      showCancelButton: false,
      allowOutsideClick: true,
      allowEscapeKey: true,
      padding: 32,
    });
  }, [showPromotionModal]);

  return (
    <Container>
      <GridContainer>
        <aside>
          <AsideMenuItems />
        </aside>
        <main style={loading ? { maxHeight: '350px', overflow: 'hidden' } : {}}>
          {loading && <Loader isFixed={false} zIndex={9999999} />}
          <WeekCronogram />
          <HeaderInfo supTitle="Sugestões" title="Filmes & Séries" margin="0 0 2rem 0" showSendButton={false} />
          <MovieSuggestionGrid>
            {randomMovies.map((movie) => (
              <MovieCard
                key={movie.id}
                id={movie.id}
                name={movie.name}
                age_group={movie.age_group}
                cover={movie.cover}
                duration={movie.duration}
                rating={movie.vote_average}
                year={movie.year}
                height="270px"
              />
            ))}
          </MovieSuggestionGrid>
        </main>
      </GridContainer>
      <ContentGrid>
        <Footer />
      </ContentGrid>
    </Container>
  );
}

export default Dashboard;
