import styled from 'styled-components';

export const ModalChromeAndW7Detect = styled.div`
  color: #3e3f5e;

  > h1 {
    margin-top: 1rem;
    text-align: center;
  }

  > p {
    text-align: justify;
  }

  > a {
    &.download {
      width: 100%;
      background: #615dfa;
      border-radius: 6px;
      padding: 12px 0;
      z-index: 9999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      transition: all .2s ease-in-out;
      margin: 1rem 0 .5rem 0;
      text-decoration: none;
    }

    color: #ced3e1;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    width: 100%;
    text-decoration: none;
    text-align: center;
    transition: color 0.3s ease 0s;
    font-weight: 600;
  }
`;


export const ModalSignatureExpired = styled.a`
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  width:100%;
  display: block;
  color: #fff;
  text-decoration: none;

  > img {
    width: 100%;
    cursor: pointer;
  }

  > footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: red;
    padding: 0.5rem;

    > h1 {
      font-size: 12px;
    }
  }
`;
