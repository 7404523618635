import React, { useCallback, useState } from 'react';

import { Container, LabelnputColors, ValidationError } from './styles';

import LoaderPureCss from '../../../components/LoaderPureCss';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { HeaderSelectContainer } from '../ModalCreateFlashCard/styles';
import { ISubjectsProps } from '../../../Pages/Cronograma';

interface IModalEditFlashCard {
  subjects: ISubjectsProps[];
  slug: string;
  flashcardId: string;
  closeModal: () => void;
  front?: string;
  back?: string;
  color?: string;
}

const ModalEditFlashCard: React.FC<IModalEditFlashCard> = ({ slug, flashcardId, closeModal, front: frontProp, back: backProp, color, subjects }) => {
  const [loading, setLoading] = useState(false);

  const [validation, setValidation] = useState(false);

  const [selectedColor, setSelectedColor] = useState(color);

  const [selectedSubject, setSubjectSelected] = useState(() => {
    const currentSubject = subjects.find((subject) => subject.slug === slug);
    return currentSubject;
  });

  const [frontState, setFrontState] = useState(frontProp || '');
  const [backPropState, setBackState] = useState(backProp || '');

  const handleSubmitForm = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);
      const data = new FormData(e.currentTarget);
      const { front, back, color } = {
        front: data.get('front'),
        back: data.get('back'),
        color: data.get('color')
      };

      console.log('data: ', { front, back, color });

      if (!front || !back) {
        setValidation(true);
        return;
      }

      const response = await api.put(`/flashcards/${flashcardId}`, {
        front: front,
        back: back,
        color: color || selectedColor,
        subject_id: selectedSubject?.id,
      });

      console.log('response: ', response);

      toast.success('Flashcard cadastrado com sucesso!');
      window.location.href = `/cronograma/${selectedSubject?.slug}/?openFlashcards`;

      closeModal();
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao cadastrar flashcard');
    }
  }, [flashcardId, selectedColor, closeModal, selectedSubject]);

  const inputColors = [
    '#6C23C0',
    '#FF0000',
    '#FF7F00',
    '#00FF00',
    '#0000FF',
    '#4B0082',
    '#f24ee5',
  ];

  return (
    <Container style={loading ? { minHeight: '360px' } : {}}>
      {loading && <LoaderPureCss isFixed={false} />}

      {!loading && (
        <form onSubmit={(e) => handleSubmitForm(e)}>
          <HeaderSelectContainer>
            <h4>Disciplina:</h4>
            <select
              name="cluster"
              id="cluster"
              onChange={(e) => {
                setSubjectSelected(subjects.find((subject) => subject?.id === e.target.value));
              }}
            >
              <option value="">Selecione...</option>
              {subjects.map((subject) => {
                const currentSubject = subjects.find((subject) => subject.id === selectedSubject?.id);

                return (
                  <option
                    key={subject.id}
                    value={subject.id}
                    selected={subject.id === currentSubject?.id}
                  >
                    {subject.name}
                  </option>
                )
              })}
            </select>
          </HeaderSelectContainer>
          <div>
            <h4>Frente:</h4>
            <textarea
              name="front"
              id="front"
              placeholder="Digite a frente do flashcard"
              value={frontState}
              onChange={(e) => setFrontState(e.target.value)}
              required
            >
            </textarea>
          </div>

          <div>
            <h4>Verso:</h4>
            <textarea
              name="back"
              id="back"
              placeholder="Digite o verso do flashcard"
              value={backPropState}
              onChange={(e) => setBackState(e.target.value)}
              required
            >
            </textarea>
          </div>

          <div>
            <h4>Cor do flashcard:</h4>
            <footer>
              {inputColors.map((color, index) => (
                <LabelnputColors
                  key={index}
                  style={{ backgroundColor: color }}
                  isSelected={color === selectedColor}
                  color={color}
                  onClick={() => setSelectedColor(color)}
                >
                  <input
                    type="radio"
                    name="color"
                    value={color}
                    checked={color === selectedColor}
                    onChange={(e) => { setSelectedColor(e.target.value) }}
                  />
                </LabelnputColors>
              ))}
            </footer>
          </div>

          <button type="submit">{loading ? 'Cadastrando...' : 'Editar flashcard'}</button>

          <footer>
            {validation && <ValidationError>⛔ <span>Por favor, preencha os campos frente e verso.</span></ValidationError>}
          </footer>
        </form>
      )}
    </Container>
  );
}

export default ModalEditFlashCard;
