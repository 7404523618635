import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import api from '../../services/api';

import ContentGrid  from "../../components/ContentGrid";
import HeaderInfo  from "../../components/HeaderInfo";
import MovieCard from '../../components/MovieCard';
import Footer from '../../components/Footer';

import { Container, GridContainer, MovieSuggestionGrid, ContentGrid3, Showing } from './styles';

import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';
import AsideMenuItems from '../../components/AsideMenuItems';

interface IMoviesProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: {
    id: string;
    channels?: {
      id: string;
      name: string;
      link: string;
      logo: string;
      created_at: string;
      updated_at: string;
    }[];
    genres?: {
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
    }[];
    reviews?: {
      id: string;
      user_id: string;
      movie_id: string;
      rating: number;
      notes: string;
      created_at: string;
      updated_at: string;
    }[];
    name: string;
    tagline: string;
    duration: number;
    age_group: '18' | '16' | '14' | '12' | '10' | 'L';
    vote_average: number;
    vote_count: number;
    year: number;
    description: string;
    cover: string;
    backdrop: string;
    trailer: string;
    created_at: string;
    updated_at: string;
    duration_formatted: string;
  }[];
}

const Movies: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [movies, setMovies] = useState<IMoviesProps>({} as IMoviesProps);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/movies', {
          params: { pageIndex: currentPage - 1, pageSize: 15, }
        });

        console.log(response.data);
        setMovies(response.data);

        //pagination
        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [currentPage]);

  return(
    <Container>
      <GridContainer>
        <aside>
          <AsideMenuItems />
        </aside>
        <main>
          <section style={loading ? {height: '200px', overflow: 'hidden' } : {}}>
            {loading && <Loader isFixed={false} />}
            <HeaderInfo supTitle="Sugestões de" title="Filmes & Séries" margin="0 0 2rem 0" showSendButton={false} />
            <MovieSuggestionGrid>
              {movies.records?.map((movie) => (
                <MovieCard
                  key={movie.id}
                  id={movie.id}
                  name={movie.name}
                  age_group={movie.age_group}
                  cover={`https://image.tmdb.org/t/p/w400${movie.cover}`}
                  duration={movie.duration}
                  rating={movie.vote_average}
                  year={movie.year}
                  height="270px"
                />
              ))}
            </MovieSuggestionGrid>
          </section>
          <ContentGrid3>
            <Showing>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} filmes disponíveis`}</Showing>
            {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
          </ContentGrid3>
        </main>
      </GridContainer>

      <ContentGrid>
        <Footer />
      </ContentGrid>
    </Container>
  );
}

export default Movies;
