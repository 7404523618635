import React, { useCallback, useContext, useState } from 'react';
import { Card, CardInner, CardFront, CardBack, CardWrapper, BtnRemoveContainer } from './styles';
import { MdDeleteForever, MdEdit } from "react-icons/md";
import { ThemeContextDnd } from '../..';
import api from '../../../../services/api';


interface Flashcard2Props extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  front: React.ReactNode;
  back: React.ReactNode;
  className?: string;
  color: string;
}

const Flashcard2: React.FC<Flashcard2Props> = ({ id, front, back, className, color }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [startDragPosition, setStartDragPosition] = useState<{ x: number, y: number } | null>(null);

  const style = useContext(ThemeContextDnd);

  const handleMouseDown = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setStartDragPosition({ x: event.clientX, y: event.clientY });
  }, []);

  const handleMouseUp = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (!startDragPosition) return;

    const threshold = 20;
    const moveX = Math.abs(event.clientX - startDragPosition.x);
    const moveY = Math.abs(event.clientY - startDragPosition.y);

    if (moveX < threshold && moveY < threshold) {
      setIsFlipped(!isFlipped);
    }
    setStartDragPosition(null);
  }, [startDragPosition, isFlipped]);

  const removeCard = useCallback(async (id: string) => {
    const confirm = window.confirm('Tem certeza que deseja excluir esse card?');
    setIsFlipped((prev) => !prev);

    if (confirm) {
      console.log('Card removido');
      try {
        const response = await api.delete(`/flashcards/${id}`);
        console.log('response: ', response);

        // implementar a remoção do card da lista

      } catch (error) {
        console.log('error: ', error);
      }
    }
  }, []);

  const editCard = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    setIsFlipped((prev) => !prev);

    alert('Editando card');
  }, []);

  return (
    <CardWrapper className={className} style={style as any}>
      <Card onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} >
        <CardInner isFlipped={isFlipped}>
          <CardFront style={{ background: color }}>
            {/* <BtnRemoveContainer>
              <MdEdit size={20} onClick={(e: any) => editCard(e)} />
              <MdDeleteForever size={20} onClick={(e: any) => removeCard(id)} />
            </BtnRemoveContainer> */}
            {front}
          </CardFront>
          <CardBack style={{ background: color+'50' }}>
            {/* <BtnRemoveContainer>
              <MdEdit size={20} onClick={(e: any) => editCard(e)} />
              <MdDeleteForever size={20} onClick={(e: any) => removeCard(e)} />
            </BtnRemoveContainer> */}
            {back}
          </CardBack>
        </CardInner>
      </Card>
    </CardWrapper>
  );
};

export default Flashcard2;
