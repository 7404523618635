import React from 'react';
import { Grid } from './styles';

import MainHeader from '../MainHeader';
import Content from '../Content';
import TodoList from '../TodoList';

interface ILayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <>
      <MainHeader />
      <Grid>
        <Content>
          {children}
        </Content>
      </Grid>
      <TodoList />
    </>
  );
}

export default Layout;
