import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../services/api';

import { User } from '../../../hooks/auth';

import Select, { components } from 'react-select';

import Input from '../../../components/Input';

import { Container, Pesos, ValidationError } from './styles';
import { FiChevronRight } from 'react-icons/fi';
import { isNumber } from 'lodash';
import LoaderPureCss from '../../../components/LoaderPureCss';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import { IGoalsProps } from '..';

interface IModalMetasProps {
  user: User;
  option: 'Primeira' | 'Segunda';
  updateUser(user: User): void;
  setFirst(goal: IGoalsProps): void;
  setSecond(goal: IGoalsProps): void;
}


const ModalMetas: React.FC<IModalMetasProps> = ({ user, option, updateUser, setFirst, setSecond }) => {
  const [loading, setLoading] = useState(false);
  const [cursoList, setCursoList] = useState<any>([]);
  const [cursoSelected, setCursoSelected] = useState('');
  const [universidadeList, setUniversidadeList] = useState<any>([]);
  const [universidadeSelected, setUniversidadeSelected] = useState('');
  const [passingScore, setPassingScore] = useState<number>();

  //disciplines states
  const [mediaType, setMediaType] = useState<boolean>((false));
  const [humanas, setHumanas] = useState<string | null>(null);
  const [natureza, setNatureza] = useState<string | null>(null);
  const [linguagens, setLinguagens] = useState<string | null>(null);
  const [matematica, setMatematica] = useState<string | null>(null);
  const [redacao, setRedacao] = useState<string | null>(null);

  const [validation, setValidation] = useState(false);

  useEffect(() => {
    const find = user.goals.find((goal) => option === 'Primeira' ? goal.is_priority : !goal.is_priority);
    console.log({find})
    if (find) {
      setPassingScore(find.score / 100);

      if(find.type === 'weighted') {
        setMediaType(true);
        setHumanas(String(find.humanas / 100));
        setNatureza(String(find.natureza / 100));
        setLinguagens(String(find.portugues / 100));
        setMatematica(String(find.matematica / 100));
        setRedacao(String(find.redacao / 100));
      }
    }
  }, [user, option]);

  useEffect(() => {
    console.log({mediaType, humanas, natureza, linguagens, matematica, redacao});
  }, [mediaType, humanas, natureza, linguagens, matematica, redacao]);

  const handleSubmitForm = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log('FORM SUBMITED>>>>>> ')
    console.log({cursoSelected, universidadeSelected, passingScore});
    console.log({ mediaType, humanas, natureza, linguagens, matematica, redacao });

    if(mediaType === true) {
      if(humanas === null || natureza === null || linguagens === null || matematica === null || redacao === null) {
        console.log({ mediaType, humanas, natureza, linguagens, matematica, redacao });
        setValidation(true);
        return
      }
    }

    try {
      setLoading(true);

      const response = await api.post('/profiles/goals', {
        university_id: universidadeSelected,
        career_id: cursoSelected,
        is_priority: option === 'Primeira' ? true :  false,
        score: isNumber(passingScore) && passingScore > 0 ? Math.round(passingScore * 100) : null,
        type: mediaType ? 'weighted' : 'simple',
        portugues: mediaType ? (Number(linguagens) * 100) : null,
        matematica: mediaType ? (Number(matematica) * 100) : null,
        humanas: mediaType ? (Number(humanas) * 100) : null,
        natureza: mediaType ? (Number(natureza) * 100) : null,
        redacao: mediaType ? (Number(redacao) * 100) : null,
      });

      Swal.close();
      toast.success(`👌 ${option} opção alterada com sucesso!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setValidation(false);

      console.log('response.data>>>>>>>>>>>>>>>>', response.data);
      const find = user.goals.findIndex((goal) => goal.is_priority === response.data.is_priority);
      const goals = user.goals;

      console.log('find: ', find);
      console.log('goals: ', goals);

      if(find >= 0) {
        goals.splice(find, 1, response.data);
      } else {
        goals.push(response.data);
      }

      updateUser({
        ...user,
        goals: goals,
      });

      if(option === 'Primeira') {
        setFirst(response.data);
      } else {
        setSecond(response.data);
      }

    } catch (error) {
      console.log(error);
      setValidation(true);
    } finally {
      setLoading(false);
    }

  }, [cursoSelected, universidadeSelected, passingScore, user, updateUser, setFirst, setSecond, option, mediaType, humanas, natureza, linguagens, matematica, redacao]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/universities/options');

        console.log(response.data);
        setUniversidadeList(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/careers/options');

        console.log(response.data);
        setCursoList(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  const customStyles = {
    menu: (base: any) => ({
      ...base,
      marginTop: 6,
      background: '#fff',
      color: '#3e3f5e',
      fontWeight: 600,
    }),
    menuList: (base: any) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    }),
    multiValue: (base: any) => ({
      ...base,
      background: '#615dfa',
      color: '#fff',
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      color: '#fff',
      fontWeight: 500,
    }),
  };

  const handleformatedScore = useCallback((value: string) => {
    //const arrNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.'];
    setPassingScore(Number(value))
  }, []);

  return (
    <Container style={loading ? {minHeight: '360px'} : {}}>
      {loading && <LoaderPureCss isFixed={false} />}
      <h2>Preencher {option} opção de curso</h2>

      {user && !loading && (
        <form onSubmit={(e) => {handleSubmitForm(e)}}>
          <div>
            <Select
              placeholder="Selecione..."
              className="react-select"
              noOptionsMessage={() => cursoList.length >= 1 ? 'Escolha apenas uma opção' : 'Nenhuma opção encontrada'}
              options={cursoSelected.length > 0 ? [] : cursoList}
              loadingMessage={() => 'Carregando...'}
              isClearable
              isMulti
              defaultValue={() => {
                const goal = user.goals.find((goal) => option === 'Primeira' ? goal.is_priority : !goal.is_priority);

                if(goal) {
                  setCursoSelected(goal.career.id);
                  return {
                    value: goal.career.id,
                    label: goal.career.name
                  }
                }
              }}
              onChange={(selected, triggeredAction) => {
                  if (triggeredAction.action === 'clear' || triggeredAction.action === 'remove-value') {
                    setCursoSelected('');
                  } else {
                    //setCursoSelected((oldCurso: any) => [...oldCurso, selected]);
                    //@ts-ignore
                    setCursoSelected(selected[0].value);
                  }
                }
              }
              styles={customStyles}
            />
            <label>{option} opção de curso*</label>
          </div>
          <div>
            <Select
              placeholder="Selecione..."
              className="react-select"
              noOptionsMessage={() => universidadeList.length >= 1 ? 'Escolha apenas uma opção' : 'Nenhuma opção encontrada'}
              loadingMessage={() => 'Carregando...'}
              options={universidadeSelected.length > 0 ? [] : universidadeList}
              isClearable
              isMulti
              defaultValue={() => {
                const goal = user.goals.find((goal) => option === 'Primeira' ? goal.is_priority : !goal.is_priority);

                if(goal) {
                  setUniversidadeSelected(goal.university.id);
                  return {
                    value: goal.university.id,
                    label: `${goal.university.acronym} - ${goal.university.name}`
                  }
                }
              }}
              onChange={(selected, triggeredAction) => {
                  if (triggeredAction.action === 'clear' || triggeredAction.action === 'remove-value') {
                    setUniversidadeSelected('');
                  } else {
                    //setUniversidadeSelected((oldUniversidade: any) => [...oldUniversidade, selected])
                    //@ts-ignore
                    setUniversidadeSelected(selected[0].value);
                  }
                }
              }
              styles={customStyles}
            />
            <label>Universidade*</label>
          </div>
          <div>
            <Input
              type="number"
              placeholder="Ex: 720"
              label="Nota de corte (opcional)"
              defaultValue={String(passingScore)}
              onChange={(e) => handleformatedScore(e.target.value)}
              step=".01"
              max={1000.00}
            />
          </div>
          <Pesos>
            <h2 style={{margin: '1.5rem 0 0.5rem 0'}}>Forma de avaliação (opcional):</h2>
            <div>
              <div>
                <input type="radio" id="media-aritmetica-simples" name="media" defaultChecked={mediaType || true} onInput={() => setMediaType(false)} />
                <label htmlFor="media-aritmetica-simples">Média Aritmética Simples</label>
              </div>
              <div>
                <input type="radio" id="media-aritmetica-ponderada" name="media" defaultChecked={mediaType} onInput={() => setMediaType(true)} />
                <label htmlFor="media-aritmetica-ponderada">Média Aritmética Ponderada</label>
              </div>
            </div>
            {mediaType && <>
              <h2>Prioridades:</h2>
              <aside>
                <p>Ciências humanas e suas tecnologias</p>
                <div>
                  <select onChange={((e) => setHumanas(e.target.value))}>
                    <option value="..." selected={!humanas}>...</option>
                    <option selected={humanas === '0.5'} value="0.5">0.5</option>
                    <option selected={humanas === '1'} value="1.0">1.0</option>
                    <option selected={humanas === '1.5'} value="1.5">1.5</option>
                    <option selected={humanas === '2'} value="2.0">2.0</option>
                    <option selected={humanas === '2.5'} value="2.5">2.5</option>
                    <option selected={humanas === '3'} value="3.0">3.0</option>
                    <option selected={humanas === '3.5'} value="3.5">3.5</option>
                    <option selected={humanas === '4'} value="4.0">4.0</option>
                    <option selected={humanas === '4.5'} value="4.5">4.5</option>
                    <option selected={humanas === '5'} value="5.0">5.0</option>
                  </select>
                </div>
              </aside>
              <aside>
                <p>Ciências da natureza e suas tecnologias</p>
                <div>
                  <select onChange={((e) => setNatureza(e.target.value))}>
                    <option value="..." selected={!natureza}>...</option>
                    <option selected={natureza === '0.5'} value="0.5">0.5</option>
                    <option selected={natureza === '1'} value="1.0">1.0</option>
                    <option selected={natureza === '1.5'} value="1.5">1.5</option>
                    <option selected={natureza === '2'} value="2.0">2.0</option>
                    <option selected={natureza === '2.5'} value="2.5">2.5</option>
                    <option selected={natureza === '3'} value="3.0">3.0</option>
                    <option selected={natureza === '3.5'} value="3.5">3.5</option>
                    <option selected={natureza === '4'} value="4.0">4.0</option>
                    <option selected={natureza === '4.5'} value="4.5">4.5</option>
                    <option selected={natureza === '5'} value="5.0">5.0</option>
                  </select>
                </div>
              </aside>
              <aside>
                <p>Linguagens, códigos e suas tecnologias</p>
                <div>
                  <select onChange={((e) => setLinguagens(e.target.value))}>
                    <option value="..." selected={!linguagens}>...</option>
                    <option selected={linguagens === '0.5'} value="0.5">0.5</option>
                    <option selected={linguagens === '1'} value="1.0">1.0</option>
                    <option selected={linguagens === '1.5'} value="1.5">1.5</option>
                    <option selected={linguagens === '2'} value="2.0">2.0</option>
                    <option selected={linguagens === '2.5'} value="2.5">2.5</option>
                    <option selected={linguagens === '3'} value="3.0">3.0</option>
                    <option selected={linguagens === '3.5'} value="3.5">3.5</option>
                    <option selected={linguagens === '4'} value="4.0">4.0</option>
                    <option selected={linguagens === '4.5'} value="4.5">4.5</option>
                    <option selected={linguagens === '5'} value="5.0">5.0</option>
                  </select>
                </div>
              </aside>
              <aside>
                <p>Matemática e suas tecnologias</p>
                <div>
                  <select onChange={((e) => setMatematica(e.target.value))}>
                    <option value="..." selected={!matematica}>...</option>
                    <option selected={matematica === '0.5'} value="0.5">0.5</option>
                    <option selected={matematica === '1'} value="1.0">1.0</option>
                    <option selected={matematica === '1.5'} value="1.5">1.5</option>
                    <option selected={matematica === '2'} value="2.0">2.0</option>
                    <option selected={matematica === '2.5'} value="2.5">2.5</option>
                    <option selected={matematica === '3'} value="3.0">3.0</option>
                    <option selected={matematica === '3.5'} value="3.5">3.5</option>
                    <option selected={matematica === '4'} value="4.0">4.0</option>
                    <option selected={matematica === '4.5'} value="4.5">4.5</option>
                    <option selected={matematica === '5'} value="5.0">5.0</option>
                  </select>
                </div>
              </aside>
              <aside>
                <p>Redação</p>
                <div>
                  <select onChange={((e) => setRedacao(e.target.value))}>
                    <option value="..." selected={!redacao}>...</option>
                    <option selected={redacao === '0.5'} value="0.5">0.5</option>
                    <option selected={redacao === '1'} value="1.0">1.0</option>
                    <option selected={redacao === '1.5'} value="1.5">1.5</option>
                    <option selected={redacao === '2'} value="2.0">2.0</option>
                    <option selected={redacao === '2.5'} value="2.5">2.5</option>
                    <option selected={redacao === '3'} value="3.0">3.0</option>
                    <option selected={redacao === '3.5'} value="3.5">3.5</option>
                    <option selected={redacao === '4'} value="4.0">4.0</option>
                    <option selected={redacao === '4.5'} value="4.5">4.5</option>
                    <option selected={redacao === '5'} value="5.0">5.0</option>
                  </select>
                </div>
              </aside>
            </>}
          </Pesos>
          {/* <div>
            <p> <a href="https://sisu.mec.gov.br/#/duvidas#nota_corte" target="_blank" rel="noreferrer"><FiChevronRight strokeWidth={2.5} /> Como descobrir a nota de corte para o meu curso?</a></p>
          </div> */}

          <button type="submit">Confirmar metas</button>

          <footer>
            {validation && <ValidationError>⛔ <span>Por favor, preencha os campos obrigatórios.</span></ValidationError>}
          </footer>
        </form>
      )}
    </Container>
  );
}

export default ModalMetas;
