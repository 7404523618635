import React from 'react';

import { useAuth } from '../hooks/auth';

import App from './app.routs';
import Auth from './auth.routs';

const Routes: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    isAuthenticated ? <App /> : <Auth />
  );
}

export default Routes;
