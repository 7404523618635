import React, { useEffect, useState } from 'react';

import api from '../../services/api';

import ContentGrid  from "../../components/ContentGrid";
import Footer from '../../components/Footer';

import { Container, GridContainer, GridContainer2 } from './styles';

import HeaderInfo from '../../components/HeaderInfo';
import DisciplineCard from '../../components/DisciplineCard';
import Loader from '../../components/Loader';
import AsideMenuItems from '../../components/AsideMenuItems';
export interface ISubjectsProps {
  id: string;
  name: string;
  description: string;
  color: string;
  icon: string;
  slug: string;
  created_at: string;
  updated_at: string;
  progress: number;
}


const Cronograma: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState<ISubjectsProps[]>([]);
  const [redacaoProgress, setRedacaoProgress] = useState(0);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/subjects');

        console.log('========= LIST SUBJECTS ==========');
        console.log(response.data);
        setSubjects(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/themes/progress');

        console.log('========= LIST THEME PROGRESS ==========');
        console.log(response.data);
        setRedacaoProgress(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  return(
    <Container>
      <GridContainer>
        <aside>
          <AsideMenuItems />
        </aside>
        <main>
          <section style={loading ? {height: '300px', overflow: 'hidden' } : {}}>
            {loading && <Loader isFixed={false} /> }
            <HeaderInfo supTitle="Cronograma" title="Áreas do conhecimento" showSendButton={false} margin="0 0 2rem 0"  />
            <GridContainer2>
              {subjects.map((subject) => (
                <DisciplineCard
                  key={subject.id}
                  to={subject.slug !== 'cronograma/temas-para-redacao' ? `cronograma/${subject.slug}` : subject.slug}
                  progress={subject.slug !== 'temas-para-redacao' ? Math.trunc(subject.progress) : Math.trunc(redacaoProgress)}
                  barColor="#93f9b9"
                  icon={`https://educacione.s3.sa-east-1.amazonaws.com/${subject.icon}`}
                  disciplineName={subject.name}
                  backdropColor="#ffffff20"
                />
              ))}
            </GridContainer2>
          </section>
        </main>
      </GridContainer>

      <ContentGrid>
        <Footer />
      </ContentGrid>
    </Container>
  );
}

export default Cronograma;
