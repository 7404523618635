import React, { useCallback, useState } from 'react';

import api from '../../../services/api';

import ReactStars from 'react-stars';

import { Container } from './styles';
import LoaderPureCss from '../../LoaderPureCss';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

interface IModalReviewProps {
  id: string;
  rating: number;
  comments?: string;
  setReviewState(review: any): void;
}

const ModalMovieReview: React.FC<IModalReviewProps> = ({ id, rating, comments, setReviewState }) => {

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);

  const [currentRating, setCurrentRating] = useState(rating);
  const [currentNotes, setCurrentNotes] = useState('');

  const handleConfirmUserReview = useCallback(async() => {
    try {
      setLoading(true);
      if(!currentNotes) {
        setValidation(true);

        return;
      }

      console.log('vars to form submit: ', {currentRating, currentNotes})
      const response = await api.post(`/movies/${id}/reviews`, {
        rating: currentRating * 20,
        notes: currentNotes
      });

      //pass to children
      setReviewState(response.data);

      console.log('MOVIE REVIEW SUBMITTED!');
      console.log(response.data);

      Swal.close();
      toast.success(`👌 Avaliação computada com sucesso!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }, [id, currentRating, currentNotes, setReviewState]);

  return (
    <Container>
      {loading && <LoaderPureCss isFixed={false} background="#090029" />}
      <header>
        <h1>{rating ? 'Confirmar avaliação?' : 'Adicione observações sobre o filme'}</h1>
        <p>{rating > 0 && `Você está avaliando com ${rating} estrelas!`}</p>
      </header>
      <main>
        <ReactStars
          key={1}
          count={5}
          size={50}
          color1="#adafca"
          color2="#d3df37"
          half={true}
          value={currentRating} //puxar do banco aqui
          onChange={(new_rating: number) => {console.log(new_rating); setCurrentRating(new_rating)}}
        />
      </main>
      <footer>
        <textarea name="" placeholder="Adicione repertórios ou comentários sobre o filme" defaultValue={comments} onChange={(e) => {setCurrentNotes(e.target.value); console.log(e.target.value)}}></textarea>
        <button onClick={() => handleConfirmUserReview()}>{rating ? 'Confirmar avaliação' : 'Submeter comentários'}</button>
      </footer>
      {validation && <p style={{color: '#ff0000', marginTop: '1rem'}}> <strong> Preencha o campo de anotações antes de confirmar avaliação!</strong>😊</p>}
    </Container>
  );
}

export default ModalMovieReview;
