import styled from "styled-components";

export const Fake = styled.div`
  gap: 0.5rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: ${props => props.theme.colors.textColor};
  transition: all 0.25s ease-in-out;
  padding: 1rem;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background: ${props => props.theme.colors.primary};
  }
`;

export const Badge = styled.span`
  font-size: 9px;
  margin-left: 4px !important;
  background: #e13131;
  color: #fff;
  padding: 2px 4px;
  border-radius: 4px;
  margin-top: 2px;
`;
