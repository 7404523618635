import React, { useCallback, useEffect, useMemo, useState } from 'react';

import api from '../../services/api';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';

import { MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import { MuuriComponent, getResponsiveStyle } from 'muuri-react';
import { IoIosFlash, IoIosCloseCircleOutline, IoMdSearch } from "react-icons/io";


import Loader from '../Loader';
import Button from '../Button';
import Flashcard from './Flashcard';
import ShuffleSlider from './ShuffleSlider';

import { CardsSection, FloatingButton, NotFoundFlashCards, SlideContainer } from './styles';
import { ISubjectsProps } from '../../Pages/Cronograma';

import { DecoratedGrid, DecoratedItem } from 'muuri-react/dist/types/interfaces';
import ModalCreateFlashCard from './ModalCreateFlashCard';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

export interface Card {
  id: string;
  order: number;
  front: string;
  back: string;
  subject_id: string;
  color: string;
  user_id: string;
  created_at: string;
  updated_at: string;
}


// The theme context.
export const ThemeContextDnd = React.createContext(null);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const isBigScreen = useMediaQuery({
    query: "(min-width: 920px)"
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 600px)"
  });

  // Memoize the style.
  const style = useMemo(() => {
    return getResponsiveStyle({
      columns: isBigScreen ? 1 / 4 : isMobile ? 1 : 1 / 2,
      margin: "1%",
      ratio: 1
    });
  }, [isBigScreen, isMobile]);

  return (
    <ThemeContextDnd.Provider value={style as any}>{children}</ThemeContextDnd.Provider>
  );
};

interface IFlashcardsProps {
  defaultShowCardsContainer: boolean;
  setDefaultShowCardsContainer(value: boolean): void;
}

export const Educards: React.FC<IFlashcardsProps> = ({ defaultShowCardsContainer = false, setDefaultShowCardsContainer }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);
  const [subjects, setSubjects] = useState<ISubjectsProps[]>([]);
  const { slug } = useParams<{ slug: string }>();
  const [currentSubject, setCurrentSubject] = useState<ISubjectsProps>({} as ISubjectsProps);

  const [showCardsContainer, setShowCardsContainer] = useState(defaultShowCardsContainer);

  const [input, setInput] = useState('');

  const [items, setItems] = useState<Card[]>([]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (showCardsContainer) {
      body?.setAttribute('style', 'overflow: hidden;');
    } else {
      body?.removeAttribute('style');
    }

    return () => {
      body?.removeAttribute('style');
    };
  }, [showCardsContainer]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response1 = await api.get('/subjects');
        setSubjects(response1.data.filter((subject: ISubjectsProps) => subject.slug !== 'temas-para-redacao'));

        const currentSubject = response1.data.find((subject: ISubjectsProps) => subject.slug === slug);
        setCurrentSubject(currentSubject);

        const response2 = await api.get(currentSubject?.id ? `/flashcards?subject_id=${currentSubject?.id}` : '/flashcards');
        setItems(response2.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [slug]);

  useEffect(() => {
    setShowCardsContainer(defaultShowCardsContainer);
  }, [defaultShowCardsContainer]);

  const children = items.map((card: Card) => {

    return (
      <Flashcard
        className='item'
        key={card.id}
        id={card.id}
        front={card.front}
        back={card.back}
        color={card.color}
        subjects={subjects}
      />
    )
  });

  const filter = useCallback((data: any) => {
    //console.log('data', data);

    const searchTerm = input?.toLowerCase();
    return data.front?.toLowerCase().includes(searchTerm) || data.back?.toLowerCase().includes(searchTerm);
  }, [input]);

  const onMount = useCallback((grid: DecoratedGrid) => {
    grid.on('move', async () => {

      if (!grid.getItems().length) return;

      const ids = grid.getItems().map((item: DecoratedItem) => item.getKey());

      try {
        await api.put('/flashcards/order', {
          subject_id: currentSubject?.id,
          flashcard_ids: ids,
        });

        toast.success('Ordem dos flashcards salva com sucesso! 😊');
      } catch (error) {
        console.log(error);
        toast.error('Erro ao salvar a ordem dos flashcards 😑');
      }
    });
  }, [currentSubject?.id]);

  const handleOpenModal = useCallback(() => {
    let MySwal = withReactContent(Swal);
      MySwal.fire({
        html: (
          <ModalCreateFlashCard
            slug={slug}
            subject_id={currentSubject?.id}
            closeModal={() => MySwal.close()}
            subjects={subjects}
          />),
        background:'#fff',
        backdrop: '#ffffff00',
        showConfirmButton: false,
        showCloseButton: true,
        showCancelButton: false,
        allowOutsideClick: true,
        allowEscapeKey : true,
        padding: '32px 16px',
      });
  }, [currentSubject?.id, slug, subjects]);

  return (
    <>
      {(showCardsContainer) && (
        <>
          <CardsSection>
            <aside>
              <IoIosCloseCircleOutline size={35}
                onClick={() => {
                  setDefaultShowCardsContainer(!showCardsContainer);
                  setReviewMode(false);
                }}
              />
            </aside>
            {!reviewMode ? (
              <>
                <footer>
                  <h1>
                    Flashcards de <span>{currentSubject?.name}</span>
                  </h1>
                  <p>Adicione resumos para o assunto selecionado, para que possa revisar e fixar o conteúdo. 😊</p>
                </footer>
                <header>
                  <aside>
                    <select
                      name="cluster"
                      id="cluster"
                      onChange={(e) => {
                        setLoading(true);
                        const currentSlug = subjects.find((subject) => subject?.id === e.target.value)?.slug;
                        console.log(currentSlug);

                        if (!currentSlug) {
                          setTimeout(() => {
                            setDefaultShowCardsContainer(true);
                          }, 1000);
                          setLoading(false);
                          setItems([]);
                          setInput('');
                        }
                        else {
                          setItems([]);
                          setInput('');
                          history.push(`/cronograma/${currentSlug}`);
                        }
                      }}
                    >
                      <option value="">Selecione...</option>
                      {subjects.map((subject) => {
                        return (
                          <option
                            key={subject.id}
                            value={subject.id}
                            selected={subject.id === currentSubject?.id}
                          >
                            {subject.name}
                          </option>
                        )
                      })}
                    </select>
                  </aside>
                  <div>
                    <IoMdSearch size={20} />
                    <input
                      type="text"
                      name="search"
                      onChange={(e) => {
                        setInput(e?.target?.value);
                      }}
                      value={input}
                      placeholder="Pesquise por um assunto, título ou conteúdo..."
                    />
                  </div>
                  <Button color="primary" onClick={handleOpenModal}>Novo flashcard</Button>
                  <Button color="info" onClick={() => setReviewMode((old) => !old)}>Revisar</Button>
                </header>
                <main>
                  {items.length > 0 ? (
                    <ThemeProvider>
                      <MuuriComponent
                        dragEnabled={true}
                        dragFixed
                        dragAutoScroll={{
                          targets: [window]
                        }}
                        forceSync={true}
                        propsToData={({ front, back, color }: any) => {
                          return {
                            front: front,
                            back: back,
                            color
                          };
                        }}
                        filter={filter}
                        dragSortHeuristics={{
                          sortInterval: 70,
                        }}
                        dragPlaceholder={{
                          enabled: false,
                          createElement: (item: any) => {
                            return item.getElement().cloneNode(true);
                          }
                        }}
                        dragSortPredicate={{
                          action: 'swap'
                        }}
                        onDragStart={(item) => {
                          console.log('start Item dragged:', item);
                        }}
                        onDragEnd={(item) => {
                          console.log('end Item dragged:', item);
                        }}
                        onMount={(grid) => onMount(grid)}
                      >
                        {children}
                      </MuuriComponent>
                    </ThemeProvider>
                  ) : (
                    <NotFoundFlashCards>
                      {loading ? (
                        <Loader isFixed={false} backgroundTransparent zIndex={9999999999999} />
                      ) : (
                        <>
                          <h1>Ops! Nenhum flashcard encontrado. Clique no botão acima para adicionar um novo flashcard.</h1>
                          <h2>😕</h2>
                        </>
                      )}
                    </NotFoundFlashCards>
                  )}
                </main>
              </>
            ) : (
              <>
                <footer>
                  <h1>
                    Modo revisão: <span>{currentSubject?.name}</span>
                  </h1>
                  <p>Use as setas para navegar entre os flashcards e revisar o conteúdo de forma aleatória. 😊</p>
                </footer>
                <header>
                  <h1 onClick={() => setReviewMode((prev) => !prev)}><MdArrowBack /> Voltar para modo de organização</h1>
                </header>
                <main>
                  <SlideContainer>
                    <ShuffleSlider
                      items={items}
                      setReviewMode={setReviewMode}
                    />
                  </SlideContainer>
                </main>
              </>
            )}
          </CardsSection>
        </>
      )}
    </>
  );
}

export default Educards;
