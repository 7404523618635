import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  > footer {
    position: relative;
    margin-top: 1rem;

    > button {
      width: 100%;
      background: #615dfa;
      border-radius: 6px;
      padding: 12px 0;
      z-index: 9999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      transition: all .2s ease-in-out;

      &:hover {
        background: #3531eb;
      }
    }
  }
`;
