import React, { useEffect, useState } from 'react';

import { useSwipeable } from 'react-swipeable';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import Flashcard2 from './Flashcard2';
import Loader from '../../Loader';

import { LoaderContainer, NotFound, SliderContainer } from './styles';

interface Card {
  id: string;
  front: string;
  back: string;
  color: string;
}

interface SlideContainerProps {
  items: Card[];
  setReviewMode(prev: boolean): void;
}

const SlideContainer: React.FC<SlideContainerProps> = ({ items, setReviewMode }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [shuffledItems, setShuffledItems] = useState<Card[]>([]);
  const [fakeLoading, setFakeLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFakeLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const shuffleArray = (array: Card[]) => {
      let newArray = [...array];
      for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
      }
      return newArray;
    }
    console.log('items', shuffleArray(items));
    setShuffledItems(shuffleArray(items));
  }, [items]);

  const totalItems = shuffledItems.length;

  const goToNextItem = () => {
    if (!isButtonDisabled && currentIndex < totalItems - 1) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
      setIsButtonDisabled(true); // Desabilita temporariamente o botão/gesto
      setTimeout(() => setIsButtonDisabled(false), 500); // Reabilita após 500ms
    }
  };

  const goToPreviousItem = () => {
    if (!isButtonDisabled && currentIndex > 0) {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + totalItems) % totalItems);
      setIsButtonDisabled(true); // Desabilita temporariamente o botão/gesto
      setTimeout(() => setIsButtonDisabled(false), 500); // Reabilita após 500ms
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => goToNextItem(),
    onSwipedRight: () => goToPreviousItem(),
    trackMouse: true
  });

  return (
    fakeLoading ? (
      <LoaderContainer>
        <Loader isFixed={false} />
        <p>Embaralhando flashcards...</p>
      </LoaderContainer>
    ) : (
      <SliderContainer {...handlers}>
        {shuffledItems.length > 0 && (
          <footer>
            {currentIndex > 0 && (
              <button className="previous-btn" onClick={goToPreviousItem}>
                <FiChevronLeft />
              </button>
            )}
            {currentIndex < totalItems - 1 && (
              <button className="next-btn" onClick={goToNextItem}>
                <FiChevronRight />
              </button>
            )}
          </footer>
        )}
        <main>
          {shuffledItems.length > 0 ? (
            <>
              {currentIndex > 0 && (
                <Flashcard2
                  id={shuffledItems[currentIndex - 1].id}
                  className="previous"
                  key={shuffledItems[currentIndex - 1].id}
                  front={shuffledItems[currentIndex - 1].front}
                  back={shuffledItems[currentIndex - 1].back}
                  color={shuffledItems[currentIndex - 1].color}
                />
              )}
              <Flashcard2
                id={shuffledItems[currentIndex].id}
                className="visible"
                key={shuffledItems[currentIndex].id}
                front={shuffledItems[currentIndex].front}
                back={shuffledItems[currentIndex].back}
                color={shuffledItems[currentIndex].color}
              />
              {currentIndex + 1 < totalItems && (
                <Flashcard2
                  id={shuffledItems[currentIndex + 1].id}
                  className="next"
                  key={shuffledItems[currentIndex + 1].id}
                  front={shuffledItems[currentIndex + 1].front}
                  back={shuffledItems[currentIndex + 1].back}
                  color={shuffledItems[currentIndex + 1].color}
                />
              )}
            </>
          ) : (
            <NotFound>
              <h1>Ops! Nenhum flashcard encontrado. <span onClick={() => setReviewMode(false)}>Volte para adicionar</span> um novo flashcard.</h1>
              <h2>😕</h2>
            </NotFound>
          )}
        </main>
      </SliderContainer>
    )
  );
};

export default SlideContainer;
