import React, { useCallback, useEffect, useState } from "react";
import api from "../../../services/api";

import LoaderPureCss from "../../../components/LoaderPureCss";

import { Container } from "./styles";
import { trim } from "lodash";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const ForgetPasswordModal: React.FC = () => {
  const [inputForgetPassword, setInputForgetPassword] = useState("");
  const [validation, setValidation] = useState("");
  const [validationStatus, setValidationStatus] = useState<boolean>();

  const [loading, setLoading] = useState(false);

  const [storageTimer, setStorageTimer] = useState(() => {
    const now = Date.now();
    const lastSent = parseInt(localStorage.getItem("@timer:forgetPassword") || "0");
    const timePassed = now - lastSent;
    const remainingTime = Math.max(0, 30000 - timePassed);

    // Convertendo de milissegundos para segundos para facilidade de exibição
    return Math.floor(remainingTime / 1000);
  });

  const handleSubmitForgetPaswordForm = useCallback(
    async (e: any, email: string) => {
      e.preventDefault();
      console.log("email: ", email);

      const emailTrined = trim(email.replace(/\s/g, "")).trim();
      const now = Date.now();
      const lastSent = parseInt(localStorage.getItem("@timer:forgetPassword") || "0");

      if (now - lastSent < 30000) {
        toast.error("Aguarde 30 segundos antes de tentar novamente.");
        return;
      }

      try {
        setLoading(true);
        const response = await api.post(`/passwords/forgot`, {
          email: emailTrined,
        });

        console.log(response.data);
        toast.success("O e-mail de redefinição foi enviado para o seu e-mail! 📩", {
          position: "bottom-center",
        });

        localStorage.setItem("@timer:forgetPassword", now.toString());
        let modal = withReactContent(Swal);
        modal.close();
        setValidationStatus(true);
      } catch (error) {
        console.log(error);
        setValidation("E-mail não encontrado em nossa plataforma! 🤷‍♂️");
        setValidationStatus(false);
      } finally {
        setLoading(false);
      }
    }, [setLoading, setValidation, setValidationStatus]);

    useEffect(() => {
      const interval = setInterval(() => {
        const now = Date.now();
        const lastSent = parseInt(localStorage.getItem("@timer:forgetPassword") || "0");
        setStorageTimer(Math.max(0, 30 - Math.floor((now - lastSent) / 1000)));
      }, 1000);

      return () => clearInterval(interval);
    }, []);

  return (
    <Container>
      <h1>Esqueceu sua senha?</h1>
      <p>
        Informe o email que você cadastrou no ato da compra para acessar a
        Plataforma e enviaremos as instruções de troca para você.
      </p>
      <div>
        <form
          onSubmit={(e) => {
            handleSubmitForgetPaswordForm(e, inputForgetPassword);
          }}
        >
          <input
            name="email"
            type="email"
            autoComplete="off"
            defaultValue={inputForgetPassword}
            onChange={(e) => setInputForgetPassword(e.target.value)}
            required
          />
          <label htmlFor="email">Informe o seu e-mail</label>
          <button disabled={(inputForgetPassword === "" ? true : false) || storageTimer !== 0}>
            RECUPERAR MINHA SENHA
          </button>
          {!loading && (
            <p style={{ color: validationStatus ? "#03bb85" : "#d65151" }}>
              {validation}
            </p>
          )}

          {storageTimer !== 0 && (
            <p>Aguarde {storageTimer} segundos para tentar novamente.</p>
          )}
        </form>
      </div>
      {loading && <LoaderPureCss isFixed={false} scale="0.6" />}
    </Container>
  );
};

export default ForgetPasswordModal;
