import React from 'react';

import { Container } from './styles';

interface INextVideoTumbnailsProps {
  thumbnailUrl: string;
  lessonOrder: number;
  lessonTitle: string;
  lessonDuration: string;
  current?: boolean;
  onClick: (e:  React.MouseEvent) => void;
}

const NextVideoTumbnails: React.FC<INextVideoTumbnailsProps> = ({thumbnailUrl, lessonOrder, lessonTitle, lessonDuration, current, onClick}) => {
  return (
    <Container onClick={onClick} current={current}>
      <div>
        <figure>
          <div>assistindo</div>
          <img src={thumbnailUrl} alt={lessonTitle} />
        </figure>
        <main>
          <p>Aula {lessonOrder}</p>
          <h3>{lessonTitle}</h3>
        </main>
        <aside>
          <p>{lessonDuration}</p>
        </aside>
      </div>
    </Container>
  );
}

export default NextVideoTumbnails;
