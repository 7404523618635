import React from 'react';
import { Link } from 'react-router-dom';

import { FiCamera, FiCheck } from 'react-icons/fi';

import { Container } from './styles';
import { FaCheck } from 'react-icons/fa';
interface IBigAvatarProps {
  avatar_url: string;
}

const BigAvatar: React.FC<IBigAvatarProps> = ({avatar_url}) => {
  return (
    <Container>
      <Link to="#">
        <img src={avatar_url} alt="" />
      </Link>
      <span>
        {/* <FiCheck strokeWidth={4} size={18} /> */}
        <FaCheck size={18} />
        {/*  <FiCamera strokeWidth={2.5} size={15} /> */}
      </span>
    </Container>
  );
}

export default BigAvatar;
