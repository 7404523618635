import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

import { FaCheckCircle, FaGraduationCap, FaSortAmountUp } from 'react-icons/fa';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import ContentGrid from "../../components/ContentGrid";
import HeaderInfo from "../../components/HeaderInfo";
import MenuItem from '../../components/MenuItem';
import Footer from '../../components/Footer';

import ModalMetas from './ModalMetas';

import { Container, GridContainer } from './styles';

import menuLinks from '../../utils/menuLinks';
import AsideMenuItems from '../../components/AsideMenuItems';
interface ICategoriesProps {
  id: number;
  image_url: string;
  name: string;
  description: string;
  administrator?: null;
}

export interface IGoalsProps {
  id: string;
  user_id: string;
  university_id: string;
  university: {
    id: string;
    name: string;
    acronym: string;
    created_at: string;
    updated_at: string;
  };
  career_id: string;
  career: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
  };
  score: number;
  is_priority: boolean;
  humanas: number;
  matematica: number;
  natureza: number;
  portugues: number;
  redacao: number;
  type: 'weighted' | 'simple';
  created_at: string;
  updated_at: string;
}

const Metas: React.FC = () => {
  const { user, updateUser } = useAuth();
  const location = useLocation();
  const localtionSplited = location.pathname;

  const [first, setFirst] = useState<IGoalsProps>();
  const [second, setSecond] = useState<IGoalsProps>();

  useEffect(() => {
    if (user.goals) {
      setFirst(user.goals.find((goal) => goal.is_priority));
      setSecond(user.goals.find((goal) => !goal.is_priority));
    }
  }, [user.goals]);

  const handleEditMeta = useCallback((option: 'Primeira' | 'Segunda') => {
    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        <ModalMetas user={user && user} option={option} updateUser={updateUser} setFirst={setFirst} setSecond={setSecond} />
      ),
      background: '#fff',
      backdrop: '#1d23334e',
      showConfirmButton: false,
      showCloseButton: true,
      showCancelButton: false,
      allowOutsideClick: true,
      allowEscapeKey: true,
      padding: '32px 16px',
    });
  }, [user, updateUser]);

  const addDotMoreZeroBefore = (value: number) => {
    return String(value).length === 1 ? `${value}.0` : value;
  }

  return (
    <Container>
      <GridContainer>
        <aside>
          <AsideMenuItems />
        </aside>
        <main>
          <HeaderInfo supTitle="Quadro de" title={`Metas ${new Date().getFullYear()}`} margin="0" showSendButton={false} />
          {!first ?
            <p>Você ainda não traçou suas metas para {new Date().getFullYear()}. <strong>Escolha suas opções de curso!</strong></p>
            :
            <p>Você pode alterar suas metas sempre que preferir clicando no botão do card abaixo! 👇🏻</p>
          }
          <section>
            {!first ? (
              <div>
                <h1><strong>Primeira opção</strong> de curso</h1>
                <h2>Você ainda não está inscrito na 1ª opção</h2>
                <p>Clique no botão abaixo e cadastre as suas metas de universidade, curso e possível nota de corte.</p>
                <hr />
                <button onClick={() => handleEditMeta('Primeira')}>Preencher inscrição na primeira opção</button>
              </div>
            )
              :
              (
                <div className="goal-checked">
                  <h1><strong>{first.career.name}</strong></h1>
                  <h2>Você já está inscrito na 1ª opção <FaCheckCircle size={24} /></h2>
                  <p><FaGraduationCap size={18} /> {first.university.acronym} - {first.university.name}</p>
                  {first.score && <p><FaSortAmountUp size={18} /> Nota de corte: {first.score / 100}</p>}
                  {first.type === 'weighted' && (
                    <>
                      <h5 style={{ marginTop: '1rem' }}>Prioridades</h5>
                      <p>Ciências humanas e suas tecnologias: {addDotMoreZeroBefore(first.humanas / 100)}</p>
                      <p>Ciências da natureza e suas tecnologias: {addDotMoreZeroBefore(first.natureza / 100)}</p>
                      <p>Linguagens, códigos e suas tecnologias: {addDotMoreZeroBefore(first.portugues / 100)}</p>
                      <p>Matemática e suas tecnologias: {addDotMoreZeroBefore(first.matematica / 100)}</p>
                      <p>Redação: {addDotMoreZeroBefore(first.redacao / 100)}</p>
                    </>
                  )}
                  <hr />
                  <button onClick={() => handleEditMeta('Primeira')}>Alterar inscrição na primeira opção</button>
                </div>
              )}
            {!second ? (
              <div>
                <h1><strong>Segunda opção</strong> de curso</h1>
                <h2>Você ainda não está inscrito na 2ª opção</h2>
                <p>Clique no botão abaixo e cadastre as suas metas de universidade, curso e possível nota de corte.</p>
                <hr />
                <button onClick={() => handleEditMeta('Segunda')}>Preencher inscrição na segunda opção</button>
              </div>
            )
              :
              (
                <div className="goal-checked">
                  <h1><strong>{second.career.name}</strong></h1>
                  <h2>Você já está inscrito na 2ª opção <FaCheckCircle size={24} /></h2>
                  <p><FaGraduationCap size={18} /> {second.university.acronym} - {second.university.name}</p>
                  {second.score && <p><FaSortAmountUp size={18} /> Nota de corte: {second.score / 100}</p>}
                  {second.type === 'weighted' && (
                    <>
                      <h5 style={{ marginTop: '1rem' }}>Prioridades</h5>
                      <p>Ciências humanas e suas tecnologias: {addDotMoreZeroBefore(second.humanas / 100)}</p>
                      <p>Ciências da natureza e suas tecnologias: {addDotMoreZeroBefore(second.natureza / 100)}</p>
                      <p>Linguagens, códigos e suas tecnologias: {addDotMoreZeroBefore(second.portugues / 100)}</p>
                      <p>Matemática e suas tecnologias: {addDotMoreZeroBefore(second.matematica / 100)}</p>
                      <p>Redação: {addDotMoreZeroBefore(second.redacao / 100)}</p>
                    </>
                  )}
                  <hr />
                  <button onClick={() => handleEditMeta('Segunda')}>Alterar inscrição na segunda opção</button>
                </div>
              )}
          </section>
        </main>
      </GridContainer>
      <ContentGrid>
        <Footer />
      </ContentGrid>
    </Container>
  );
}

export default Metas;
