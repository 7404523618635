import styled from 'styled-components';

export const Container = styled.main`
  position: relative;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;


  > a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-image: ${props => props.theme.colors.progressBarGradient};
    border-radius: 50%;
    overflow: hidden;


    &::after {
      content: 'Alterar foto';
      position: absolute;
      width: 100%;
      height: 35%;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(to bottom, transparent 0%, ${props => props.theme.colors.primary}95 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      opacity: 0;
      transition: all .2s ease-in-out;
      padding-bottom: 1rem;
    }

    &:hover {

      &::after {
        opacity: 1;
      }
    }

    > img {
      width: 190px;
      height: 190px;
      border-radius: 50%;
      border: 8px solid ${props => props.theme.colors.bgBox};
    }
  }

  > span {
    position: absolute;
    bottom: 14px;
    right: 10px;
    background: ${props => props.theme.colors.secondary};
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 6px solid ${props => props.theme.colors.bgBox};
    color: ${props => props.theme.colors.bgBox};

    > svg {
      margin-top: 2px;
    }
  }

`;
