import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: ${props => props.theme.colors.shadowBox};
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  /* Customize focus-visible for keyboard */
  *:focus-visible {
    border-radius: 4px;
    animation-name:  showFocus;
    animation-duration: 0.32s;
    animation-fill-mode: forwards;
  }
  /* Remove outline for non-keyboard :focus */
  *:focus {
    outline: none;
  }
  summary {
    background: ${props => props.theme.colors.bgBox};
    color: ${props => props.theme.colors.textColor};
    position: relative;
    padding: 28px 26px 28px 60px;
    font-weight: 600;
    cursor: pointer;
    list-style-type: none;
    border-bottom: 1px solid #adafca1a;
    &::-webkit-details-marker { display:none; }
    transition: all .3s ease-in-out;
    user-select: none;

    &:hover {
      background: ${props => props.theme.colors.bgBox}99;
    }

    > svg {
      position: absolute;
      top: 28px;
      left: 28px;
      margin-top: 4px;
      color: ${props => props.theme.colors.textColor};
    }

    > p {
      margin-top: 4px;
      color: #adafca;
      font-size: .75rem;
      font-weight: 500;
      line-height: 1.3333333333em;
      padding: 0;
      width: 85%;
    }
  }
  details {

    &:last-child {
      border-radius: 0 0 10px 10px;
      overflow: hidden;
    }
  }
  /* Content in Accordion fades in */
  details[open] > div {
    opacity: 0;
    padding: 28px 26px 28px 60px;
    background: ${props => props.theme.colors.bgBox}70;
    animation-name: showContent;
    animation-duration: .6s;
    animation-fill-mode: forwards;

    > ul {
      list-style: none;

      > li {
        margin: .5rem 0;

        > h1 {
          display: block;
          font-size: .75rem;
          font-weight: 700;
          line-height: 1.5rem;
          text-decoration: none;
          color: ${props => props.theme.colors.textColor};
          transition: all .2s ease-in-out;
          cursor: pointer;

          &:hover {
            transform: translate(4px);
            color: ${props => props.theme.colors.info};
          }

          &.active {
            color: ${props => props.theme.colors.primary};
            background: transparent !important;
          }
        }
      }
    }
  }
  /* Arrow for Accordion */
  details summary:after {
    content: '';
    position: absolute;
    top: 35px;
    right: 30px;
    width: 4px;
    height: 4px;
    border: solid;
    border-color: $acc-arrow-color;
    border-width: 0 2px 2px 0;
    animation-name: flipArrowDown;
    animation-duration: 0.32s;
    animation-fill-mode: forwards;
  }
  details[open] summary:after {
    margin-top: 6px;
    animation-name: flipArrowUp;
    animation-duration: 0.24s;
    animation-fill-mode: forwards;
  }
  /* Animation for Focus */
  @keyframes showFocus {
    from {
      box-shadow: none;
    }
    to {
      box-shadow: 0 0 0 2px $acc-focus-color;
    }
  }
  /* Animation for Content */
  @keyframes showContent {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  /* Animation for Arrow */
  @keyframes flipArrowUp { 100% { -webkit-transform: rotate(-135deg); transform:rotate(-135deg); } }
  @keyframes flipArrowDown { 100% { -webkit-transform: rotate(45deg); transform:rotate(45deg); } }
`;

export const Question = styled.details`

&[open] {

  > p {
    animation-name: showContent;
    animation-duration: .6s;
    animation-fill-mode: forwards;
    opacity: 0;
    line-height: 1.8;
  }

  > summary {
    transform: translate(4px);
    color: ${props => props.theme.colors.info} !important;

    > svg {
      transition: all .2s ease-in-out;
      color: ${props => props.theme.colors.info} !important;
    }
  }
}

`;
export const QuestionTitle = styled.summary`
  font-size: 14px !important;
  background: none !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  display: block;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-decoration: none;
  color: ${props => props.theme.colors.textColor} !important;
  transition: all .2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translate(4px);
    color: ${props => props.theme.colors.info} !important;

    > svg {
      transition: all .2s ease-in-out;
      color: ${props => props.theme.colors.info} !important;
    }
  }

  &:after {
    display: none;
  }

  > svg {
    position: relative !important;
    top: 2px !important;
    left: 0 !important;

    &:hover {
      color: ${props => props.theme.colors.info} !important;
    }
  }
`;

export const Anwser = styled.p`
  margin: .5rem 0;
  color: ${props => props.theme.colors.textColor} !important;
  font-size: 13px;
  padding: 0 0 0 1rem;

  > a {
    color: ${props => props.theme.colors.textColor} !important;
  }
`;
