import React, { useState } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Container } from './styles';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import api from '../../../services/api';

interface IWysiwygProps {
  defaultValue?: string;
  subarea_id: string;
  onSubmit: (newValue: string) => void;
}

const WysiwygInsetComment: React.FC<IWysiwygProps> = ({ defaultValue, subarea_id, onSubmit }) => {
  const [value, setValue] = useState(defaultValue || '');

  const handleSumit = async () => {

    try {
      const response = await api.patch(`/schedules/notes`, {
        notes: value,
        subarea_id: subarea_id,
      });

      console.log('response.data', response.data);

      if (response.status === 200) {
        onSubmit(value || '');

        Swal.close();
        toast.success("👍 Comentário adicionado com sucesso!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        placeholder="Digite aqui..."
        style={{ color: "#3e3f5e", resize: "none", }}
        modules={{
          toolbar: [
            [
              { 'size': ['small', false, 'large', 'huge'] },
              { 'font': [] }, 'bold', 'italic', 'underline', 'link', { 'align': [] },
              { 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' },
              { 'color': [] }, { 'background': [] }
            ],
          ],
        }}
      />

      <footer>
        <button type="submit" onClick={handleSumit}>Salvar anotação</button>
      </footer>
    </Container>
  );
}

export default WysiwygInsetComment;
