import styled from 'styled-components';

interface IInputAnimateProps {
  padding?: string;
  innerPadding?: string;
  margin?: string;
  onlyWhite?: boolean | false;
}

export const Container = styled.div<IInputAnimateProps>`
  position: relative;
  line-height: 14px;
  display: inline-block;
  width: 100%;
  padding: ${props => props.padding ? props.padding : null};
  margin: ${props => props.margin ? props.margin : null};

  > input {
    width: 100%;
    padding: ${props => props.innerPadding ? props.innerPadding : '1rem 1.5rem'};
    border-radius: 10px;
    border: 1px solid #adafca30;
    color: ${props => props.onlyWhite ? '#3e3f5e' : '#3e3f5e'};
    background: ${props => props.onlyWhite ? '#fff' : '#fff'};
    font-size: 1rem;
    font-weight: 700;
    transition: .3s ease-in-out;

    &:focus {
      border-color: #5d65e170;
    }

    &:valid + label {
      font-size: .8rem;
      transform: translateY(-1.7rem);
    }

    &:invalid + label {
      transform: translateY(0);
    }

    &:focus + label {
      font-size: .8rem;
      transform:translateY(-1.7rem);
    }

    &:read-only {
      background: #eeeef5;
      color: #bbbbbb;
      cursor: no-drop;


      & + label {
        font-size: .8rem;
        transform:translateY(-1.7rem);
      }
    }
  }

  > span {
    position: absolute;
    padding: 0 .5rem;
    right: .5rem;
    margin: 20px 0;
    color: #adafca;
    font-size: 18px;
  }
`;

export const Input = styled.input<IInputAnimateProps>`
  width: 100%;
  padding: ${props => props.innerPadding ? props.innerPadding : '1rem 1.5rem'};
  border-radius: 12px;
  border: 1px solid #adafca30;
  color: ${props => props.onlyWhite ? '#fff' : '#fff'};
  background: ${props => props.onlyWhite ? '#fff' : '#fff'};
  font-size: 1rem;
  font-weight: 700;
  transition: .3s ease-in-out;

  &:focus {
    border-color: #3e3f5e;
  }

  &:valid + label {
    font-size: .8rem;
    transform: translateY(-1.7rem);
  }

  &:invalid + label {
    transform: translateY(0);
  }

  &:focus + label {
    font-size: .8rem;
    transform:translateY(-1.7rem);
  }
`;

export const Label = styled.label<IInputAnimateProps>`
  color: #3e3f5e;
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  top: 20px;
  left: 1rem;
  padding: 0 5px;
  pointer-events: none;
  background: ${props => props.onlyWhite ? '#fff' : '#fff'};
  transition: .3s ease;
  transform: translateY(0);
`;
