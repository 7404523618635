import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Links = styled(Link)`
  gap: 0.5rem;
`;

export const Badge = styled.span`
  font-size: 9px;
  margin-left: 4px !important;
  background: #e13131;
  color: #fff;
  padding: 2px 4px;
  border-radius: 4px;
  margin-top: 2px;
`;
