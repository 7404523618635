import React, { useRef } from 'react';

import { Container, Label } from './styles';
import Icon from '../Icon';

interface IInputSelectPureCssProps {
  label: string;
  padding?: string;
  margin?: string;
  onlyWhite?: boolean;
  name: string;
  showLabel?: boolean;
  selected?: boolean;
  showPlaceholder?: boolean;
  labelText?: string;
  placeholder?: string;
  options: {
    value: string;
    label: string;
  }[];
  setValue(value: string): void;
}

const InputSelectPureCss: React.FC<IInputSelectPureCssProps> = ({setValue, options, showLabel, labelText, label, name, padding, margin, onlyWhite, placeholder, selected, ...rest}) => {

  return (
    <>
      <Container padding={padding} margin={margin} onlyWhite={onlyWhite}>
        <select name={name} onChange={(e) => setValue(e.target.value)} {...rest}>
          {showLabel && <option className="default" defaultValue={labelText} value="" key="777777">{placeholder ? placeholder : labelText}...</option>}
          {options.map((option, index) => (
            <option key={index} value={option.value} selected={selected}>{option.label}</option>
          ))}
        </select>
        <Icon icon="icon-icon-big-arrow" />
        {showLabel && <Label>{labelText}</Label>}
      </Container>
    </>
  );
}

export default InputSelectPureCss;
