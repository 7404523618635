import styled from 'styled-components';

interface IMainHeaderProps {
  cover_url: string;
  poster_url: string;
}

export const Container = styled.div<IMainHeaderProps>`
  grid-area: MH;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
  width: 100%;
  background-image: url(${props => props.cover_url});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 0 6rem 0;

  /* TABLET */
  @media (max-width: 700px) {
    height: auto;
    padding: 2rem 0;
    background-position: top center;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 20%, rgb(9 0 41)), url(${props => props.cover_url});
    background-size: contain;
    background-repeat: no-repeat;

    &:before {
      display: none;
    }

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 360px;
      left: 0;
      top: 0;
      z-index: 0;
      background: radial-gradient(circle,rgba(104,33,158, 0.6) 0%,rgba(34,9,79,1) 80%);
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 0;
    background: radial-gradient(circle,rgba(104,33,158, 0.6) 0%,rgba(34,9,79,1) 80%);
  }

  &::after {
    content: '';
    width: 100%;
    height: 130px;
    left: 0;
    bottom: 0;
    position: absolute;
    background-image: linear-gradient(to top, #090029  30%, transparent);
    z-index: 0;

    /* TABLET */
    @media (max-width: 700px) {
      height: 360px;
    }
    /* MOBILE */
    @media (max-width: 500px) {
      height: 230px;
    }
    /* MOBILE2 */
    @media (max-width: 300px) {
      height: 170px;
    }
  }
`;

export const MainContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  margin-top: 0;
  z-index: 9;

  /* TABLET */
  @media (max-width: 700px) {
    margin-top: 132px;
    border-radius: 0;
    //box-shadow: 0px -15px 40px 15px #090029;
    //border-radius: 2rem 2rem 0 0;
    //background: #090029;
    padding: 1.5rem 0 2rem 0;
  }

  /* TABLET */
  @media (max-width: 450px) {
    margin-top: 145px;
  }

  /* MOBILE */
  @media (max-width: 380px) {
    margin-top: 130px;
  }

  /* MOBILE pp */
  @media (max-width: 350px) {
    margin-top: 100px;
  }

  > section {
    position: relative;
    width: 100%;
    display: flex;
    padding: 0 1rem;
    gap: 2rem;
    flex-direction: row;

    /* TABLET */
    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      padding: 0 2rem;
    }

    > aside {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      /* TABLET */
      @media (max-width: 1000px) {
        display: none;
      }

      > div {
        position: relative;
        width: 300px;
        height: 450px;
        overflow: hidden;
        border-radius: 10px;
        transition: all .4s ease-in-out;
        cursor: pointer;
      }
    }

    > main {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > h1 {
        font-size: 2.2rem;
        color: #fff;
        line-height: 1;

        /* MOBILE */
        @media (max-width: 600px) {
          font-size: 1.5rem;
          margin: 0 0 1rem 0;
        }
      }

      > h2 {
        font-size: 1rem;
        color: #fff;
        font-weight: 600;

        /* MOBILE */
        @media (max-width: 600px) {
          font-size: .85rem;
        }
      }

      > h4 {
        color: #77ffaa;
        margin: 0 0 1rem 0;
      }

      > h5 {
        color: #fff;
        font-size: 1rem;
        margin: .5rem 0;
      }

      > p {
        color: #fff;

        /* MOBILE */
        @media (max-width: 600px) {
          font-size: .75rem;
        }

        > button {
          border: none;
          margin-left: .5rem;
          color: #fff;
          font-weight: 600;
          background: none;
          text-decoration: underline;
        }
      }

      > main {
        margin: .5rem 0 0 0;

        > div {
          position: relative;
          padding: 8px 16px;
          border-radius: 6px;
          background: #ffffff2e;
          backdrop-filter: blur(5px) saturate(180%);
          border: 1px solid #ffffff30;
          width: fit-content;

          > button {
            position: absolute;
            right: -12px;
            top: -10px;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #201f8c2e;
            border: 1px solid #ffffff30;
            backdrop-filter: blur(5px) saturate(180%);

            > svg {
              color: #fff;
            }
          }

          > p {
            color: #fff;
            margin-top: .5rem;
          }

          > h5 {
            color: #fff;
            font-size: 1rem;
          }
        }


        > button {
          color: #fff;
          display: inline-flex;
          background: #090029b3;
          padding: .75rem 1.2rem;
          backdrop-filter: blur(5px);
          transition: all .3s ease-in-out;
          animation: changeBackgroundColor 4s infinite;
          margin-top: .5rem;
          border-radius: 4px;


          &:hover {
            background: #ff0000;
            animation: none;
          }

          /* MOBILE */
          @media (max-width: 1000px) {
            font-size: .75rem;
            width: 100%;
            display: flex;
            justify-content: center;
            animation: changeBackgroundColor2 4s infinite;

            @keyframes changeBackgroundColor2 {
              0% {
                background-color: #ff0000;
              }
              50% {
                background-color: #09002910;
              }
              100% {
                background-color: #ff0000;
              }
            }
          }

          @keyframes changeBackgroundColor {
            0% {
              background-color: #090029b3;
            }
            50% {
              background-color: #09002910;
            }
            100% {
              background-color: #090029b3;
            }
          }
        }

        > p {
          color: #fff;
          margin-top: .5rem;
        }
      }

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        > span {
          margin-left: .5rem;
          color: #fff;
          font-weight: 600;
        }

        > button {
          border: none;
          margin-left: .5rem;
          color: #fff;
          font-weight: 600;
          background: none;
          text-decoration: underline;
        }
      }
    }
  }
`;

export const ArrowBack = styled.button`
  position: absolute;
  height: 15px;
  width: 15px;
  font-size: 14px;
  top: 1.2rem;
  right: 1.2rem;
  border: none;
  background: none;
  color: ${props => props.theme.colors.textColor};
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h4 {
    padding: 0 0 5px 7px;
    color: #adafca;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
  }

  > div {
    margin: .25rem 0;
    width: 100%;
    padding: 12px 1rem;
    display: flex;
    align-items: center;

    &.active {
      border-radius: 12px;
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.primary};
      box-shadow: ${props => props.theme.colors.shadowBox};

      > a, span {
        color: #fff;
      }
    }

    > a {
      text-decoration: none;
      transition: all .2s ease-in-out;
      font-weight: 700;
      text-align: left;
      display: flex;
      align-items: center;
      color: ${props =>props.theme.colors.textColor};

      > span {
        padding-right: 1.5rem;
        font-size: 20px;
        color: ${props =>props.theme.colors.primary};
      }
    }
  }
  `;

export const Chanels = styled.footer`
  display: flex;
  gap: .5rem;

  > a {

    > img  {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 4px;
    }
  }
`;

export const ModalMoviesDetails = styled.div`
  display: flex;
  margin: -32px -53px;
  overflow: hidden;

  > main {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    background: #090029;

    &:after {
      content: '';
      background: #21114780;
      position: absolute;
      width: 110%;
      height: 110%;
      left: -15px;
      top: -15px;
      backdrop-filter: blur(5px);
      border-radius: 10px;
    }

    > section {
      z-index: 999;

      > h1 {
        color: red;
      }
    }
  }
`;

export const ModalMoviesRating = styled.div`
  /* aaaaaaaa aqui 777777 */
`;

export const Head = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  color: #fff;
  z-index: 99999999;

  > main {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 1rem;

    > section {

      > button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .8rem;
        border: none;
        border-radius: 50%;
        background: #09002930;
        color: #fff;
        backdrop-filter: blur(5px);
        transition: all .3s ease-in-out;
        border: 1px solid ${props => props.theme.colors.gray}10;
        box-shadow: ${props => props.theme.colors.shadowBox};

        &:hover {
          background: #6c23c0;
        }

        /* TABLET */
        @media (max-width: 1000px) {

          > svg {
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }
`;
