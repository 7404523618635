const dark = {
  title: 'dark',

  colors: {
    primary: '#6c23c0',
    secondary: '#77ffaa',
    tertiary: '#00cc91',

    white: '#ffffff',
    black: '#22094f',
    black2: '#6c23c0',
    gray: '#9aa4bf',
    darkGray: '#293249',

    danger: '#ff0000',
    success: '#77ffaa',
    info: '#77ffaa',
    warning: '#ff6961',
    alteranative: '#7b9fc7',
    alteranative2: '#73eaf6',

    textColor: '#ffffff',
    secundaryTextColor: '#9aa4bf',

    bgContent: '#090029',
    bgContent2: '#090029',
    bgBox: '#6c23c0',
    bgAnimation: '#1d2333',
    shadowBox: '0 0 40px 0 rgba(0,0,0,.06);',

    bgBoxMenu: '#1d153a',
    shadowMenu: '3px 5px 20px 0 rgba(0,0,0,.12);',

    shadowAlternative: '3px 5px 40px 0 rgba(0,0,0,.1)',

    headerGradient: 'radial-gradient(circle, rgba(104,33,158,1) 0%, rgba(34,9,79,1) 54%)',

    progressBarGradient: 'linear-gradient(to right, #177afa 20%, #03f0d1)',

    textAreaWizColor: '#141821',

    separator: '#adafca20',
  }
};

export default dark;
