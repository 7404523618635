import styled from 'styled-components';

export const Container = styled.div`
  color: #9aa4bf80;
  text-align: center;
  padding: 2rem 0;
  font-size: 10px;

  > p {

    > a {
      color: ${props => props.theme.colors.secondary};
      text-decoration: none;

      &:hover {
        opacity: .75;
      }
    }
  }

  /* MOBILE */
  @media (max-width: 376px) {
    font-size: 8.5px !important;
  }
`;
