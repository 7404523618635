import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { useAuth } from "../../hooks/auth";

import api from "../../services/api";

import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { IoIosFlash } from "react-icons/io";

import Wysiwyg from "./Wysiwyg";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import Educards from "../../components/Educards";
import HeaderInfo from "../../components/HeaderInfo";
import ContentGrid from "../../components/ContentGrid";
import SelectInput from "../../components/SelectInput";
import InputCheckbox from "../../components/InputCheckbox";
import AsideMenuItems from "../../components/AsideMenuItems";
import TooltipAdapter from "../../components/TooltipAdapter";

import {
  Container,
  FlashCardButtonOpen,
  FormModalNotesContainer,
  GridContainer,
  HeaderTitle,
  MobileTable,
  NoneProgress,
} from "./styles";

interface IAreasProps {
  id: string;
  subject_id: string;
  subareas: {
    id: string;
    area_id: string;
    name: string;
    order: number;
    relevance: number;
    created_at: string;
    updated_at: string;
    relevance_scale: string;
    schedules: {
      id: string;
      user_id: string;
      subarea_id: string;
      theory: boolean | null;
      abstract: boolean | null;
      exercise: boolean | null;
      notes: string | null;
      revision: string | null;
      next_revision?: string;
      created_at: string;
      updated_at: string;
    }[];
  }[];
  name: string;
  order: number;
  created_at: string;
  updated_at: string;
}


const CronogramaDisciplina: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [mobileTable, setMobileTable] = useState(false);

  const [loading, setLoading] = useState(false);
  const [areas, setAreas] = useState<IAreasProps[]>([]);

  const [openModalFlashcards, setOpenModalFlashcards] = useState(new URLSearchParams(location.search).has('openFlashcards'));

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get(`/subjects/${slug}/areas/`);

        console.log(response.data);

        let dataAreas = response.data;

        if (slug === "lingua-estrangeira") {
          dataAreas = dataAreas.filter(
            (value: IAreasProps) => value.name === user?.language
          );
          console.log("filtered", dataAreas);
        }

        setAreas(dataAreas);
      } catch (error) {
        console.log(error);
        history.push("/cronograma");
        toast.error(
          "🤷‍♂️ Desculpe, a página que você tentou acessar não foi encontrada!",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [slug, history, user]);

  // Get window dimensions
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }

  // verifica a dimensão do dispositivo
  useEffect(() => {
    if (windowDimensions.width <= 875) {
      setMobileTable(true);
    } else {
      setMobileTable(false);
    }
  }, [windowDimensions]);

  useEffect(() => {
    const handleResize = () => {
      const { width, height } = getWindowDimensions();
      setWindowDimensions({ width, height });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleUpdateTheory = useCallback(
    async (checked: boolean, subarea_id: string) => {
      try {
        await api.patch(`/schedules/theory`, {
          subarea_id: subarea_id,
          theory: checked,
        });
      } catch (error) {
        console.log(error);
      }
    }, []);

  const handleUpdateAbstract = useCallback(
    async (checked: boolean, subarea_id: string) => {
      try {
        await api.patch(`/schedules/abstract`, {
          subarea_id: subarea_id,
          abstract: checked,
        });
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const handleUpdateExercise = useCallback(
    async (checked: boolean, subarea_id: string) => {
      try {
        await api.patch(`/schedules/exercise`, {
          subarea_id: subarea_id,
          exercise: checked,
        });
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const handleRevisionValue = useCallback(
    async (value: string, subarea_id: string) => {
      try {
        const response = await api.patch(`/schedules/revision`, {
          revision: value,
          subarea_id: subarea_id,
        });
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const handleOpenModalNote = useCallback((subarea_id: string, note?: string) => {
    let modalNotes = withReactContent(Swal);

    modalNotes.fire({
      html: (
        <FormModalNotesContainer>
          <header>
            <h1>{note ? 'Eitar' : 'Adicionar'} comentário</h1>
          </header>
          <main>
            <input type="hidden" name="subarea_id" value={subarea_id} />
            <Wysiwyg
              defaultValue={note}
              subarea_id={subarea_id}
              onSubmit={(newValue) => {
                setAreas((oldState) => {
                  const newState = oldState.map((area) => {
                    const newSubareas = area.subareas.map((subarea) => {
                      if (subarea.id === subarea_id) {

                        return {
                          ...subarea,
                          schedules: [
                            {
                              ...subarea.schedules[0],
                              notes: newValue,
                            },
                          ],
                        };
                      }

                      return subarea;
                    });

                    return {
                      ...area,
                      subareas: newSubareas,
                    };
                  });

                  return newState;
                });
              }}
            />
          </main>
        </FormModalNotesContainer>
      ),
      background: "#fff",
      backdrop: "rgba(110, 97, 198, .1)",
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
      width: mobileTable ? "100%" : '700px',
    });
  }, [mobileTable]);


  return (
    <>
      <Educards
        defaultShowCardsContainer={openModalFlashcards}
        setDefaultShowCardsContainer={() => {
          setOpenModalFlashcards((oldState) => !oldState);
        }}
      />
      <Container>
        <GridContainer>
          <aside>
            <AsideMenuItems />
          </aside>
          <main>
            <section
              style={
                loading
                  ? { height: "300px", overflow: "hidden", position: "relative" }
                  : {}
              }
            >
              {loading && <Loader isFixed={false} borderRadius="0px" />}
              {areas.map((area, index) => (
                <div key={index}>
                  <HeaderTitle>
                    <HeaderInfo
                      supTitle="Área do Conhecimento"
                      title={area.name}
                      margin={
                        index === 0 ? "0 0 1rem .5rem" : "2rem 0 2rem .5rem"
                      }
                      showSendButton={false}
                    />
                    <FlashCardButtonOpen onClick={() => {
                      console.log('open flashcards');
                      setOpenModalFlashcards(true)
                    }}>
                      <IoIosFlash size={28} />
                      Flashcards <span>{area.name}</span>
                    </FlashCardButtonOpen>
                  </HeaderTitle>
                  <main id="main-scroll">
                    {!mobileTable && (
                      <section>
                        <th className="text-center first-c">
                          Taxa de Relevância
                        </th>
                        <th className="text-left">Assunto</th>
                        <th className="rest">Teoria</th>
                        <th className="rest">Resumo</th>
                        <th className="rest">Exercício</th>
                        <th className="rest">Anotações</th>
                        <th className="rest last-c">Revisão</th>
                      </section>
                    )}
                    {area?.subareas?.map((subarea, index) => {

                      const defColor = {
                        excellent: "#00cc91",
                        good: "#2993e9",
                        fair: "#f88e37",
                        poor: "#ff0000",
                      };

                      const percentage = subarea.relevance;

                      const relevance = percentage >= 80 ? "excellent" : percentage >= 60 ? "good" : percentage >= 40 ? "fair" : "poor";

                      const relevanceColor = defColor[relevance];

                      return !mobileTable ? (
                        <table
                          key={index}
                          style={
                            index ===
                              (Number(area?.subareas?.length) - 1 || 0)
                              ? { border: "none" }
                              : {}
                          }
                        >
                          <tbody>
                            <tr>
                              <td className="text-center circle">
                                {subarea.relevance !== 0 ? (
                                  <>
                                    <CircularProgressbar
                                      value={subarea.relevance}
                                      text={`${subarea.relevance}%`}
                                      strokeWidth={7}
                                      maxValue={100}
                                      minValue={0}
                                      styles={buildStyles({
                                        // Rotation of path and trail, in number of turns (0-1)
                                        rotation: 0,
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'round',
                                        // Text size
                                        textSize: '24px',
                                        // How long animation takes to go from one percentage to another, in seconds
                                        pathTransitionDuration: 0.5,
                                        // Can specify path transition in more detail, or remove it entirely
                                        // pathTransition: 'none',
                                        // Colors
                                        pathColor: relevanceColor,
                                        textColor: '#fff',
                                        trailColor: '#adafca40',
                                        backgroundColor: 'transparent',
                                      })}
                                    />
                                  </>
                                ) : (
                                  <NoneProgress>-</NoneProgress>
                                )}
                              </td>
                              <td className="isName">
                                <h4>{subarea.name}</h4>
                              </td>
                              <td className="text-center">
                                <InputCheckbox
                                  id={subarea.id + "_theory"}
                                  onChange={(event) =>
                                    handleUpdateTheory(
                                      event.target.checked,
                                      subarea.id
                                    )
                                  }
                                  checkedd={
                                    subarea?.schedules[0]?.theory || false
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <InputCheckbox
                                  id={subarea.id + "_abstract"}
                                  onChange={(event: any) =>
                                    handleUpdateAbstract(
                                      event.target.checked,
                                      subarea.id
                                    )
                                  }
                                  checkedd={subarea?.schedules[0]?.abstract || false}
                                />
                              </td>
                              <td className="text-center">
                                <InputCheckbox
                                  id={subarea.id + "_exercise"}
                                  onChange={(event: any) =>
                                    handleUpdateExercise(
                                      event.target.checked,
                                      subarea.id
                                    )
                                  }
                                  checkedd={subarea?.schedules[0]?.exercise || false}
                                />
                              </td>
                              <td className="anottation">
                                <TooltipAdapter
                                  cursor="pointer"
                                  onClick={() => handleOpenModalNote(subarea.id, (subarea?.schedules[0]?.notes || ''))}
                                  alt={subarea?.schedules[0]?.notes ? 'Ver anotação' : 'Adicionar anotação'}
                                >
                                  <img className={subarea?.schedules[0]?.notes ? 'contain-note' : ''} src="https://educacione-api.s3.sa-east-1.amazonaws.com/logo/notes.png" alt="" />
                                </TooltipAdapter>
                              </td>
                              <td className="text-center">
                                <SelectInput
                                  options={[
                                    {
                                      value: "Selecione...",
                                      label: "Selecione...",
                                    },
                                    {
                                      value: "first-revision",
                                      label: "1ª revisão",
                                    },
                                    {
                                      value: "second-revision",
                                      label: "2ª revisão",
                                    },
                                    {
                                      value: "third-revision",
                                      label: "3ª revisão",
                                    },
                                    {
                                      value: "fourth-revision",
                                      label: "4ª revisão",
                                    },
                                    {
                                      value: "fifth-revision",
                                      label: "5ª revisão",
                                    },
                                  ]}
                                  defaultValue={
                                    subarea.schedules[0]?.revision ||
                                    "Selecione..."
                                  }
                                  onChange={(e) => {
                                    handleRevisionValue(
                                      e.target.value,
                                      subarea.id
                                    );
                                  }}
                                  placeHolder="Selectione..."
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <MobileTable>
                          <header
                            key={index}
                            style={
                              index === 0 ? { borderRadius: "5px 5px 0 0" } : {}
                            }
                          >
                            <aside>
                              <h4>{subarea.name}</h4>
                              <TooltipAdapter
                                cursor="pointer"
                                onClick={() => handleOpenModalNote(subarea.id, (subarea?.schedules[0]?.notes || ''))}
                                alt={subarea?.schedules[0]?.notes ? 'Ver anotação' : 'Adicionar anotação'}
                              >
                                <img className={subarea?.schedules[0]?.notes ? 'contain-note' : ''} src="https://educacione-api.s3.sa-east-1.amazonaws.com/logo/notes.png" alt="" />
                              </TooltipAdapter>
                            </aside>
                            {subarea.relevance > 0 && (
                              <div>
                                <CircularProgressbar
                                  value={subarea.relevance}
                                  text={`${subarea.relevance}%`}
                                  strokeWidth={6}
                                  maxValue={100}
                                  minValue={0}
                                  className="progress-bar-mobile"
                                  styles={buildStyles({
                                    // Rotation of path and trail, in number of turns (0-1)
                                    rotation: 0,
                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'round',
                                    // Text size
                                    textSize: '24px',
                                    // How long animation takes to go from one percentage to another, in seconds
                                    pathTransitionDuration: 0.5,
                                    // Can specify path transition in more detail, or remove it entirely
                                    // pathTransition: 'none',
                                    // Colors
                                    pathColor: relevanceColor,
                                    textColor: '#fff',
                                    trailColor: '#adafca40',
                                    backgroundColor: 'transparent',
                                  })}
                                />
                              </div>
                            )}
                          </header>
                          <main
                            style={
                              index ===
                                (Number(area?.subareas?.length) - 1 || 0)
                                ? { borderRadius: "0 0 5px 5px" }
                                : {}
                            }
                          >
                            <div>
                              <h5>Teoria</h5>
                              <InputCheckbox
                                id={subarea.id + "_theory"}
                                onChange={(event) =>
                                  handleUpdateTheory(
                                    event.target.checked,
                                    subarea.id
                                  )
                                }
                                checkedd={subarea?.schedules[0]?.theory || false}
                              />
                            </div>
                            <div>
                              <h5>Resumo</h5>
                              <InputCheckbox
                                id={subarea.id + "_abstract"}
                                onChange={(event) =>
                                  handleUpdateAbstract(
                                    event.target.checked,
                                    subarea.id
                                  )
                                }
                                checkedd={
                                  subarea?.schedules[0]?.abstract || false
                                }
                              />
                            </div>
                            <div>
                              <h5>Exercício</h5>
                              <InputCheckbox
                                id={subarea.id + "_exercise"}
                                onChange={(event) =>
                                  handleUpdateExercise(
                                    event.target.checked,
                                    subarea.id
                                  )
                                }
                                checkedd={
                                  subarea?.schedules[0]?.exercise || false
                                }
                              />
                            </div>
                            <div>
                              <h5>Revisão</h5>
                              <SelectInput
                                options={[
                                  {
                                    value: "Selecione...",
                                    label: "Selecione...",
                                  },
                                  {
                                    value: "first-revision",
                                    label: "1ª revisão",
                                  },
                                  {
                                    value: "second-revision",
                                    label: "2ª revisão",
                                  },
                                  {
                                    value: "third-revision",
                                    label: "3ª revisão",
                                  },
                                  {
                                    value: "fourth-revision",
                                    label: "4ª revisão",
                                  },
                                  {
                                    value: "fifth-revision",
                                    label: "5ª revisão",
                                  },
                                ]}
                                defaultValue={
                                  subarea.schedules[0]?.revision || "Selecione..."
                                }
                                onChange={(e) => {
                                  handleRevisionValue(e.target.value, subarea.id);
                                }}
                                placeHolder="Selectione..."
                              />
                            </div>
                          </main>
                        </MobileTable>
                      )
                    }
                    )}
                  </main>
                </div>
              ))}
            </section>
          </main>
        </GridContainer>

        <ContentGrid>
          <Footer />
        </ContentGrid>
      </Container>
    </>
  );
};

export default CronogramaDisciplina;
