import styled, { css } from 'styled-components';

import checkIcon from '../../assets/check-icon.svg';
import blockIcon from '../../assets/block-icon.svg';

interface ICardQuestionProps {
  status: 'corrected' | 'wrong' | 'blank';
  current: boolean;
  blank: boolean;
  onClick(): void;
}

const buttonColorVariations = {
  corrected: css`
    background: #03bb85;
    color: #fff;
    transition: all .2s ease-in-out;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: #03bb85de;
    }
  `,
  wrong: css`
    background: #e74c3c;
    color: #fff;
    transition: all .2s ease-in-out;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: #e74c3ccc;
    }
  `,
  blank: css`
    background: ${props => props.theme.colors.bgBox};
    color: #adafca !important;
    transition: all .2s ease-in-out;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: ${props => props.theme.colors.bgBox}80;
    }
  `,
};

export const Container = styled.div`
  position: relative;
  width: 100%;

  .active {
    color: #ffffff;
    background: ${props => props.theme.colors.primary};
  }

  .open {
    height: auto;
    margin-bottom: 1rem;
    transition: all .35s ease-in-out;

    &:before {
      opacity: 0;
    }

    > div {
      bottom: 0;
      animation: none;

      > svg {
        transition: all .25s ease-in-out;
        transition-delay: .3s;
        transform: rotate(180deg);
      }
    }
  }
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* LAPTOP */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* LAPTOP */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      > li {
        margin: .25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${props => props.theme.colors.textColor};
          transition: all .25s ease-in-out;
          padding: 1rem;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${props => props.theme.colors.primary};
          }

          > svg {
            margin-right: .5rem;
          }
        }
      }
    }
  }


  > main {
    display: flex;
    flex-direction: column;

    > section:nth-child(1) {
      border-radius: 10px;
      padding: 1rem;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};
    }

    > section:nth-child(1) {
      border-radius: 10px;
      padding: 1rem;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};
    }

  }
`;

export const ContentGrid1 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 4fr 1fr;

  /* TABLET */
  @media (max-width: 1250px) {
    display: flex;
    flex-direction: column-reverse;
  }

  /* MOBILE */
  @media (max-width: 480px) {
    //grid-template-columns: repeat(1, 1fr);
  }

  > section:nth-child(1) {
    position: relative;
    border-radius: 12px;
    padding: 1rem 28px 1rem 28px !important;
    color: ${props => props.theme.colors.textColor};
    box-shadow: ${props => props.theme.colors.shadow};
    background: ${props => props.theme.colors.bgBoxMenu};
    margin-bottom: 1rem;
  }

  > section:nth-child(2) {
    position: sticky;
    top: 1rem;
    height: 0;

    /* TABLET */
    @media (max-width: 1250px) {
      position: relative;
      height: auto;
      top: 0;
    }

    > main {
      background: ${props => props.theme.colors.bgBoxMenu};
      position: relative;
      padding: 32px 28px 28px 28px;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(5, 1fr);
      border-radius: 12px;

      /* MOBILE */
      @media (max-width: 1280px) {
        //background: red;
      }
    }
  }
`;

export const Question = styled.div`
  margin-bottom: 1rem;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    > div:nth-child(1) {
        > h1 {
        text-align: left;

        > span {
          font-size: 13px;
        }
      }
    }
    > div:nth-child(2) {
      > p {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: #adafca;
      }
    }
  }

  > main {
    margin-bottom: 1rem;

    > div {

      > p {
        font-size: 14px;
        margin-bottom: .5rem;
        font-weight: 600;

        > img {
          width: auto !important;
          height: auto !important;
          background: #fff;
          padding: .5rem;
          border-radius: 8px;
          max-width: 100%;
        }
      }
    }
  }

  > footer {
    font-weight: 600;

    > p {
      font-weight: 600;
    }
  }
`;

interface IInputGroupProps {
  readOnly: boolean;
  correctAnswer: string;
  checkedAnswer: string;
  value: string;
}


export const InputGroup = styled.div<IInputGroupProps>`
    display: block;
    margin: 10px 0;
    position: relative;
    color: ${({ theme }) => theme.colors.textColor};

    label {
      ${props => props.readOnly && css`
        ${props.correctAnswer === props.value ? 'background: #03bb85; color: #fff;' : (props.checkedAnswer === props.value && 'background: #e74c3c;')};
      `}
      padding: 12px 50px;
      width: 100%;
      display: block;
      text-align: left;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
      border-radius: 8px;
      border: 1px solid ${props => props.theme.colors.primary}25;
      ${props => props.readOnly && css`
        cursor: not-allowed;
      `}
      ${props => props.readOnly && css`
        ${props.correctAnswer === props.value ? 'border: 1px solid #03bb85;' : (props.checkedAnswer === props.value && 'border: 1px solid ##e74c3c;')};
      `}

      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: #5562eb;
        background-color: ${props => props.theme.colors.primary};
        ${props => props.readOnly && css`
          ${props.correctAnswer === props.value ? 'background: #03bb85' : (props.checkedAnswer === props.value && 'background: #e74c3c')};
        `}
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        width: 1rem;
        height: 1rem;
        content: '';
        border: 2px solid #e6e8fc;
        background: url(${checkIcon});

        ${props => props.readOnly && css`
          ${props.correctAnswer === props.value ?
            `background: url(${checkIcon}) #189570;border: 2px solid #289777;`
            :
            null
          };
        `}
        ${props => props.readOnly && css`
          ${props.correctAnswer !== props.value ?
            `background: url(${blockIcon}) #fff`
            :
            null
          };
        `}
        background-repeat: no-repeat;
        background-position: 1px 1px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        ${props => props.readOnly && css`
          cursor: not-allowed;
        `}
        transition: all 200ms ease-in;
      }
    }

    input:checked ~ label {
      color: #fff;

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
        width: 100%;
      }

      &:after {
        background-color: #54E0C7;
        border-color: #54E0C7;

        ${props => props.readOnly && css`
          ${props.correctAnswer === props.value ? 'background-color: #189570; border-color: #289777' : null };
        `}

       ${props => props.readOnly && css`
          ${props.correctAnswer !== props.value ? 'background-color: #c92413; border-color: #bf3d2f' : null };
        `}
      }
    }

    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
`;

export const ModalFinishForTime = styled.div`


`;


export const ShowResultsModal = styled.div`

  > h4 {
    font-size: 70px;
  }

  > button {
    background: #615dfa;
    color: #fff;
    width: 170px;
    border: none;
    border-radius: 4px;
    padding: 0.9rem 0;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    align-items: center;
    box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
    font-size: .75rem;
    text-transform: uppercase;
    margin: 2rem 0 .5rem 0;
    transition: all .3s ease-in-out;

    &:hover {
      background: #4944e6;
    }
  }

  > p {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #adafca;
    transition: all .3s ease-in-out;
    margin: 0 0 .5rem 0;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const CardQuestion = styled.div<ICardQuestionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 1rem;
  border-radius: 4px;
  ${props => buttonColorVariations[props.status]};
  background: ${props => props.status === 'blank' && props.theme.colors.bgContent};
  color: #fff;
  font-weight: bold;
  animation: ${props => props.current ? 'pulseAnimationCss 2s infinite' : 'unset' };

  @keyframes pulseAnimationCss {
    0% {
      -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.primary};
      box-shadow: 0 0 0 0 ${props => props.theme.colors.primary};
    }
    70% {
        -moz-box-shadow: 0 0 0 5px ${props => props.theme.colors.primary}10;
        box-shadow: 0 0 0 5px ${props => props.theme.colors.primary}10;
    }
    100% {
        -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.primary}10;
        box-shadow: 0 0 0 0 ${props => props.theme.colors.primary}10;
    }
  }
`;

export const FooterMenu = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.primary};
  box-shadow: ${props => props.theme.colors.shadowBox};
  z-index: 9;
  width: 100vw;
  height: 80px;
  left: 0;
  bottom: 0;
  padding: 0 86px;
  z-index: 999999999999999999999999;

  > section:nth-child(1) {
    text-align: center;
    margin: 0 .5rem;

    > p {
      color: #fff;
      font-size: .75rem;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1;
    }

    > span {
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 1;
    }
  }

  > section:nth-child(2) {
    text-align: center;
    margin: 0 .5rem;

    > p {
      color: #fff;
      font-size: .75rem;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1;
    }

    > span {
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 1;
    }
  }

  > section:nth-child(3) {
    text-align: center;
    margin: 0 .5rem;

    > button {
      margin: 0 .5rem;
      border: none;
      border-radius: 7px;
      padding: 1rem 1rem;
      min-width: 170px;
      text-align: center;
      justify-content: center;
      font-weight: bold;
      align-items: center;
      box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
      font-size: .75rem;
    }
  }
  `;

  export const InitializeConfigs = styled.div`
    position: relative;
    border-radius: 12px;
    padding: 34px 28px !important;
    color: ${props => props.theme.colors.textColor};
    box-shadow: ${props => props.theme.colors.shadow};
    background: ${props => props.theme.colors.bgBoxMenu};

    > hr {
      background-color: transparent;
      background-image: linear-gradient(90deg, rgba(206,211,246,0) 0, ${props => props.theme.colors.textColor}50 38%, ${props => props.theme.colors.textColor}50 64%, rgba(206,211,246,0) 99%);
      opacity: .3;
      margin: 30px 0 0;
      height: 1px;
      margin: 3rem 0 0 0;
    }

    > form {

      > section {
        display: flex;
        flex-direction: row;
        gap: 2rem;

        /* TABLET */
        @media (max-width: 770px) {
          flex-direction: column;
          gap: 0;
        }

        > main {

          > label {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            cursor: pointer;

            span:first-child {
              margin-right: .5rem !important;
            }
          }
        }

        > div {
          margin: 1rem 0 0 0;
          border-left: 1px solid ${props => props.theme.colors.separator};
          padding-left: 2rem;

          /* TABLET */
          @media (max-width: 770px) {
            border: none;
            padding-left: 0;
            gap: 1rem;
          }

          > label {
            font-size: 1rem;
            margin-bottom: 1rem;
            display: block;
            font-weight: bold;
          }

          > div {
            width: 100%;
          }

          select {
            width: 100%;
            margin: 0;
            padding: 10px;
          }
        }
      }

      > div {
        margin: 1rem 0 0 0;

        /* TABLET */
        @media (max-width: 770px) {
          display: flex;
          flex-direction: column;

          > button {
            margin: 1rem 0 0 0 !important;
          }

          > span {
            margin: 1rem 0 0 0 !important;
          }
        }

        > button {
          background: #74ac89;
          padding: 14px 20px;
          border-radius: 4px;
          text-transform: uppercase;
          color: #fff;
          font-weight: 700;
          width: 180px;
        }

        > span {
          opacity: .4;
          margin: 0 0 0 1rem;
          font-size: 12px;
        }
      }
    }
  `;

export const LastResultsContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  /* TABLET */
  @media (max-width: 951px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* TABLET */
  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContentGrid3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* TABLET */
  @media (max-width: 768px) {}
  /* MOBILE */
  @media (max-width: 480px) {}
`;

export const Showing = styled.div`
  color: #9aa4bf95;
  margin-top: 2rem;
  text-align: center;
`;


export const ResultRow = styled.div`
  color: ${props => props.theme.colors.textColor};
  position: relative;
  padding: 1rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 1rem 0;
  box-shadow: ${props => props.theme.colors.shadowBox};
  background: ${props => props.theme.colors.bgContent2};
  border: 1px solid ${props => props.theme.colors.gray}20;
  border-radius: 10px;
  transition: all .2s ease-in-out;

  > header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;

      > h1 {
        font-size: 1rem;
        color: #fff;
        font-weight: 700;
        background: red;
        display: flex;
        height: 2.2rem;
        width: 2.2rem;
        align-items: center;
        justify-content: center;
        border-radius:50%;
        background: ${props => props.theme.colors.primary}80;
      }

      > span {
        margin: 0 0 0 .5rem;
        font-size: 11px;
        color: ${props => props.theme.colors.gray};
      }
    }

    > aside {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      font-size: 11px;
      color: ${props => props.theme.colors.gray};
    }

  }

  > main {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    svg {
      width: 180px;
      height: 180px;
    }

    .progress-bar-percent {
      font-size: 2em;
    }

    > div {

      > div {
        display: flex;
        justify-content: center;
        width: auto;
      }
    }

  }

  > footer {
    position: relative;
    width: 100%;
    height: 75px;
    overflow: hidden;
    transition: all .25s ease-in-out;

    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-8px);
      }
      60% {
        transform: translateY(-4px);
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      inset: 0px;
      background-image: linear-gradient(to bottom,transparent -20%, ${props => props.theme.colors.bgContent2} 100%);
      opacity: 1;
      transition: all .3s ease-in-out;
    }

    > div {
      position: absolute;
      bottom: 14px;
      width: 100%;
      justify-content: end;
      align-items: center;
      display: flex;
      animation: bounce 2s infinite;
      cursor: pointer;

      > svg {
        color: ${props => props.theme.colors.gray};
      }
    }

    > h4 {
      display: inline-flex;
      padding: 5px 15px;
      color: #fff;
      background: ${props => props.theme.colors.primary};
      border-radius: 18px;
      font-size: 10px;
      border: 1px solid ${props => props.theme.colors.textColor}05;
      text-transform: uppercase;
      margin: .5rem .5rem 0 0;
    }

    > h6 {
      color: ${props => props.theme.colors.textColor};
      padding: 0 0 0 1rem;
    }
  }

`;
