import React, { useCallback, useContext, useState } from 'react';
import { Card, CardInner, CardFront, CardBack, CardWrapper, BtnRemoveContainer } from './styles';
import { ThemeContextDnd } from '..';
import { MdDeleteForever, MdEdit } from "react-icons/md";
import api from '../../../services/api';
import { useParams } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import ModalEditFlashCard from '../ModalEditFlashCard';
import Swal from 'sweetalert2';
import { ISubjectsProps } from '../../../Pages/Cronograma';


interface FlashcardProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  front: React.ReactNode;
  back: React.ReactNode;
  className?: string;
  color: string;
  subjects: ISubjectsProps[];
}

const Flashcard: React.FC<FlashcardProps> = ({ id, front, back, className, color, subjects }) => {
  const { slug } = useParams<{ slug: string }>();
  const [isFlipped, setIsFlipped] = useState(false);
  const [startDragPosition, setStartDragPosition] = useState<{ x: number, y: number } | null>(null);

  const style = useContext(ThemeContextDnd);

  const handleMouseDown = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setStartDragPosition({ x: event.clientX, y: event.clientY });
  }, []);

  const handleMouseUp = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (!startDragPosition) return;

    const threshold = 20;
    const moveX = Math.abs(event.clientX - startDragPosition.x);
    const moveY = Math.abs(event.clientY - startDragPosition.y);

    if (moveX < threshold && moveY < threshold) {
      setIsFlipped(!isFlipped);
    }
    setStartDragPosition(null);
  }, [startDragPosition, isFlipped]);

  const removeCard = useCallback(async(e: React.MouseEvent<HTMLDivElement>) => {
    const confirm = window.confirm('Tem certeza que deseja excluir esse card?');
    setIsFlipped((prev) => !prev);

    if (confirm) {
      console.log('Card removido');
      try {
        const response = api.delete(`/flashcards/${id}`);
        console.log(response);

        window.location.href = `/cronograma/${slug}/?openFlashcards`;
      } catch (error) {
        console.log(error);
      }
    }
  }, [id, slug]);

  const editCard = useCallback((front: any, back: any, color: string) => {
    setIsFlipped((prev) => !prev);

    let MySwal = withReactContent(Swal);
      MySwal.fire({
        html: (
          <ModalEditFlashCard
            slug={slug}
            flashcardId={id}
            subjects={subjects}
            back={back}
            front={front}
            color={color}
            closeModal={() => MySwal.close()}
          />),
        background:'#fff',
        backdrop: '#ffffff00',
        showConfirmButton: false,
        showCloseButton: true,
        showCancelButton: false,
        allowOutsideClick: true,
        allowEscapeKey : true,
        padding: '32px 16px',
      });
  }, [id, slug, subjects]);

  return (
    <CardWrapper className={className} style={style as any}>
      <Card onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} >
        <CardInner isFlipped={isFlipped}>
          <CardFront style={{ background: color }}>
            <BtnRemoveContainer>
              <MdEdit size={20} onClick={() => editCard(front, back, color)} />
              <MdDeleteForever size={20} onClick={(e: any) => removeCard(e)} />
            </BtnRemoveContainer>
            <main>
              <p>{front}</p>
            </main>
          </CardFront>
          <CardBack style={{ background: color + '50' }}>
            <BtnRemoveContainer>
              <MdEdit size={20} onClick={() => editCard(front, back, color)} />
              <MdDeleteForever size={20} onClick={(e: any) => removeCard(e)} />
            </BtnRemoveContainer>
            <main>
              <p>{back}</p>
            </main>
          </CardBack>
        </CardInner>
      </Card>
    </CardWrapper>
  );
};

export default Flashcard;
