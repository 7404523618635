import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import confetti from 'canvas-confetti';
import { formatDistance, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import htmlParser from 'react-html-parser';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Countdown, { CountdownRenderProps } from 'react-countdown';

import HeaderInfo from '../../components/HeaderInfo';
import ContentGrid from "../../components/ContentGrid";
import Loader from '../../components/Loader';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Footer from '../../components/Footer';

import { Container, GridContainer, ContentGrid1, Question, InputGroup, ModalFinishForTime, ShowResultsModal, CardQuestion, FooterMenu, InitializeConfigs, LastResultsContainer, ContentGrid3, Showing, ResultRow } from './styles';

import menuLinks from '../../utils/menuLinks';
import addZeroBefore from '../../utils/addZeroBefore';
import toHHMMSS from '../../utils/toHHMMSS';
import SelectInput from '../../components/SelectInput';
import InputCheckbox from '../../components/InputCheckbox';
import Pagination from '../../components/Pagination';
import { FaAngleDown } from 'react-icons/fa';
import ModalReportQuestion from '../../components/ModalReportQuestion';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import AsideMenuItems from '../../components/AsideMenuItems';

interface ILastTestsResultsProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: {
    id: string;
    user_id: string;
    practiceTest_index: string;
    practiceTest_correct_answers: number;
    practiceTest_total_questions: number;
    correct_answers: number;
    practiceTest_subjects?: ['matematica', 'humanas', 'natureza', 'portugues'];
    practiceTest_created_at: string;
    practiceTest_updated_at: string;
  }[];
}

interface IQuestionsProps {
  _id: string;
  id: number;
  enunciado: string;
  opcoes: Opcoes;
  alternativa_correta: string;
  info_prova: {
    ano: string;
    material: string;
  };
  titulo: string;
  total: string;
  total_certas: string;
  total_erradas: string;
  corrected?: boolean;
  blank?: boolean;
  status?: 'corrected' | 'wrong' | 'blank';
}

interface ITemplateAnswers {
  id: number;
  index: number;
  answer_checked: string;
  answer_correct: string;
  status: string;
}

interface Opcoes {
  [key: number]: {
    opcao: string;
    descricao: string;
  }
}

const Simulados: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const countDownRef = useRef<any>(null);

  const answerList = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [competencesSelected, setcompetencesSelected] = useState<string[]>([]);

  const [checkedAnswer, setCheckedAnswer] = useState('');
  const [readOnly, setReadOnly] = useState(false);
  const [loadNextQuestion, setLoadNextQuestion] = useState(true);

  const [dataQuestions, setDataQuestions] = useState<IQuestionsProps[]>([]);
  const [lastTestsResults, setLastTestsResults] = useState<ILastTestsResultsProps>({} as ILastTestsResultsProps);
  const [checkedQuestions, setCheckedQuestions] = useState<ITemplateAnswers[]>([]);

  const [totalTestTimeFormated, setTotalTestTimeFormated] = useState('');
  const [countCorrects, setCountCorrects] = useState(0);

  const [questionIndex, setQuestionIndex] = useState(0);

  const [startTime, setStartTime] = useState<number>(999999999999999999999999999);

  const [questionsQuantity, setQuestionsQuantity] = useState<any>();

  const [isStarted, setIsStated] = useState(false);
  const [moreInfoIsOpen, setMoreInfoIsOpen] = useState(false);
  const [validation, setValidation] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/practice-tests/', {
          params: { pageIndex: currentPage - 1, pageSize: 9, }
        });

        console.log(response.data);
        console.log('============ LAST RESULTS ===========');
        setLastTestsResults(response.data);

        //pagination
        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [currentPage]);


  //initialize simulado
  const handleInitialize = useCallback(() => {
    Swal.close();
    //setStartTime(Date.now() + (3600000 * 2) + 1800000); // 2h30 de simulado
    if (questionsQuantity > 0) {
      setStartTime(Date.now() + 150000 * Number(questionsQuantity)); // 2.5 min * qtd de questões
    }

  }, [setStartTime, questionsQuantity]);

  const handleSumbitResult = useCallback(async (countCorrectAnwsers: number) => {
    try {

      const response = await api.post('/practice-tests/', {
        total_questions: dataQuestions.length,
        correct_answers: countCorrectAnwsers,
        subjects: competencesSelected,
      });

      console.log('=== TEST RESULT ====')
      console.log(response.data);

    } catch (error) {
      console.log(error);
    }
  }, [competencesSelected, dataQuestions.length]);

  //finalizar simulado por tempo
  const handleFinishForTimer = useCallback(() => {
    setReadOnly(true);

    //submit result user
    handleSumbitResult(countCorrects);

    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        <ModalFinishForTime>
          <p>Você acertou {countCorrects} de {dataQuestions.length} questões!</p>
        </ModalFinishForTime>
      ),
      title: 'TEMPO ESGOTADO!',
      background: '#6e61c6',
      backdrop: '#4b3cad',
      showConfirmButton: true,
      showCloseButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      padding: 32,
      confirmButtonText: 'Voltar para Dashboard'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
        history.push('/');
      }
    });
  }, [countCorrects, dataQuestions.length, history, handleSumbitResult]);


  const renderer = useCallback(
    ({ hours, minutes, seconds, completed }: CountdownRenderProps) => {
      if (completed) {
        // Render a completed state
        return '';
      }

      // Render a countdown
      return <span>{`0${hours}`.slice(-2)}:{`0${minutes}`.slice(-2)}:{`0${seconds}`.slice(-2)}</span>;
    }, []);

  const handleChangeAnswer = useCallback((answer: string) => {
    if (!readOnly) {
      setCheckedAnswer(answer);
    }
  }, [readOnly]);

  const loadConfetti = useCallback(() => {
    setTimeout(() => {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2
        }
      });
    }, 0);

    setTimeout(() => {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2
        }
      });
    }, 0);

    setTimeout(() => {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2
        }
      });
    }, 0);

    setTimeout(() => {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2
        }
      });
    }, 0);

    setTimeout(() => {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2
        }
      });
    }, 0);
  }, []);

  const emoji = useMemo(() => {
    const emojis = ['🎉', '🥳', '😍', '😎', '😁', '😱'];
    const indice = Math.floor(Math.random() * emojis.length)
    return emojis[indice];
  }, []);

  const handleSaveCheckedAnswer = useCallback(() => {
    const currentQuestion = {
      id: dataQuestions[questionIndex].id,
      index: questionIndex,
      answer_checked: checkedAnswer,
      answer_correct: dataQuestions[questionIndex].alternativa_correta,
      status: 'answered',
    };

    // check if question index contain in array
    if (!checkedQuestions.find((question) => question.index === currentQuestion.index)) {
      setCheckedQuestions((oldQuestions) => [...oldQuestions, currentQuestion]);
    }

  }, [checkedAnswer, checkedQuestions, dataQuestions, questionIndex]);

  const navigationToQuestion = useCallback((index: number) => {
    setQuestionIndex(index);

    if (checkedQuestions.find((question) => question.index === index)) {
      const questionChecked = checkedQuestions.find((question) => question.index === index);

      if (!questionChecked) {
        return;
      }

      setReadOnly(true);
      setCheckedAnswer(questionChecked?.answer_checked);

    }
    else {
      setReadOnly(false);
      setCheckedAnswer('');
    }

  }, [checkedQuestions]);

  // Get next question
  const getNextQuestion = useCallback(() => {
    if (checkedAnswer === '') {
      return;
    }

    handleSaveCheckedAnswer();

    if (checkedQuestions.length === dataQuestions.length) {
      return;
    }

    if (questionIndex + 1 === dataQuestions.length) {
      const isChecked = dataQuestions.findIndex((questions) => {
        if (checkedQuestions.find((question) => question.id === questions.id)) {
          return false;
        }
        return true;
      });

      setQuestionIndex(isChecked);
      setReadOnly(false);
      setCheckedAnswer('');

      return;
    }

    //window.scrollTo(0, 0);
    window.scrollTo(0, (scrollRef.current?.scrollHeight || 0));
    console.log(scrollRef.current?.scrollHeight)
    setLoadNextQuestion(false);
    setTimeout(() => setLoadNextQuestion(true), 800);
    navigationToQuestion(questionIndex + 1);
  }, [checkedAnswer, handleSaveCheckedAnswer, navigationToQuestion, questionIndex, checkedQuestions, dataQuestions]);

  // Check if inputs are empty
  const checkCorrectQuestion = useCallback(() => {
    if (!checkedAnswer) {
      let MySwal = withReactContent(Swal);
      MySwal.fire({
        html: (
          <h2 style={{ lineHeight: 1 }}>Você esqueceu de assinalar uma resposta!</h2>
        ),
        icon: 'error',
        background: '#6e61c6',
        backdrop: 'rgba(110, 97, 198, .1)',
        confirmButtonColor: '#03bb85',
        showConfirmButton: true,
        confirmButtonText: 'Ops!'
      });

      return;
    }

    handleSaveCheckedAnswer();

    // Check if answr is correct
    const correctAnswerValue = dataQuestions[questionIndex].alternativa_correta;

    let countCorrectBug = countCorrects;

    if (checkedAnswer === correctAnswerValue) {
      setCountCorrects(oldValue => oldValue + 1);

      countCorrectBug += 1;

      loadConfetti();
      dataQuestions[questionIndex].corrected = true;
      dataQuestions[questionIndex].status = 'corrected';

    } else {
      dataQuestions[questionIndex].corrected = false;
      dataQuestions[questionIndex].status = 'wrong';
    }

    setReadOnly(true);

    // verifica se ja terminou
    if (checkedQuestions.length + 1 === dataQuestions.length) {
      const timeInMileseconds = 150000 * Number(questionsQuantity) - countDownRef.current.state.timeDelta.total;
      setTotalTestTimeFormated(toHHMMSS(timeInMileseconds));

      //submit result user
      handleSumbitResult(countCorrectBug); /* FIXME: AAA */

      let MySwal = withReactContent(Swal);
      MySwal.fire({
        html: (
          <ShowResultsModal>
            <h4>{emoji}</h4>
            <h1 style={{ lineHeight: 1.5, color: '#3e3f5e' }}>RESULTADO FINAL</h1>
            <h2 style={{ lineHeight: 1, color: '#3e3f5e' }}>{user.first_name}, você acertou {countCorrectBug} de {dataQuestions.length} questões!</h2>
            <button onClick={() => Swal.close()}>Revisar simulado</button>
            <p onClick={() => { history.push('/'); Swal.close(); }}>voltar para a Dashboard</p>
          </ShowResultsModal>
        ),
        background: '#FFF',
        backdrop: 'rgba(110, 97, 198, .1)',
        confirmButtonColor: '#514bf0',
        showConfirmButton: false,
        showCloseButton: true,
        allowEscapeKey: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.close();
        }
      });

      return;
    }

  }, [emoji, checkedAnswer, dataQuestions, loadConfetti, questionIndex, handleSaveCheckedAnswer, checkedQuestions, countCorrects, handleSumbitResult, user.first_name, questionsQuantity, history]);

  const competencesOptions = useMemo(() => [
    { id: "humanas", checked: false, label: "Ciências humanas e suas tecnologias" },
    { id: "natureza", checked: false, label: "Ciências da natureza e suas tecnologias" },
    { id: "portugues", checked: false, label: "Linguagens, códigos e suas tecnologias" },
    { id: "matematica", checked: false, label: "Matemática e suas tecnologias" },
  ], []);

  const handleValidateTestInitialize = useCallback(async () => {
    const countSelecteds = competencesOptions.filter((item) => item.checked === true).length;
    competencesOptions.forEach((competence) => {

      if (competence.checked) {
        competencesSelected.push(competence.id);
      }
    });

    console.log('>>>>>>> GERAR SIMULADO 🎉');
    console.log('>>>>>>> ', { questionsQuantity, countSelecteds, competencesSelected });

    if (questionsQuantity === undefined || competencesSelected.length === 0) {
      toast.error(`Selecione as disciplinas e a quantidade total de questões antes de iniciar o simulado! ❌`, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setValidation(true);
    } else {
      console.log('>>>>>> INICIAR SIMULADO <<<<<<<<');
      try {
        setLoading(true);

        const response = await api.post('/questions/random', {
          quantity: questionsQuantity,
          subjects: competencesSelected,
        });

        console.log(response.data);
        setDataQuestions(response.data);
        setIsStated(true);
        handleInitialize();
      }
      catch (e) {
        console.log(e);
      }
      finally {
        setLoading(false);
      }
    }
  }, [questionsQuantity, competencesOptions, competencesSelected, handleInitialize]);

  return (
    <>
      <Container>
        <GridContainer>
          <aside>
            <AsideMenuItems />
          </aside>
          <main>
            {!isStarted ? (
              <InitializeConfigs style={loading ? { maxHeight: '300px', overflow: 'hidden' } : {}}>
                {loading && <Loader isFixed={false} />}
                <HeaderInfo supTitle="Simulados Enem" title="Gerar Simulado" margin="0 0 1rem 0" showSendButton={false} />
                <form onSubmit={(e) => e.preventDefault()}>
                  <section>

                    {/* <div>
                      <label htmlFor="">Selecione as competências</label>
                      <Select
                        options={competencesOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        placeholder="Selecione as competências"
                        components={{
                          Option
                        }}
                        onChange={(selected: any) => {setOptionSelected(selected); console.log(selected)}}
                        value={optionSelected}
                        styles={reactSelectStyles}
                      />
                    </div> */}
                    <main>
                      {competencesOptions.slice(0, 2).map((competence, index) => (
                        <label>
                          <InputCheckbox
                            key={competence.id}
                            id={competence.id}
                            onChange={() => { competence.checked = !competence.checked; console.log(competencesOptions) }}
                          />
                          {competence.label}
                        </label>
                      ))}
                    </main>
                    <main>
                      {competencesOptions.slice(2, 4).map((competence, index) => (
                        <label>
                          <InputCheckbox
                            key={competence.id}
                            id={competence.id}
                            onChange={() => { competence.checked = !competence.checked; console.log(competencesOptions) }}
                          />
                          {competence.label}
                        </label>
                      ))}
                    </main>
                    <div>
                      <label style={validation && questionsQuantity === undefined ? { color: '#ff0000', opacity: 1 } : {}}>Quantidade de Questões</label>
                      <SelectInput
                        options={[
                          { value: "Selecione...", label: "Selecione..." },
                          { value: 10, label: "10" },
                          { value: 20, label: "20" },
                          { value: 30, label: "30" },
                          { value: 40, label: "40" },
                          { value: 50, label: "50" },
                          { value: 60, label: "60" },
                          { value: 70, label: "70" },
                          { value: 80, label: "80" },
                          { value: 90, label: "90" },
                          { value: 100, label: "100" },
                        ]}
                        onChange={(e) => { setQuestionsQuantity(e.target.value); console.log('Questions quantity: ', e.target.value) }}
                        placeHolder='Selectione...'
                      />
                    </div>
                  </section>
                  <div>
                    <button onClick={handleValidateTestInitialize}>Iniciar Simulado</button>
                    <span style={validation ? { color: '#ff0000', fontWeight: 'bold', opacity: 1 } : {}}>Selecione as disciplinas e a quantidade total de questões antes de iniciar o simulado.</span>
                  </div>
                </form>

                {lastTestsResults.records && lastTestsResults.records.length > 0 && (
                  <>
                    <hr />
                    <HeaderInfo supTitle="" title="Últimos resultados" margin="2rem 0 1rem 0" showSendButton={false} />

                    <LastResultsContainer>
                      {lastTestsResults && lastTestsResults.records?.map((result, index) => {

                        const defColor = {
                          excellent: "#00cc91",
                          good: "#2993e9",
                          fair: "#f88e37",
                          poor: "#ff0000",
                        };

                        const percentage = 100 * result.practiceTest_correct_answers / result.practiceTest_total_questions;
                        const relevance = percentage >= 80 ? "excellent" : percentage >= 60 ? "good" : percentage >= 40 ? "fair" : "poor";
                        const relevanceColor = defColor[relevance];

                        return (
                          <ResultRow key={index}>
                            <header>
                              <div>
                                <h1>#{result.practiceTest_index}</h1>
                                <span>há {formatDistance(parseISO(result.practiceTest_created_at), new Date(), { locale: ptBR })}</span>
                              </div>
                              <aside>
                                <p>acertos: {result.practiceTest_correct_answers}</p>
                                <p>total de questões: {result.practiceTest_total_questions}</p>
                              </aside>
                            </header>
                            <main>
                              <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                strokeWidth={4}
                                minValue={0}
                                maxValue={100}
                                styles={buildStyles({
                                  // Rotation of path and trail, in number of turns (0-1)
                                  rotation: 0,
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: 'round',
                                  // Text size
                                  textSize: '24px',
                                  // How long animation takes to go from one percentage to another, in seconds
                                  pathTransitionDuration: 0.5,
                                  // Can specify path transition in more detail, or remove it entirely
                                  // pathTransition: 'none',
                                  // Colors
                                  pathColor: relevanceColor,
                                  textColor: '#fff',
                                  trailColor: '#adafca40',
                                  backgroundColor: 'transparent',
                                })}
                              />
                            </main>
                            <footer className={moreInfoIsOpen ? 'open' : ''}>
                              <div onClick={() => setMoreInfoIsOpen(oldValue => !oldValue)}>
                                <FaAngleDown size={22} />
                              </div>
                              <h6>Áreas do conhecimento:</h6>
                              {result.practiceTest_subjects?.map((subject, index) => (
                                competencesOptions.map((competence) => (
                                  competence.id === subject && (
                                    <h4 key={index}>{competence.label}</h4>
                                  )
                                ))
                              ))}
                            </footer>
                          </ResultRow>
                        )
                      }
                      )}
                    </LastResultsContainer>

                    <ContentGrid3>
                      <Showing>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} simulados realizados`}</Showing>
                      {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
                    </ContentGrid3>
                  </>)}
              </InitializeConfigs>
            ) : (
              <ContentGrid1>
                <section ref={scrollRef} style={!loadNextQuestion ? { height: '416px', overflow: 'hidden' } : { height: 'auto' }}>
                  {!loadNextQuestion && <Loader isFixed={false} />}
                  <Question>
                    <header>
                      <div>
                        <h1>Questão {questionIndex + 1}<span>/{dataQuestions.length}</span></h1>
                      </div>
                      <div>
                        <p>{dataQuestions[questionIndex]?.info_prova.material}</p>
                      </div>
                    </header>
                    <main>
                      <div>
                        {htmlParser(dataQuestions[questionIndex]?.enunciado)}
                      </div>
                    </main>
                    <h1>Alternativas</h1>
                    <footer ref={answerList} style={readOnly ? { cursor: 'not-allowed' } : { cursor: 'default' }}>
                      <InputGroup
                        readOnly={readOnly}
                        value="A"
                        correctAnswer={dataQuestions[questionIndex]?.alternativa_correta}
                        checkedAnswer={checkedAnswer}
                      >
                        <input
                          id="radio1"
                          name="radio-1"
                          type="radio"
                          checked={checkedAnswer === 'A'}
                          onChange={() => { handleChangeAnswer('A') }
                          } />
                        <label htmlFor="radio1">{dataQuestions[questionIndex]?.opcoes[1].descricao}</label>
                      </InputGroup>
                      <InputGroup
                        readOnly={readOnly}
                        value="B"
                        correctAnswer={dataQuestions[questionIndex]?.alternativa_correta}
                        checkedAnswer={checkedAnswer}
                      >
                        <input
                          id="radio2"
                          name="radio-1"
                          type="radio"
                          checked={checkedAnswer === 'B'}
                          onChange={() => { handleChangeAnswer('B') }
                          } />
                        <label htmlFor="radio2">{dataQuestions[questionIndex]?.opcoes[2].descricao}</label>
                      </InputGroup>
                      <InputGroup
                        readOnly={readOnly}
                        value="C"
                        correctAnswer={dataQuestions[questionIndex]?.alternativa_correta}
                        checkedAnswer={checkedAnswer}
                      >
                        <input
                          id="radio3"
                          name="radio-1"
                          type="radio"
                          checked={checkedAnswer === 'C'}
                          onChange={() => { handleChangeAnswer('C') }
                          } />
                        <label htmlFor="radio3">{dataQuestions[questionIndex]?.opcoes[3].descricao}</label>
                      </InputGroup>
                      <InputGroup
                        readOnly={readOnly}
                        value="D"
                        correctAnswer={dataQuestions[questionIndex]?.alternativa_correta}
                        checkedAnswer={checkedAnswer}
                      >
                        <input
                          id="radio4"
                          name="radio-1"
                          type="radio"
                          checked={checkedAnswer === 'D'}
                          onChange={() => { handleChangeAnswer('D') }
                          } />
                        <label htmlFor="radio4">{dataQuestions[questionIndex]?.opcoes[4].descricao}</label>
                      </InputGroup>
                      <InputGroup
                        readOnly={readOnly}
                        value="E"
                        correctAnswer={dataQuestions[questionIndex]?.alternativa_correta}
                        checkedAnswer={checkedAnswer}
                      >
                        <input
                          id="radio5"
                          name="radio-1"
                          type="radio"
                          checked={checkedAnswer === 'E'}
                          onChange={() => { handleChangeAnswer('E') }
                          } />
                        <label htmlFor="radio5">{dataQuestions[questionIndex]?.opcoes[5].descricao}</label>
                      </InputGroup>
                    </footer>

                    <ModalReportQuestion questionId={String(dataQuestions[questionIndex]?._id)} />
                  </Question>
                </section>
                <section>
                  <main>
                    {dataQuestions.map((question, index) => {

                      return (
                        <CardQuestion
                          key={question.id}
                          current={dataQuestions[index].id === dataQuestions[questionIndex].id ? true : false}
                          status={dataQuestions[index].status || 'blank'}
                          blank={dataQuestions[index].corrected ? false : true}
                          onClick={() => navigationToQuestion(index)}
                        >
                          <p>{index + 1}</p>
                        </CardQuestion>
                      )
                    })}
                  </main>
                </section>
              </ContentGrid1>
            )}
          </main>
        </GridContainer>

        <ContentGrid>
          <Footer />
        </ContentGrid>
      </Container>
      {!loading && isStarted &&
        <FooterMenu>
          <section>
            <p>{checkedQuestions.length === dataQuestions.length ? 'Tempo de prova' : 'Tempo restante'}</p>
            {checkedQuestions.length !== dataQuestions.length ? (
              <Countdown
                ref={countDownRef}
                controlled={false}
                date={startTime}
                renderer={renderer}
                onComplete={handleFinishForTimer}
              />)
              :
              <span>
                {totalTestTimeFormated}
              </span>
            }
          </section>
          <section>
            <p>Acertos</p>
            <span>{addZeroBefore(countCorrects)}</span>
          </section>
          {checkedQuestions.length !== dataQuestions.length &&
            <section>
              {readOnly ?
                <Button color="outline" onClick={getNextQuestion}>PRÓXIMA QUESTÃO <Icon icon="icon-icon-small-arrow" margin="0 0 0 5px" size="8px" /></Button>
                :
                <Button color="tertiary" onClick={checkCorrectQuestion}>RESPONDER QUESTÃO</Button>
              }
            </section>}
        </FooterMenu>}
    </>
  );
}

export default Simulados;
