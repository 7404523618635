import styled from 'styled-components';

import bgTexture from '../../assets/scanline.png'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(104,33,158);
  background-image: radial-gradient(circle,rgba(104,33,158,1) 0%,rgba(34,9,79,1) 54%);
  height: 100vh;
  width: 100%;

  /* MOBILE */
  @media (max-width: 480px) {
    background: ${props => props.theme.colors.primary};
  }

  &:before {
    position: fixed;
    content: '';
    background-image: url(${bgTexture});
    background-size: 0.3rem;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: .1;
  }

  #video-bg {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }

  .with-pattern {
    background: none !important;
  }

  .opac {
    min-height: 100vh;
  }

  .auth-box-w .logo-w {
    text-align: center;
    padding: 10% 20%;
  }

  .os-icon {
    color: #f75c95 !important;
  }
  .auth-header {
    display: none;
  }
  .auth-box-w .auth-header:after {
    content: none;
  }

  .btn-primary,
  .all-wrapper .fc-button.fc-state-active {
    background-color: #f75c95 !important;
    border-color: #f75c95 !important;
  }

  .botfixed {
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  .botfixed h5 {
    color: #fff
  }
  /* anim */

  .paralaxx-abs {
    position: absolute;
  }

  .r0 {
    right: 0 !important;
  }

  .l0 {
    left: 0 !important;
  }

  .b0 {
    bottom: 0 !important;
  }

  .t0 {
    top: 0 !important;
  }

  .t50 {
    top: 40%;
  }

  @keyframes float1 {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(359deg);
    }
    100% {
        transform: rotate(0deg);
    }
  }

  @keyframes float2 {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0deg);
    }
  }

  @keyframes float3 {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-25px);
    }
    100% {
        transform: translatey(0px);
    }
  }

  @keyframes float4 {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-120px);
    }
    100% {
        transform: translatey(0px);
    }
  }

  @keyframes float5 {
    0% {
        transform: translatex(0px);
    }
    50% {
        transform: translatex(-65px);
    }
    100% {
        transform: translatex(0px);
    }
  }

  .paralaxx-abs {
    margin: 10px;
    transform: scale(.77);
  }

  .element-pic {
    transform: translatey(0px);
    animation: float1 15s ease-in-out infinite;
  }

  .element-pic img {
      transform: translatey(0px);
      animation: float1 15s ease-in-out infinite;
  }

  .element-pic2 {
      transform: translatey(0px);
      animation: float2 6s ease-in-out infinite;
  }

  .element-pic2 img {
      transform: translatey(0px);
      animation: float2 6s ease-in-out infinite;
  }

  .element-pic3 {
      transform: translatey(0px);
      animation: float3 8s ease-in-out infinite;
  }

  .element-pic3 img {
      transform: translatey(0px);
      animation: float3 8s ease-in-out infinite;
  }

  .element-pic4 {
      transform: translatey(0px);
      animation: float4 15s ease-in-out infinite;
  }

  .element-pic4 img {
      transform: translatey(0px);
      animation: float4 9s ease-in-out infinite;
  }

  .element-pic5 {
      transform: translatey(0px);
      animation: float5 9s ease-in-out infinite;
  }

  .element-pic5 img {
      transform: translatey(0px);
      animation: float5 9s ease-in-out infinite;
  }
  .share-bg {
      background-image: none !important;
      background: #ff7e5f !important;
      background: linear-gradient(to bottom, #feb47b, #ff7e5f) !important;
  }
  /*  */
  .auth-wrapper .all-wrapper {
      padding: 0 20px 20px 20px !important;
  }
  .auth-box-w form {
      padding: 40px 70px !important;
      padding-bottom: 40px !important;
  }
  .auth-box-w form .form-group .pre-icon {
      color: #9a2929  !important;
  }
  .btn-primary, .all-wrapper .fc-button.fc-state-active {
      background-color: #9a2929  !important;
      border-color: #9a2929  !important;
  }
  a {
      color: #9a2929  !important;
  }

  @media only screen and (max-width: 600px) {
      .paralaxx-abs {
          display: none;
      }
  }

  > main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: blue;
    width: 484px;
    padding: 3rem 4rem;
    border-radius: 10px;
    background: rgb(168 76 211 / 47%);
    box-shadow: rgb(94 92 154 / 12%) 0px 0px 60px 0px;
    backdrop-filter: blur(4px);
    border: 1px solid #6546d730;
    background: #fff;
    border: none;
    z-index: 9999;

    /* MOBILE */
    @media (max-width: 480px) {
      width: 90%;
      padding: 1rem 3rem;
    }

    /* MOBILE pp */
    @media (max-width: 350px) {
      width: 90%;
      padding: 1rem 1.5rem;
    }

    > img {
      width: auto;
      margin-bottom: 1rem;

      /* MOBILE */
      @media (max-width: 480px) {
        width: 220px;
        margin: 1rem 0;
      }
    }

    > h1 {
      margin-bottom: 2rem;
      color: #3e3f5e;
      font-weight: 700;
      line-height: 1em;
      text-align: center;
      font-size: 24px;
    }

    > form {
      width: 100%;

      > div {
        margin: 1rem 0;

        > p {
          color: #ced3e1;
          font-size: 15px;
          font-weight: 700;
          line-height: 22px;
          width: 100%;
          text-decoration: none;
          text-align: center;
          transition: color 0.3s ease 0s;
          font-weight: 600;

          > span {
            color: #9b12bd;
            font-weight: bold;
            white-space: nowrap;
            cursor: pointer;
          }
        }

        > h6 {
          margin-top: 2rem;
          font-weight: 700;
          color: rgba(154, 164, 191, 0.5);
          text-align: center;
          font-size: 10px;

          /* MOBILE */
          @media (max-width: 480px) {
            font-size: 8px;
            font-weight: 500;
          }

          /* MOBILE pp */
          @media (max-width: 350px) {
            display: none;
          }
        }

        > button {
          margin: 1rem 0 0 0;
          border: none;
          border-radius: 10px;
          padding: 1.15rem 0.4rem;
          font-weight: bold;
          align-items: center;
          box-shadow: rgb(97 93 250 / 20%) 4px 7px 12px 0px;
          font-size: 1rem;
          transition: all 0.3s ease-in-out 0s;
          text-transform: uppercase;
          background: #00cd96;
          color: rgb(255, 255, 255);
          width: 100%;
          text-align: center;
          height: 56px;
          max-height: 56px;
          padding: 4px 0.4rem;
        }

        > div {
          margin: 0.5rem 0 0 0;

          > input {
            width: 100%;
            ::placeholder {
              color: #3e3f5e;
              opacity: .5;
            }
          }
        }
      }
    }
  }

  > footer {
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: .5;
    z-index: 9999;
    transition: all .3s ease-in-out;

    /* MOBILE */
    @media (max-width: 480px) {
      display: none;
    }

    &:hover {
      opacity: 1;
    }

    > a {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-decoration: none;
      align-items: center;

      > p {
        color: #fff;
        margin: 0 0 .5rem 0;
        font-size: 10px;
      }

      > img {
        width: 35px;
        height: auto;
      }
    }
  }
`;
