import React from 'react';

import ContentGrid from "../../components/ContentGrid";
import HeaderInfo from "../../components/HeaderInfo";
import Footer from '../../components/Footer';

import { Container, GridContainer } from './styles';

import Accordion from './Accordion';
import AsideMenuItems from '../../components/AsideMenuItems';

const Faq: React.FC = () => {

  return (
    <Container>
      <GridContainer>
        <aside>
          <AsideMenuItems />
        </aside>
        <main>
          <section>
            <HeaderInfo supTitle="Faq" title="Perguntas Frequentes" margin="0 0 2rem 0" showSendButton={false} />
            <Accordion />
          </section>
        </main>
      </GridContainer>

      <ContentGrid>
        <Footer />
      </ContentGrid>
    </Container>
  );
}

export default Faq;
