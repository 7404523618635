import React from 'react';

import { FiCalendar, FiChevronRight, FiDollarSign, FiLayout, FiLock } from 'react-icons/fi';

import { Container, Question, QuestionTitle, Anwser } from './styles';

const Accordion: React.FC = () => {
  return (
  <Container>
    <details open={false}>
      <summary>
        <FiDollarSign size={22} strokeWidth={2.5} />
        Planos e Pagamentos
        <p>Entenda como funciona a sua assinatura e tire todas as suas dúvidas sobre o pagamento</p>
      </summary>
      <div>
        <ul>
          <li>
            <Question>
              <QuestionTitle>
                  <FiChevronRight />
                  O acesso à Plataforma é vitalício?
              </QuestionTitle>
              <Anwser>
                  Não, o acesso é garantido por 12 meses a contar da data de confirmação do pagamento.
              </Anwser>
            </Question>
          </li>
          <li>
            <Question>
              <QuestionTitle>
                  <FiChevronRight />
                  Minha assinatura é renovada automaticamente?
              </QuestionTitle>
              <Anwser>
                  Não. Caso deseje renovar o seu plano e continuar os estudos de onde parou, basta realizar a compra de um novo plano com o mesmo e-mail cadastrado anteriormente.
              </Anwser>
            </Question>
          </li>
        </ul>
      </div>
    </details>

    <details open={false}>
      <summary>
        <FiLayout size={22} strokeWidth={2.5} />
        Plataforma
        <p>Confira as principais dúvidas dos nossos assinantes que podem te ajudar</p>
      </summary>
      <div>
        <ul>
          <li>
            <Question>
              <QuestionTitle><FiChevronRight /> O que é a Educacione?</QuestionTitle>
              <Anwser>
                A Educacione é uma plataforma de educação que possibilita que qualquer pessoa organize suas sessões de estudo. Entendemos que uma boa rotina de estudos começa com um bom planejamento, por isso, juntos dos melhores estudantes e professores do Brasil, criei uma plataforma que oferece tudo que você precisa para fazer a melhor preparação da sua vida.
              </Anwser>
            </Question>
          </li>
          <li>
            <Question>
              <QuestionTitle><FiChevronRight /> Como usar a Plataforma?</QuestionTitle>
              <Anwser>
                Começou a estudar agora e não sabe por onde começar a usar a plataforma? Pode deixar que a gente te ajuda! 😉
                Ao entrar em sua conta, você irá se deparar com uma série de funcionalidades incríveis. É extremamente normal não entender tudo que primeira, tá?
                Pensando nisso, preparei um vídeo tutorial bem completinho, mostrando cada detalhe da Plataforma 👇
                <br /><br />
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <iframe style={{maxWidth: '560px', maxHeight: '315px', height: '315px'}} width="100%" src="https://www.youtube.com/embed/3zPl6n-L6zs" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>
                <br />
                <strong>Já está se sentindo mais confiante?</strong> Esperto que sim. 💜
              </Anwser>
            </Question>
          </li>
          <li>
            <Question>
              <QuestionTitle>
                  <FiChevronRight />
                  A Educacione oferece videoaulas ou livros de apoio?
              </QuestionTitle>
              <Anwser>
                  Não! A Educacione oferece todo o suporte para planejamento e organização da sua preparação, todavia, o contato com a
                  teoria – seja por livros, apostilas e/ou videoaulas - deve ser feito pelo aluno.
              </Anwser>
            </Question>
          </li>
        </ul>
      </div>
    </details>

    <details open={false}>
      <summary>
        <FiCalendar size={22} strokeWidth={2.5} />
        Cronograma
        <p>Não está sabendo lidar com o cronograma? Talvez tenhamos a resposta aqui</p>
      </summary>
      <div>
        <ul>
          <li>
            <Question>
              <QuestionTitle><FiChevronRight /> Preciso estudar todos os conteúdos do cronograma?</QuestionTitle>
              <Anwser>
                Não necessariamente! Embora o ideal seja estudar todos os assuntos avaliados pela prova, sabemos que a lista de conteúdos é extensa. Nesse sentido, o cronograma apresenta a taxa de relevância de cada assunto nele presente; através dessa porcentagem, o aluno pode analisar e identificar quais são os assuntos mais importantes para a prova, de acordo com os seus objetivos, e focar neles. Lembrando que não aconselho estudar apenas os assuntos mais relevantes... o ideal é estudar o máximo de conteúdos possível dando ênfase aos mais importantes para o seu curso.
              </Anwser>
            </Question>
          </li>
          <li>
            <Question>
              <QuestionTitle><FiChevronRight /> Dá para estudar para concursos utilizando o cronograma?</QuestionTitle>
              <Anwser>
              Sim! O cronograma é totalmente dinâmico e, devido a essa característica, é possível empregá-lo no estudo para concursos. No entanto, uma vez que o cronograma é direcionado para o ENEM, cada aluno deve buscar as diferenças entre a referida prova e as provas específicas que deseja realizar a fim fazer as adaptações necessárias. Além disso, todas as taxas de relevância presentes no presente cronograma foram estatisticamente avaliadas e definidas tendo como parâmetro a prova do ENEM.
              </Anwser>
            </Question>
          </li>
          <li>
            <Question>
              <QuestionTitle><FiChevronRight /> Com o cronograma é possível estudar para vestibulares específicos como fuvest e Universidades particulares?</QuestionTitle>
              <Anwser>
                Sim! O cronograma é totalmente dinâmico e, devido a essa característica, é possível empregá-lo no estudo para outros vestibulares além do ENEM. No entanto, uma vez que o cronograma é direcionado para o ENEM, cada aluno deve buscar as diferenças entre a referida prova e as provas específicas que deseja realizar a fim fazer as adaptações necessárias. Além disso, todas as taxas de relevância presentes no presente cronograma foram estatisticamente avaliadas e definidas tendo como parâmetro a prova do ENEM. Vale lembrar que é de responsabilidade de cada aluno fazer as adaptações necessárias, ok?
              </Anwser>
            </Question>
          </li>
          <li>
            <Question>
              <QuestionTitle><FiChevronRight /> O cronograma serve para quem quer um curso específico? Exemplo medicina, direito ou fisioterapia.</QuestionTitle>
              <Anwser>
                Sim, o cronograma serve para qualquer curso! Claro que, a depender do curso e da forma que a universidade irá avaliar sua nota (média aritmética simples ou média aritmética ponderada), o planejamento mudará, justamente por isso, o cronograma traz também um cronograma semanais, um quadro de metas e as taxas de relevância por assunto.
              </Anwser>
            </Question>
          </li>
        </ul>
      </div>
    </details>

    <details open={false}>
      <summary>
        <FiLock size={22} strokeWidth={2.5} />
        Outras dúvidas
        <p>Dúvidas sobre dados cadastrais, login, recuperar senhas e muito mais</p>
      </summary>
      <div>
        <ul>
          <li>
            <Question>
              <QuestionTitle><FiChevronRight /> Os cronogramas antigos ainda servem como base para os estudos?</QuestionTitle>
              <Anwser>
                Sim, os cronogramas antigos ainda são válidos, entretanto, nem de longe apresentam a mesma quantidade de benefícios que o cronograma de estudos digital apresenta. Além disso, os cronogramas anteriores não estão atualizados com as métricas advindas da aplicação do ENEM 2021. Dessa forma, embora você possa estudar com os cronogramas antigos, o cronograma de estudos digital da Educacione é o mais completo, dinâmico, eficiente, personalizável e atualizado.
              </Anwser>
            </Question>
          </li>
          <li>
            <Question>
              <QuestionTitle>
                  <FiChevronRight />
                  Como saber se a Universidade vai me avaliar por média aritmética simples ou ponderada?
              </QuestionTitle>
              <Anwser>
                Para você saber isso, basta entrar no google e colocar "termo de adesão" e, ao lado, a sigla da instituição que deseja cursar. Exemplo: Termo de adesão USP.
                Ao fazer isso, no primeiro link, você terá acesso a um PDF. Nesse PDF você consegue ver os pesos de cada curso da universidade que deseja cursar. Se o pesos foram todos iguais, a média é simples, caso os pesos sejam diferentes, a média será ponderada.
              </Anwser>
            </Question>
          </li>
          <li>
            <Question>
              <QuestionTitle>
                  <FiChevronRight />
                  Posso trocar/alterar os dados do meu cadastro?
              </QuestionTitle>
              <Anwser>
                Pode sim! Para isto, basta acessar a Plataforma e clicar no ícone de um lápis ao lado das informaçoes do seu perfil. Ali você conseguirá editar quase todas as informações.
                Se você deseja alterar algum dado que não seja possível, você precisará solicitar a alteração nos enviando um e-mail para: <a href="mailto:suporte@educacione.com.br">suporte@educacione.com.br</a>.
              </Anwser>
            </Question>
          </li>
        </ul>
      </div>
    </details>
    </Container>
  );
}

export default Accordion;
