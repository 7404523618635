import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/auth';

const Logout: React.FC = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return(
    <>
    </>
  );
}

export default Logout;
