import styled from 'styled-components';

interface INoticeCardProps {
  background?: string;
}

export const Container = styled.div`
  position: relative;
  width: 100%;

  .active {
    color: #ffffff;
    background: ${props => props.theme.colors.primary};
  }
`;

export const Showing = styled.div`
  color: #9aa4bf95;
  margin-top: 2rem;
  text-align: center;
`;

export const ContentGrid3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* TABLET */
  @media (max-width: 768px) {}
  /* MOBILE */
  @media (max-width: 480px) {}
`;

export const NoticesStudentContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: column;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const  NoticesHeader = styled.section`
  position: relative;

  > header {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    > ul {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      > li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        color: #9aa4bf95;
        font-size: 12px;
        cursor: pointer;
        transition: all .25s ease-in-out;

        &:hover {
          color: #ffffff;
        }

        > button {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 8px 10px;
          background: transparent;
          color: #ffffff;
          font-weight: 600;
          border-radius: 6px;
        }
      }
    }
  }
`;

export const NoticesContainer = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > aside {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    > header {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    > main {
      position: relative;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;

      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
`;

export const NoticeCard = styled.div<INoticeCardProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.colors.bgBoxMenu};
  box-shadow: ${props => props.theme.colors.shadowBox};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
  text-decoration: none;

  &:hover {

    > a {
      text-decoration: none;

      > main {
        transform: scale(1.10);
      }
    }
  }

  > header {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 9;
    padding: 1rem;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      background: #ffffff25;
      backdrop-filter: blur(4px);
      border-radius: 50%;

      > svg {
        color: #77ffaa;
      }
    }
  }

  > a {
    position: relative;
    width: 100%;
    height: 200px;
    max-height: 200px;
    text-decoration: none;

    > main {
      position: relative;
      width: 100%;
      height: 200px;
      max-height: 200px;
      background: url(${props => props.background}) no-repeat center center;
      background-size: cover;
      transition: all .35s cubic-bezier(0.645, 0.045, 0.355, 1);
      overflow: hidden;
    }
  }


  > footer {
    position: relative;
    width: 100%;
    z-index: 9;
    padding: 2rem;
    margin-top: -1rem;
    background: ${props => props.theme.colors.bgBoxMenu};

    > a {
      color: ${props => props.theme.colors.textColor};
      text-align: left;
      font-size: 16px;
      margin-bottom: 10px;
      text-decoration: none;
      font-weight: 600;
      transition: all .25s ease-in-out;
      margin-bottom: 1rem;
    }

    > p {
      color: #9aa4bf95;
      font-size: 12px;
      text-align: justify;
      margin-top: 1rem;
    }

    > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
      color: #9aa4bf95;
      text-align: left;
      font-size: 12px;
      margin-top: 1rem
    }
  }
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* TABLET */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* TABLET */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      > li {
        margin: .25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${props => props.theme.colors.textColor};
          transition: all .25s ease-in-out;
          padding: 1rem;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${props => props.theme.colors.primary};
          }

          > svg {
            margin-right: .5rem;
          }
        }
      }
    }
  }


  > main {
    display: flex;
    flex-direction: column;

    > header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        z-index: 3;

        > svg {
          margin-right: 0.75rem;
          position: absolute;
          color: ${props => props.theme.colors.muted}80;
          z-index: 1;
        }

        > select {
          padding: 0.5rem 2.5rem 0.5rem 0.7rem;
          background: transparent;
          border-radius: 8px;
          color: ${props => props.theme.colors.textColor};
          width: 150px;
          height: 42px;
          font-size: 12px;
          user-select: none;
          background: ${props => props.theme.colors.bgBoxMenu};
          box-shadow: ${props => props.theme.colors.shadowBox};
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
          z-index: 0;
          font-weight: bold;
          font-size: 14px;
          cursor: pointer;

          > option {
            color: ${props => props.theme.colors.textColor};
          }
        }
      }
    }

    > section:nth-child(1) {
      border-radius: 10px;
      padding: 1rem;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};
    }
  }
`;
