import React, { useCallback, useEffect, useState } from 'react';

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FiCheck, FiPlus, FiTrash, FiX } from 'react-icons/fi';

import ModalDisciplineDetails from './ModalDisciplineDetails';
import Button from '../Button';
import HeaderInfo from '../HeaderInfo';

import { Container, BoardContent, ColumnName, DragabbleButtonContainer, DraggableButton, DeleteButton, DraggableButtonBlockMove } from './styles';

import truncateStrings from '../../utils/truncateStrings';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import Loader from '../Loader';
interface IItemProps {
  id: string;
  content: string;
  color: string;
}

interface IColumnsProps {
  [key: string]: {
    name: string;
    items: IItemProps[];
  }
}

const WeekCronogram: React.FC = () => {
  const history =  useHistory();
  const [loadingWeekCronogram, setLoadingWeekCronogram] = useState(false);
  //const [columnsFromBackend, setColumnsFromBackend] = useState<IColumnsProps>({} as IColumnsProps);

  const [isReorganizer, setIsReorganizer] = useState(false);


  const [columns, setColumns] = useState({} as IColumnsProps);

  useEffect(() => {
    async function loadData() {
      try {
        setLoadingWeekCronogram(true);
        const response = await api.get('/calendars');
        console.log('========== LIST WEEK CRONOGRAM ==========');
        console.log(response.data);

        delete response.data.id
        delete response.data.updated_at
        delete response.data.created_at
        delete response.data.user_id

        setColumns(response.data);
      }
      catch (error) {
        console.log(error);
      }
      finally {
        setLoadingWeekCronogram(false);
      }
    }

    loadData();
  }, []);


  const onDragEnd = useCallback(async (result: DropResult) => {
    if (!result.destination) {
      console.log('aqui', result);
      console.log('droppableId', result.source.droppableId);
      console.log('draggableId', result.draggableId);

      /*
      // remove discipline case move to out Board droppables
      setColumns({
        ...columns,
        [result.source.droppableId]: {
          ...columns[result.source.droppableId],
          items: [...columns[result.source.droppableId].items].filter((item) => item.id !== result.draggableId)
        }
      });
      */

      return;
    };
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }

    console.log(columns);
  }, [columns]);

  // update calendar
  useEffect(() => {
    async function updateData() {
      try {
        const response = await api.put('/calendars', columns);
        console.log(response.data);
      }
      catch(error) {
        console.log(error);
      }
    }

    updateData();
  }, [columns])

  /* const handleOpenModalDetails = useCallback((item: IItemProps) => {
    console.log(item);
  },[]); */

  const handleRemoveDiscipline = useCallback((discipline: any) => {
    //console.log('columns', columns);

    //const currentColumn = columns.find((coluna) => coluna.id === discipline.id);
    //console.log('currentColumn', currentColumn)

    Object.keys(columns).forEach((dayOfWeek) => {
      //const dayOfWeek = columns[item].name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

      const currentDiscipline = columns[dayOfWeek].items.find((current) => current.id === discipline.id);
      console.log('currentDiscipline: ', currentDiscipline)

      if(currentDiscipline) {
        setColumns({
          ...columns,
          [dayOfWeek]: {
            ...columns[dayOfWeek],
            items: [...columns[dayOfWeek].items].filter((current) => current.id !== currentDiscipline.id)
          }
        });
      }
    });
  }, [columns]);

  const handleOpenModalAddDiscipline = useCallback(() => {
    setIsReorganizer(false);

    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        <ModalDisciplineDetails setColumns={setColumns} />
      ),
      background:'#090029',
      backdrop: '#1d23334e',
      showConfirmButton: false,
      showCloseButton: true,
      showCancelButton: false,
      allowOutsideClick: true,
      allowEscapeKey : true,
      padding: '32px 16px',
    });
  }, []);

  return (
    <Container style={loadingWeekCronogram ? {height: 0, overflow: 'hidden'} : {}}>
      <header>
        <div>
          <HeaderInfo supTitle="Organize da melhor forma" title="Cronograma semanal" showSendButton={false}  />
        </div>
        <div>
          <Button color={!isReorganizer ? 'error' : 'tertiary'} onClick={() => setIsReorganizer(oldValue => !oldValue)}>
            {!isReorganizer ? <FiTrash size={20} strokeWidth={2.5} /> : <FiCheck size={20} strokeWidth={4} />}
            {!isReorganizer ? <span>Remover disciplinas</span> : <span>Ok!</span>}
          </Button>
          <Button color="primary" onClick={handleOpenModalAddDiscipline}><FiPlus size={20} strokeWidth={2.5} /> <span>Adicionar disciplina</span></Button>
        </div>
      </header>
      <BoardContent style={{userSelect: 'none'}}>
        <DragDropContext onDragEnd={result => onDragEnd(result)}>
          {Object.entries(columns).map(([columnId, column], index) => {
            const weekday = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
            const d = new Date();
            const day = weekday[d.getDay()];

            return (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} key={columnId}>
                <ColumnName className={day === column.name ? 'is-date' : '' }>{column.name}</ColumnName>
                <div>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {

                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? '#adafca20'
                              : "transparent",
                            padding: '4px',
                            width: '100%',
                            minWidth: 140,
                            minHeight: 236,
                            border: '2px dashed #adafca80',
                            borderRadius: '10px',
                            margin: '.5rem 0 0 0',
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => {
                                  const normalizedHref = (item.content.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()).replaceAll(' ', '-');

                                  return (
                                    <DragabbleButtonContainer className={isReorganizer ? 'shake' : ''}>
                                      {isReorganizer && <DraggableButtonBlockMove />}
                                      <DraggableButton
                                        id={item.id}
                                        onClick={() => history.push(`cronograma/${normalizedHref}`)}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: "none",
                                          padding: 16,
                                          height: "50px",
                                          backgroundColor: snapshot.isDragging
                                            ? `${item.color}90`
                                            : `${item.color}`,
                                          color: "white",
                                          borderRadius: '5px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          ...provided.draggableProps.style
                                        }}>
                                        <span style={{ fontSize: 12, fontWeight: 600, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                          {truncateStrings(item.content, 18)}
                                        </span>
                                      </DraggableButton>
                                      {isReorganizer && <DeleteButton onClick={() => handleRemoveDiscipline(item)}><FiX strokeWidth={3} size={13} /></DeleteButton>}
                                    </DragabbleButtonContainer>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </BoardContent>
    </Container>
  );
}

export default WeekCronogram;
