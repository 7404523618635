import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;

  .progress-bar-circ-container {
    width: auto !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .progress-bar-percent {
    color: ${props => props.theme.colors.textColor};
  }

  .active {
    color: #ffffff;
    background: ${props => props.theme.colors.primary};
  }
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* TABLET */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* TABLET */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};

      > li {
        margin: .25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${props => props.theme.colors.textColor};
          transition: all .25s ease-in-out;
          padding: 1rem;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${props => props.theme.colors.primary};
          }

          > svg {
            margin-right: .5rem;
          }
        }
      }
    }
  }

  > main {
    position: relative;
    display: flex;
    flex-direction: column;

    > section:nth-child(1) {
      border-radius: 10px;
      padding: 1rem;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};

      .text-left {
        text-align: left !important;
        justify-content: start !important;
      }
      .text-center {
        text-align: center !important;
      }
      .isName {
        width: 25% !important;
      }

      .thead-extra {
        border-radius: 5px 5px 0 0;
        width: 100%;
      }

      .first-c {
        border-radius: 5px 0 0 0;
      }

      .last-c {
        border-radius: 0 5px 0 0;
      }

      > main {
        position: relative;
        border-radius: 5px;
        box-shadow: ${props => props.theme.colors.shadowMenu};
        border: 1px solid ${props => props.theme.colors.separator};

        > section {
          position: sticky;
          top: 1rem;
          border-radius: 5px 5px 0 0;
          width: 100%;
          display: grid;
          grid-template-columns: 25% 1fr 1fr 1fr 1fr 1fr 1fr;
          z-index: 9999;
          opacity: 1;
          box-shadow: 0px 12px 35px -13px ${props => props.theme.colors.primary}, 0px 25px 47px 2px rgba(0,0,0,0);

          &:before {
            content: '';
            position: absolute;
            width: 101%;
            height: 20px;
            top: -18px;
            background: ${props => props.theme.colors.bgBoxMenu};
            border: 2px solid ${props => props.theme.colors.bgBoxMenu};
            left: -6px;
            //background: red;
          }

          > tr, th, td {
            background: #6c23c0;
            color: #ffffff;
            font-weight: bold;
            padding: 12px 15px;
            border-collapse: collapse;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999999;
            white-space: nowrap; /* AQUI */
          }
        }

        > table {
          position: relative;
          width: 100%;
          border-collapse: collapse;
          font-size: 0.9em;
          overflow: hidden;
          border-bottom: 1px solid ${props => props.theme.colors.separator};


          td {
            padding: 12px 15px;
            color: ${props => props.theme.colors.textColor};

            > input {
              width: 70%;
              border-radius: 4px;
              text-align: center;
              padding: .4rem 0;
              transition: all .2s ease-in-out;
              border: 1px solid ${props => props.theme.colors.separator};

              &::-webkit-input-placeholder {
                text-align: center;
              }

              &:-moz-placeholder {
                text-align: center;
              }

              &:focus {
                border: 1px solid #615dfa;
              }

              &:read-only {
                background: ${props => props.theme.colors.separator};
                border: 1px solid ${props => props.theme.colors.separator};
                color: ${props => props.theme.colors.textColor};
                cursor: not-allowed;
                text-align: center;
                font-weight: 600;
              }
            }
          }

          > thead {
            border-collapse: collapse;
            border-radius: 5px 5px 0 0;

            > tr, th, td {
              background: #6c23c0;
              color: #ffffff;
              font-weight: bold;
              padding: 12px 15px;
              border-collapse: collapse;
            }
          }

          > tbody {
            border-collapse: collapse;

            > tr {
              border-collapse: collapse;
              margin: 5px;

              &:last-child {
                border: none;
              }
            }
          }
        }
      }

    }
  }
  `;

export const MobileTable = styled.div`

  > header {
    padding: 12px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #6c23c0;
    border-collapse: collapse;

    > aside {

      > h4 {
        color: #fff;
      }
    }
  }

  > main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    box-shadow: ${props => props.theme.colors.shadowMenu};
    display: grid !important;
    grid-template-columns: repeat(6, 1fr);
    padding-bottom: 1rem;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > input {
        width: 80%;
        border-radius: 4px;
        text-align: center;
        padding: .4rem 0;
        transition: all .2s ease-in-out;
        border: 1px solid ${props => props.theme.colors.separator};

        /* MOBILE */
        @media (max-width: 750px) {
          width: 90%;
        }

        &::-webkit-input-placeholder {
          text-align: center;
        }

        &:-moz-placeholder {
          text-align: center;
        }

        &:focus {
          border: 1px solid #615dfa;
        }

        &:read-only {
          background: ${props => props.theme.colors.separator};
          border: 1px solid ${props => props.theme.colors.separator};
          color: ${props => props.theme.colors.textColor};
          cursor: not-allowed;
          text-align: center;
          font-weight: 600;
        }
      }

      > h5 {
        margin: 0 0 8px 0;
        color: ${props => props.theme.colors.textColor};
      }

      > select {
        padding: 5px;
        border-radius: 4px;
        background: ${props => props.theme.colors.bgBoxMenu};
        border: 2px solid #b7b6c2;
        color: ${props => props.theme.colors.textColor};
      }
    }
  }

  select {
    background: ${props => props.theme.colors.bgBoxMenu} !important;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ContentGrid3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* TABLET */
  @media (max-width: 768px) {}
  /* MOBILE */
  @media (max-width: 480px) {}
`;


export const Showing = styled.div`
  color: #9aa4bf95;
  margin-top: 2rem;
  text-align: center;
`;
