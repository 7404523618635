import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -1rem 0 2rem 0;
    padding: 0 1rem 0 0;

    > h2 {
      text-align: left;
      color: #3e3f5e;
    }

    > h4 {

      > span {

        text-align: left;
        color: #3e3f5e;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #615dfa;

        &:hover {
          text-decoration: underline;
        }

        > svg {
          margin-right: .5rem;
        }
      }
    }
  }

  > form {
    position: relative;

    > button {
      width: 100%;
      background: #615dfa;
      border-radius: 6px;
      padding: 12px 0;
      z-index: 9999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      transition: all .2s ease-in-out;

      &:hover {
        background: #3531eb;
      }
    }

    > div {
      position: relative;
      text-align: left;
      margin: 1rem 0;

      > label {
        position: absolute;
        background: #fff;
        color: #3e3f5e;
        font-weight: 600;
        font-size: .8rem;
        transform: translate(11px, -59px);
        padding: 0 5px;
      }

      .react-select {

        input {
          padding: 7.5px 0 !important;
          font-weight: 600 !important;
          color: #3e3f5e !important;
          margin-left: 4px !important;

          &:after {
            background: blue !important;
          }
        }

        .css-1s2u09g-control {
          border-color: #3e3f5e;
          border-radius: 6px;
        }

        .css-14el2xx-placeholder {
          margin-left: 7px;
          font-weight: 600;
          color: #b1b3cd;
          font-size: 14px;
        }
      }

      input {
        padding: 20px 0;
      }

      > hr {
        background-color: transparent;
        background-image: linear-gradient(90deg, rgba(206,211,246,0) 0, #3e3f5e 38%, #3e3f5e 64%, rgba(206,211,246,0) 99%);
        opacity: .3;
        margin: 30px 0;
        height: 1px;
      }

      > div {

        > input, select {
          padding: .75rem 1rem;
          font-size: 14px;
          background: #fff;
          border: 1px solid #3e3f5e;
          color: #3e3f5e;
          border-radius: 6px;
          font-weight: 600;

          &:focus {
            border-color: #615dfa;
          }

          ::placeholder {
            color: #adafca;
            opacity: 1;
            text-transform: none !important;
          }
        }

        > label {
          background: #fff;
          color: #3e3f5e;
        }

        > span {
          background: transparent;
          color: #3e3f5e;
        }

      }
    }
  }
`;

export const ValidationError = styled.div`
  color: red;
  margin: 1rem 0 -1rem 0;
`;
