import { FiAward, FiBook, FiBookOpen, FiCalendar, FiLogOut, FiMessageSquare, FiMonitor, FiYoutube, FiEdit3, FiLayers } from "react-icons/fi";
import { IoNewspaperOutline } from "react-icons/io5";

const menuLinks = [
  {
    "title": 'Dashboard',
    "location": '/',
    "icon": FiMonitor,
  },
  {
    "title": 'Educacione',
    "location": '/educacione',
    "icon": FiBookOpen,
  },
  {
    "title": 'Redacione',
    "location": '/redacione',
    "icon": FiEdit3,
  },
  {
    "title": 'Metas',
    "location": '/metas',
    "icon": FiAward,
  },
  {
    "title": 'Cronograma',
    "location": '/cronograma',
    "icon": FiCalendar,
  },
  {
    "title": 'EduCards',
    "location": '/educards',
    "icon": FiLayers,
    "isComponent": true,
    "badge": 'new',
  },
  {
    "title": 'AtualizaEDU',
    "location": '/atualizaedu',
    "icon": IoNewspaperOutline,
  },
  {
    "title": 'SimuEDU',
    "location": '/simulado',
    "icon": FiBook,
  },
  {
    "title": 'Eduflix',
    "location": '/eduflix',
    "icon": FiYoutube,
  },
  {
    "title": 'FAQ',
    "location": '/duvidas-frequentes',
    "icon": FiMessageSquare,
  },
  {
    "title": 'Sair',
    "location": '/logout',
    "icon": FiLogOut,
  },
]

export default menuLinks;
