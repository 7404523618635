import React from 'react';
import { FiPlay } from 'react-icons/fi';

import ReactStars from 'react-stars';
import 'react-toastify/dist/ReactToastify.css';
import NextVideoTumbnails from '../NextVideoTumbnails';
import PlyrAdapter from '../PlyrAdapter';

import { Container, ContentGrid1, YoutubeVideoContainer } from './styles';

const PlayerLives: React.FC = () => {

  const [currentVideoIndex, setCurrentVideoIndex] = React.useState(0);

  const playlist = [
    {
      id: 0,
      title: 'SEJAM BEM-VINDOS A EDUCACIONE! 💜',
      video: 'YEP2S9qKJ8k',
      duration: '01:27',
    },
  ];

  return (
    <Container>
      <ContentGrid1>
        <aside>
          <main>
            <YoutubeVideoContainer>
              <PlyrAdapter
                source={{
                  type: "video",
                  sources: [
                    {
                      src: playlist[currentVideoIndex].video,
                      provider: 'youtube'
                    }
                  ]
                }}
              />
            </YoutubeVideoContainer>
          </main>
          <footer>
            <aside>
              <div>
                <FiPlay />
                <span>{playlist[currentVideoIndex].title}</span>
              </div>
            </aside>
            <aside>
              <main />
              <ReactStars
                key={1}
                count={5}
                size={20}
                color1="#adafca"
                color2="#b9993a"
                half={false}
                value={5} //puxar do banco aqui
                onChange={(new_rating: number) => {
                  //
                }}
              />
            </aside>
          </footer>
        </aside>
        <aside>
          {playlist.map((v) => (
            <NextVideoTumbnails
              key={v.id}
              onClick={() => setCurrentVideoIndex(v.id)}
              lessonDuration={v.duration}
              lessonOrder={v.id}
              lessonTitle={v.title}
              thumbnailUrl={`https://img.youtube.com/vi/${v.video}/mqdefault.jpg`}
              current={currentVideoIndex === v.id}
            />
          ))}
        </aside>
      </ContentGrid1>
    </Container>
  );
}

export default PlayerLives;
