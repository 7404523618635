import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  grid-area: CT;
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  margin: -2rem auto 0 auto;
  max-width: 1280px;
  width: 100%;

  .active {
    color: #ffffff;
    background: ${props => props.theme.colors.primary};
  }

  .slick-track {
    display: flex !important;
    gap: 1rem;
  }
  .slick-slider {
    position: relative !important;
  }

`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: flex: ;
  padding: 0 1rem;

  > main {
    display: flex;
    flex-direction: column;

    > section {
      display: flex;
      gap: 1rem;
      overflow: hidden;

      > div {
        width: 100%;
      }
    }

  }
`;

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 150px;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to left,transparent 0%, #090029 100%);
    z-index: 999999;

    /* MOBILE */
    @media (max-width: 480px) {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 150px;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(to right,transparent 0%, #090029 100%);
    z-index: 999999;

    /* MOBILE */
    @media (max-width: 480px) {
      display: none;
    }
  }
`;


export const SamplePrevArrow = styled.div`
  /* display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: green;
  position: absolute;
  height: 2rem;
  width: 2rem;
  top: 50%;
  left: 0;
  z-index: 999999999; */
  display: none;
`;

export const SampleNextArrow = styled.div`
  /* display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: blue;
  position: absolute;
  height: 2rem;
  width: 2rem;
  top: 50%;
  right: 0;
  z-index: 999999999; */
  display: none;
`;
