import React from 'react';

import Icon from '../Icon';

import { Container } from './styles';

interface IMovieCardOnlyCoverProps {
  cover: string;
  height?: string;
  onClick(): void;
}

const MovieCardOnlyCover: React.FC<IMovieCardOnlyCoverProps> = ({  cover, height, onClick}) => {

  return (
      <Container cover={cover} height={height} onClick={onClick}>
        <main>
          <div>
            <Icon icon="icon-icon-play" size="50px" margin="0 0 0 5px" />
          </div>
        </main>
      </Container>
  );
}

export default MovieCardOnlyCover;
