import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  > h2 {
    text-align: left;
    margin: -1rem 0 2rem 0;
    color: #3e3f5e;
    text-transform: uppercase;
  }

  > form {
    position: relative;

    > button {
      width: 100%;
      background: #615dfa;
      border-radius: 6px;
      padding: 12px 0;
      z-index: 9999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      transition: all 0.2s ease-in-out;
      margin: 1.5rem 0 0 0;

      &:hover {
        background: #3531eb;
      }
    }

    > div {
      position: relative;
      text-align: left;
      margin: 0;

      > p {
        color: #3e3f5e;
        margin: -0.5rem 0 0 0;

        > a {
          text-decoration: none;
          display: flex;
          align-items: center;
          transition: all 0.2s ease-in-out;

          &:hover {
            color: #232554;
          }

          > svg {
            margin-right: 4px;
          }
        }
      }

      > h4 {
        text-align: left;
        margin: 0 0 0.5rem 0;
        color: #3e3f5e;
        text-transform: uppercase;
      }

      > textarea {
        width: 100%;
        padding: 1rem;
        padding: 0.75rem 1rem;
        min-height: 100px;
        min-width: 100%;
        max-width: 100%;
        font-size: 14px;
        background: #fff;
        border: 1px solid #3e3f5e;
        color: #3e3f5e;
        border-radius: 6px;
        font-weight: 600;

        &:focus {
          border-color: #615dfa;
        }

        ::placeholder {
          color: #adafca;
          opacity: 1;
          text-transform: none !important;
        }
      }

      > footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
      }

      > label {
        position: absolute;
        background: #fff;
        color: #3e3f5e;
        font-weight: 600;
        font-size: 0.8rem;
        transform: translate(11px, -59px);
        padding: 0 5px;
      }

      .react-select {
        input {
          padding: 7.5px 0 !important;
          font-weight: 600 !important;
          color: #3e3f5e !important;
          margin-left: 4px !important;

          &:after {
            background: blue !important;
          }
        }

        .css-1s2u09g-control {
          border-color: #3e3f5e;
          border-radius: 6px;
        }

        .css-14el2xx-placeholder {
          margin-left: 7px;
          font-weight: 600;
          color: #b1b3cd;
          font-size: 14px;
        }
      }

      input {
        padding: 20px 0;
      }

      > div {
        > input,
        select {
          padding: 0.75rem 1rem;
          font-size: 14px;
          background: #fff;
          border: 1px solid #3e3f5e;
          color: #3e3f5e;
          border-radius: 6px;
          font-weight: 600;

          &:focus {
            border-color: #615dfa;
          }

          ::placeholder {
            color: #adafca;
            opacity: 1;
            text-transform: none !important;
          }
        }

        > label {
          background: #fff;
          color: #3e3f5e;
        }

        > span {
          background: transparent;
          color: #3e3f5e;
        }
      }
    }
  }
`;

export const ValidationError = styled.div`
  color: red;
  margin: 1rem 0 0 0;
  animation: shake 0.3s ease-in-out reverse infinite;
  animation-iteration-count: 5;


  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }

  > span {
    font-weight: bold;
  }
`;

interface LabelnputColorsProps {
  isSelected: boolean;
  color: string;
}

export const LabelnputColors = styled.label<LabelnputColorsProps>`
  display: inline-block;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-sizing: content-box;
  border: 2px solid transparent;

  ${({ isSelected, color }) => isSelected && `
    border: 2px solid ${color};

    &:after {
      content: '✓';
      color: ${color};
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      font-weight: bold;
      font-size: 1rem;
      box-sizing: content-box;
    }
  `}

  &:hover {
    //transform: scale(1.1);
  }

  > input {
    display: none;
  }
`;


export const HeaderSelectContainer = styled.div`
  position: relative;
  margin: 0 0 1rem 0 !important;

  > select {
    padding: 0.75rem 1rem;
    font-size: 14px;
    background: #fff;
    border: 1px solid #3e3f5e;
    color: #3e3f5e;
    border-radius: 6px;
    font-weight: 600;
    width: 100%;

    &:focus {
      border-color: #615dfa;
    }

    ::placeholder {
      color: #adafca;
      opacity: 1;
      text-transform: none !important;
    }
  }
`;
