import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";

import { AxiosError } from "axios";
import "react-toastify/dist/ReactToastify.css";

import api from "../services/api";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { ModalSignatureExpired } from "./styles";
interface IFailedRequest {
  onSuccess(token: string): void;
  onFailure(err: AxiosError): void;
}

export interface User {
  id: string;
  goals: {
    id: string;
    user_id: string;
    university_id: string;
    university: {
      id: string;
      name: string;
      acronym: string;
      created_at: string;
      updated_at: string;
    };
    career_id: string;
    career: {
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
    };
    score: number;
    is_priority: boolean;
    humanas: number;
    matematica: number;
    natureza: number;
    portugues: number;
    redacao: number;
    type: "weighted" | "simple";
    created_at: string;
    updated_at: string;
  }[];
  first_name: string;
  last_name: string;
  cpf: string;
  email: string;
  birthday: string;
  nickname?: string;
  phone_number: string;
  gender: string;
  avatar?: string;
  role: string;
  cep: string;
  street: string;
  number: string;
  complement: string;
  neighborhood?: string;
  city: string;
  state: string;
  language?: string;
  created_at: string;
  updated_at: string;
  full_name: string;
  role_name: string;
  show_name: string;
  avatar_url: string;
  activeTransactions?: {
    id: string;
    user_id: string;
    product_id: string;
    coupon_id?: null;
    start_date: string;
    end_date: string;
    warranty_date: string;
    canceled_at?: null;
    payment_engine: string;
    payment_method: string;
    payment_ext: string;
    price: number;
    status: string;
    installments: number;
    boleto_url?: null;
    boleto_expiration_date?: null;
    qr_code?: null;
    qr_code_url?: null;
    created_at: string;
    updated_at: string;
    amount: string;
    status_info: {
      name: string;
      background: string;
      color: string;
    };
    active: boolean;
  }[];
}

interface AuthState {
  token: string;
  user: User;
  refreshToken: string;
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface AuthContextState {
  user: User;
  isAuthenticated: boolean;
  updateUser(userData: User): void;
  login(credentials: LoginCredentials): Promise<void>;
  logout(): void;
}

interface Props {
  children: React.ReactNode;
}

let authChannel: BroadcastChannel | undefined;

// eslint-disable-next-line no-restricted-globals
if ("BroadcastChannel" in self) {
  authChannel = new BroadcastChannel("auth");
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const history = useHistory();

  const [user, setUser] = useState<User>({} as User);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("@Educacione:token")
  );

  const clearStorage = () => {
    localStorage.removeItem("@Educacione:token");
    localStorage.removeItem("@Educacione:refreshToken");

    setUser({} as User);
    setIsAuthenticated(false);
  };

  const logout = useCallback(() => {
    clearStorage();

    authChannel?.postMessage("logout");

    history.push("/");
  }, [history]);

  useEffect(() => {
    if (!authChannel) {
      return;
    }

    authChannel.onmessage = (event) => {
      switch (event.data) {
        case "logout":
          clearStorage();
          break;
        case "login":
          window.location.reload();
          break;
        default:
          break;
      }
    };
  }, []);

  useEffect(() => {
    async function loadData() {
      const token = localStorage.getItem("@Educacione:token");

      if (token) {
        await api
          .get("/profiles/me")
          .then((response) => {
            const userData = response.data;

            setUser({
              ...userData.user,
              courses: userData.courses,
              forums: userData.forums,
            });

            setIsAuthenticated(true);
          })
          .catch(() => {
            logout();
          });
      }
    }

    loadData();
  }, [logout]);

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await api.post<AuthState>("sessions", {
        email,
        password,
      });

      const { token, refreshToken } = response.data;

      localStorage.setItem("@Educacione:token", token);
      localStorage.setItem("@Educacione:refreshToken", refreshToken);

      api.defaults.headers.Authorization = `Bearer ${token}`;

      const profileResponse = await api.get("/profiles/me");

      setUser({
        ...profileResponse.data.user,
        courses: profileResponse.data.courses,
        forums: profileResponse.data.forums,
        activeTransactions: profileResponse.data.activeTransactions,
      });

      if (!profileResponse.data.activeTransactions?.length) {
        let MySwal = withReactContent(Swal);
        MySwal.fire({
          html: (
            <ModalSignatureExpired href="https://checkout.educacione.com.br/7b512eac-2866-43f1-8c59-99770a0856a8/">
              <img src={'https://educacione.s3.sa-east-1.amazonaws.com/default/estender-plano.jpg'} alt="" />
              <footer>
                <h1>Clique aqui para estender seu plano!</h1>
              </footer>
            </ModalSignatureExpired>
          ),
          background: "transparent",
          backdrop: "#1d23334e",
          showConfirmButton: false,
          showCloseButton: true,
          showCancelButton: false,
          allowOutsideClick: true,
          allowEscapeKey: true,
          padding: "32px 16px",
        });

        return;
      }

      setIsAuthenticated(true);

      authChannel?.postMessage("login");
    },
    []
  );

  const updateUser = useCallback((userData: User) => {
    setUser(userData);
  }, []);

  let refreshToken = localStorage.getItem("@Educacione:refreshToken");
  let isRefreshing = false;
  let failedRequestsQueue: IFailedRequest[] = [];

  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (error.response.data?.error === "token-expired") {
          refreshToken = localStorage.getItem("@Educacione:refreshToken");
          const originalConfig = error.config;

          if (!isRefreshing) {
            isRefreshing = true;

            api
              .post("/refresh-tokens", { token: refreshToken })
              .then((response) => {
                localStorage.setItem("@Educacione:token", response.data.token);
                localStorage.setItem(
                  "@Educacione:refreshToken",
                  response.data.refreshToken
                );

                api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

                failedRequestsQueue.forEach((request) =>
                  request.onSuccess(response.data.token)
                );
                failedRequestsQueue = [];
              })
              .catch((err) => {
                failedRequestsQueue.forEach((request) =>
                  request.onFailure(err)
                );
                failedRequestsQueue = [];
              })
              .finally(() => {
                isRefreshing = false;
              });
          }

          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({
              onSuccess: (newToken: string) => {
                originalConfig.headers.Authorization = `Bearer ${newToken}`;

                resolve(api(originalConfig));
              },
              onFailure: (err: AxiosError) => {
                reject(err);
              },
            });
          });
        }

        logout();
      }

      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider
      value={{ user, updateUser, login, logout, isAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextState {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be user within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
