import styled from 'styled-components';

export const Container = styled.div`

  @keyframes check {
    50% {
      transform: scale(1.2);
    }
  }

  `;

export const Input = styled.input`
  &:checked + label span:first-child {
    border-color: #6c23c0;
    background: #6c23c0;
    animation: check 0.6s ease-in-out;
  }

  &:checked + label span:first-child svg {
    stroke-dashoffset: 0;
  }

  &:checked + label span:first-child:before {
    transform: scale(2.2);
    opacity: 0;
    transition: all 0.6s ease;
  }

  &:checked + label span:last-child {
    color: #adafca30;
    transition: all 0.3s ease;
  }

  &:checked + label span:last-child:after {
    transform: scaleX(1);
    transition: all 0.3s ease;
  }
`;

export const Label = styled.label`
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    > span {
      display: inline-block;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);
    }

    > span:first-child {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      transform: scale(1);
      vertical-align: middle;
      border: 2px solid #B9B8C3;
      transition: all 0.2s ease;
    }
    > span:first-child svg {
      position: absolute;
      z-index: 1;
      top: 6px;
      left: 4px;
      fill: none;
      stroke: white;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 16px;
      stroke-dashoffset: 16px;
      transition: all 0.3s ease;
      transition-delay: 0.1s;
      transform: translate3d(0, 0, 0);
    }

    > span:first-child:before {
      content: "";
      width: 100%;
      height: 100%;
      background: #506EEC;
      display: block;
      transform: scale(0);
      opacity: 1;
      border-radius: 50%;
      transition-delay: 0.2s;
    }

    > span:last-child {
      //
    }

    &:hover > span:first-child {
      border-color: #6c23c0;
    }
  `;
