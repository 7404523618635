import React, { useEffect, useState } from 'react';

import { Container } from './styles';

interface IProgressBarProps {
  percentage: number;
  width: string;
  height: string;
  bgColor: string;
  barColor: string;
  trackBorderColor: string;
  rectBorderRadius?: string;
  style?: React.CSSProperties;
}

const ProgressBar: React.FC<IProgressBarProps> = ({ percentage, width, height, bgColor, barColor, trackBorderColor, rectBorderRadius, style }) => {
  const [progress, setProgress] = useState(0);
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    setProgress((oldPercentage) => {
      setAnimate(true);
      if (percentage < oldPercentage) {
        return 100;
      }
      return percentage;
    });
  }, [percentage]);

  return (
    <Container
      style={style}
      percentage={progress}
      width={width}
      height={height}
      bgColor={bgColor}
      barColor={barColor}
      trackBorderColor={trackBorderColor}
      rectBorderRadius={rectBorderRadius}
      animate={animate}
    >
      <main />
    </Container>
  );
}

export default ProgressBar;
