import React from 'react';

import { Switch, Route } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

import Layout from '../components/Layout';

import Dashboard from '../Pages/Dashboard';
import Movies from '../Pages/Movies';
import Movie from '../Pages/Movie';
import Cronograma from '../Pages/Cronograma';
import CronogramaDisciplina from '../Pages/CronogramaDisciplina';
import CronogramaRedacao from '../Pages/CronogramaRedacao';
import Simulados from '../Pages/Simulados';
import Faq from '../Pages/Faq';
import Login from '../Pages/Login';
import Logout from '../Pages/Logout';
import Metas from '../Pages/Metas';
import Educacione from '../Pages/Educacione';
import AtualizaEDU from '../Pages/AtualizaEDU';
import Redacione from '../Pages/Redacione';

const AppRoutes: React.FC = () => (
  <>
    <ScrollToTop>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/eduflix/:id" exact component={Movie} />

        <Layout>
          <Route path="/" exact component={Dashboard} />
          <Route path="/educacione" exact component={Educacione} />
          <Route path="/redacione" exact component={Redacione} />
          <Route path="/cronograma" exact component={Cronograma} />
          <Switch>
            <Route path="/cronograma/temas-para-redacao" exact component={CronogramaRedacao} />
            <Route path="/cronograma/:slug" exact component={CronogramaDisciplina} />
          </Switch>
          <Route path="/simulado" exact component={Simulados} />
          <Route path="/eduflix" exact component={Movies} />
          <Route path="/atualizaedu" exact component={AtualizaEDU} />
          <Route path="/duvidas-frequentes" exact component={Faq} />
          <Route path="/metas" exact component={Metas} />
          <Route path="/logout" exact component={Logout} />
        </Layout>
      </Switch>
    </ScrollToTop>
  </>
);

export default AppRoutes;
