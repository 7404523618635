import styled from "styled-components";

export const CardWrapper = styled.div`
  position: absolute;
`;

export const Card = styled.div`
  position: absolute;
  perspective: 1000px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
`;

export const CardInner = styled.div<{ isFlipped: boolean }>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  transform: ${({ isFlipped }) =>
    isFlipped ? "rotateY(180deg)" : "rotateY(0)"};
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const CardFront = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: #6c23c0;
  user-select: none;
  border-radius: 0.5rem;

  > main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 2rem;
    word-wrap: break-word; /* ou overflow-wrap: break-word; para garantir compatibilidade */
    word-break: break-all;

    > p {
      color: #fff;
      font-size: 1rem;
      text-align: center;
    }
  }
`;

export const CardBack = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background: #6c23c050;
  user-select: none;
  border-radius: 0.5rem;

  > main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 2rem;
    word-wrap: break-word; /* ou overflow-wrap: break-word; para garantir compatibilidade */
    word-break: break-all;

    > p {
      color: #fff;
      font-size: 1rem;
      text-align: center;
    }
  }
`;

export const BtnRemoveContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
  text-align: right;
  padding: 0.5rem;
  gap: 0.25rem;
  cursor: pointer;
  z-index: 9;

  >svg {
    padding: 0.25rem;
    box-sizing: content-box;
  }

  > svg:nth-child(1) {

    &:hover {
      color: #77ffaa;

      animation: shakeHorizontal 0.5s;

      @keyframes shakeHorizontal {
        0% {
          transform: translateX(0);
        }
        25% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        75% {
          transform: translateX(-5px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
  }

  > svg:nth-child(2) {
    &:hover {
      color: #ff0000;

      animation: shakeHorizontal 0.5s;

      @keyframes shakeHorizontal {
        0% {
          transform: translateX(0);
        }
        25% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        75% {
          transform: translateX(-5px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
  }
`;
