import React from 'react';
import { useTheme } from '../../hooks/theme';
import ProgressBar from '../ProgressBar';

import { Container } from './styles';

interface IDisciplineCardProps {
  progress: number;
  barColor: string;
  icon: string;
  disciplineName: string;
  backdropColor: string;
  to: string;
}

const DisciplineCard: React.FC<IDisciplineCardProps> = ({ to, progress, barColor, icon, disciplineName, backdropColor }) => {
    const { theme } = useTheme();
    return (
    <Container to={to}>
      <header>
        <img src={icon} alt="" />
        <div>
          <h1>{disciplineName}</h1>
          <span>{progress}%</span>
        </div>
      </header>
      <footer>
        <ProgressBar
          width="100%"
          height="6px"
          percentage={Number(progress)}
          bgColor={theme.title === 'dark' ? '#ffffff24' : '#e8e8e89e'}
          barColor={theme.title === 'dark' ? '#6befb0' : '#6befb0'}
          rectBorderRadius='12px'
          trackBorderColor="transparent"
          style={{ marginTop: '8px' }}
        />
      </footer>
    </Container>
  );
}

export default DisciplineCard;
