import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background: red;
`;

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  > main {
    height: 500px; // Defina a altura conforme necessário
    position: relative;
    display: flex;
    transition: transform 0.25s ease-in-out;

    > div {
      transition: all 0.5s ease-in-out;
      width: 100%;
      height: 500px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transform: translateX(-100%);
      padding: 0 1rem;

      &.previous {
        transform: translateX(-100%);
        opacity: 1;
      }

      &.visible {
        transform: translateX(0);
        opacity: 1;
      }

      &.next {
        transform: translateX(100%);
        opacity: 1;
      }
    }
  }

  > footer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
    position: absolute;
    z-index: 9999999;
    top: calc(50% - 16px);

    > button {
      top: 50%;
      transform: translateY(-50%);
      width: fit-content;
      height: 48px;
      width: 48px;
      background: #00000020;
      border: none;
      color: #ffffff;
      font-size: 1.5rem;
      cursor: pointer;
      transition: background 0.3s;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &.previous-btn {
        left: 1.5rem;
        position: absolute;
      }

      &.next-btn {
        right: 1.5rem;
        position: absolute;
      }

      &:hover {
        background: #00000040;
      }
    }
  }
`;

export const Slide = styled.div`
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  width: 100%;
  position: relative;
  left: 0;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
`;

export const NotFound = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 200px;
  text-align: center;

  > h1 {

    > span {
      color: #4141ff;
      cursor: pointer;
    }
  }

  > p {
    font-size: 2rem;
    width: 100%;
  }
`;


export const LoaderContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  height: fit-content !important;
  margin-top: -20rem;

  > div {
    background: transparent;
  }

  > p {
    font-size: 1.5rem;
    width: 100%;
    background: transparent;
    margin-top: 10rem;
  }

  > div > div > span {

    &:before {
      border-top-color: #ffff !important;
    }
  }
`;
