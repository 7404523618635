import React from 'react';

import { Container } from './styles';

interface IHetaryGroupProps {
  age_group:
  | '18'
  | '16'
  | '14'
  | '12'
  | '10'
  | 'L'
}

const HetaryGroup: React.FC<IHetaryGroupProps> = ({ age_group }) => {
  return (
    <Container age_group={age_group}>
      {age_group}
    </Container>
  );
}

export default HetaryGroup;
