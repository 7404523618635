import React from 'react';

import ReactStars from 'react-stars';

import HetaryGroup from './HetaryGroup';
import Icon from '../Icon';

import truncateStrings from '../../utils/truncateStrings';

import { Container, RatingContent } from './styles';
import { Link } from 'react-router-dom';

interface IMovieCardProps {
  id: string;
  name: string;
  duration: number;
  age_group: '18' | '16' | '14' | '12' | '10' | 'L';
  rating: number;
  year: string | number;
  cover: string;
  description?: string;
  height?: string;
}

const MovieCard: React.FC<IMovieCardProps> = ({ id, name, duration, age_group, rating, year, cover, description, height, }) => {

  return (
      <Container to={`/eduflix/${id}`} cover={cover} height={height}>
        <main>
          <div>
            <Icon icon="icon-icon-play" size="24px" margin="0 0 0 5px" />
          </div>
        </main>
        <footer>
          <Link to={`/eduflix/${id}`}>{truncateStrings(name, 26)}</Link>
        </footer>
        <RatingContent>
          <ReactStars
            key={1}
            count={5}
            edit={false}
            size={20}
            color1="#adafca"
            color2="#d3df37"
            half={true}
            value={rating/20} //puxar do banco aqui
            onChange={(new_rating: number) => {}}
          />
          {rating < 0.5 && <span>{String(rating/20).length < 2 ? rating/20 + '.0' : rating/20}</span>}
          <HetaryGroup age_group={age_group} />
        </RatingContent>
      </Container>
  );
}

export default MovieCard;
