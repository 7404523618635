import { createGlobalStyle } from "styled-components";

const isMobile = window.innerWidth < 600;

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    text-transform: none;
    box-sizing: border-box;
    outline: none;
    font-family: 'Titillium Web', sans-serif;

    ::selection {
      background-color: #77ffaa;
      color: #fff;
    }
  }

  html {
    background: #090029;
  }

  html, #root {
    height: 100%;

    /* TABLET */
    @media (max-width: 1300px) {
      overflow-x: hidden;
    }
  }

  .__react_component_tooltip {
    ${
      isMobile &&
      `
      background-color: transparent !important;
      color: transparent !important;

      &:before {
        background-color: transparent !important;
      }

      &:after {
        background-color: transparent !important;
      }
    `
    }
  }

  *, input, button {
    border: 0;
    outline: none;
  }

  button {
    cursor: pointer;
  }

  .css-z532ln-multiValue {
    /* TABLET */
    @media (max-width: 450px) {
      width: auto !important;
      max-width: 90% !important;
    }
  }

  body.swal2-shown > [aria-hidden="true"] {
    filter: blur(7px) !important;
    z-index: 0;
  }

  .swal2-actions {
    z-index: 0;
  }

  /* .swal2-show::before {
    left: 0;
    content: '';
    position: absolute;
    width: 100%;
    height: 220px;
    top: -218px;
    margin: 0 auto
  } */

  .swal2-title {
    color: #fff;
    margin-bottom: 0 !important;
  }

  .swal2-content {
    color: #fff;
    font-size: .8rem;
    text-transform: uppercase !important;
  }

  .swal2-styled.swal2-confirm, .swal2-styled.swal2-cancel {
    width: 170px;
    border: none;
    border-radius: 7px;
    padding: .9rem 0;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    align-items: center;
    box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
    font-size: .75rem;
  }

  .swal2-icon {
    margin: 0 auto 1.875em;
  }

  .swal2-show {
    background: #6e61c6;
    border-radius: 12px;
  }

  .swal2-close {
    color: #fff !important;
    top: 9px;
    right: 7px;
    font-size: 27px;
    background: rgb(106 85 157 / 22%);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    transition: all .4s;

    &:hover {
      background: rgb(167 146 218 / 22%);
    }

    &:focus {
      outline: 0;
      box-shadow: none !important;
    }
  }

  .swal2-styled.swal2-confirm {
    background: #03bb85;
  }

  .Toastify__toast-container, .Toastify__toast, .Toastify__toast--default {
    z-index: 999999999999999999999 !important;
  }

  .Toastify__toast--success {
    background: #03bb85 !important;
  }

  /* START TRELLO STYLES */
  .react-trello-lane {
    border: 0;
  }

  .smooth-dnd-container.horizontal {
    white-space: unset;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .ql-toolbar.ql-snow {
    border-radius: 12px 12px 0 0 !important;
  }
  .ql-container.ql-snow {
    border-radius: 0 0 12px 12px !important;
    min-height: 150px;
    max-height: 300px;
    overflow-y: auto;
    resize: none;
  }

  .ql-toolbar.ql-snow .ql-formats {
    margin: 0;
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ql-snow .ql-picker-label {
    text-align: center;
    display: inline-flex;
    border: 1px solid #ccc !important;
    border-radius: 6px;
  }

  .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
    text-align: center;
    display: inline-flex;
    border: 1px solid #ccc !important;
    border-radius: 6px;

    &:hover {
      background: #e9e9e9;
    }

    &.ql-active {
      background: #e9e9e9;
    }
  }

  #ql-picker-options-20 {

    > span {
      &:nth-child(1) {
        &:before {
          content: 'Pequeno' !important;
        }
      }

      &:nth-child(2) {
        &:before {
          content: 'Médio' !important;
        }
      }

      &:nth-child(3) {
        &:before {
          content: 'Grande' !important;
        }
      }

      &:nth-child(4) {
        display: none !important;
      }
    }
  }

  #ql-picker-options-21 {

    > span {

      &:nth-child(1) {
        &:before {
          content: 'Fonte 1' !important;
        }
      }

      &:nth-child(2) {
        &:before {
          content: 'Fonte 2' !important;
        }
      }

      &:nth-child(3) {
        &:before {
          content: 'Fonte 3' !important;
        }
      }

    }
  }


  /* END TRELLO STYLES */

  /* START STYLES DND */

/*  .item.muuri-item-positioning {
    z-index: 2;
  }
  .item.muuri-item-dragging,
  .item.muuri-item-releasing {
    cursor: move;
    z-index: 9999;
  }
  .item.muuri-item-dragging {
    cursor: move;
  }
  .item.muuri-item-hidden {
    z-index: 0;
  }
  .item.muuri-item-placeholder {
    z-index: 2;
    opacity: 0.5;
    border: 2px dashed #fff !important;
    border-radius: 0.5rem;
  }

  .item.muuri-item-placeholder .card {
    border-style: dashed !important;
  }

  .filtered {
    cursor: not-allowed;
  } */
  /* END STYLES DND */
`;
