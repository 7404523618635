import React, { useCallback, useEffect, useState } from 'react';

import { useAuth, User } from '../../../hooks/auth';

import Select, { components } from 'react-select';
import Swal from 'sweetalert2';

import Input from '../../Input';

import { Container, ValidationError } from './styles';
import { FiLock, FiUser } from 'react-icons/fi';
import api from '../../../services/api';
import LoaderPureCss from '../../LoaderPureCss';
import { toast } from 'react-toastify';
import axios from 'axios';

interface IModalEditProfileProps {
  user: User;
  updateUser(user: User): void;
}

const ModalEditProfile: React.FC<IModalEditProfileProps> = ({ user, updateUser }) => {
  const [nickname, setNickname] = useState<string | null>(null);
  const [foreignLanguage, setForeignLanguage] = useState<any>([]);
  const [oldPassword, setOldPassword] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState<string | null>(null);

  const [validation, setValidation] = useState('');
  const [loading, setLoading] = useState(false);

  const [passwordChangeState, setPasswordChangeState] = useState(false);

  const opts = [
    { value: 'Inglês', label: 'Inglês' },
    { value: 'Espanhol', label: 'Espanhol' },
  ];

  const handleSubmitForm = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(!passwordChangeState) {
      console.log('FIRST STAGE: ', {nickname, foreignLanguage});

      if(foreignLanguage === '') {
        setValidation('Você precisa selecionar o campo Língua estrangeira!');

        return;
      }
    }

    if(passwordChangeState) {
      console.log('SECOND STAGE: ', {oldPassword, password, passwordConfirmation});

      if(!oldPassword || !password || !passwordConfirmation) {
        setValidation('Por favor, preencha todos os campos!');
        return;
      }

      if(password !== passwordConfirmation) {
        setValidation('A nova senha e a confirmação de senha não conferem.');
      }

      if(password.length <= 6) {
        setValidation('A nova senha deve conter mais de 6 caracteres.');
        return;
      }
    }

    try {
      setLoading(true);
      const response = await api.put(`/profiles`, {
        nickname: nickname === '' ? null : nickname,
        language: foreignLanguage,
        password: password,
        password_confirmation: passwordConfirmation,
        old_password: oldPassword
      });

      console.log(response.data);

      updateUser({
        ...user,
        nickname: response.data.nickname,
        show_name: response.data.show_name,
        language: response.data.language,
      });

      Swal.close();

      toast.success(`👌 Perfil alterado com sucesso!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } catch (err) {
      console.log('errrrrrrrrrrrrr11111::: ', err);

      if(axios.isAxiosError(err)) {
        const { error } = err.response?.data || { error: 'default' };

        console.log('errrrrrrrrrrrrr222::: ', error);

        switch(error) {
          case 'wrong-password':
            setValidation('Senha atual inválida!');
            break;

          case 'Bad Request':
            setValidation('As senhas informadas não conferem!');
            break;

          default:
            setValidation('Poxa, algo deu errado! Tente novamente mais tarde.');
        }

        return;
      }
    }
    finally {
      setLoading(false);
    }
  }, [nickname, foreignLanguage, oldPassword, password, passwordConfirmation, passwordChangeState, user, updateUser]);

  useEffect(() => {
    console.log(user);

    if(user.nickname) {
      setNickname(user.nickname);
    } else {
      setNickname(null);
    }
  }, [user]);

  const customStyles = {
    menu: (base: any) => ({
      ...base,
      marginTop: 6,
      background: '#fff',
      color: '#3e3f5e',
      fontWeight: 600,
    }),
    menuList: (base: any) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    }),
    multiValue: (base: any) => ({
      ...base,
      background: '#615dfa',
      color: '#fff',
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      color: '#fff',
      fontWeight: 500,
    }),
  };

  return (
    <Container>
      {loading && <LoaderPureCss isFixed={false} />}
      <header>
        <h2>{!passwordChangeState ? 'Editar perfil' : 'Alterar senha'}</h2>
        <h4 onClick={()=> setPasswordChangeState(!passwordChangeState)}> {!passwordChangeState ? <span><FiLock /> Alterar senha?</span> :  <span><FiUser /> Alterar perfil?</span>}</h4>
      </header>

      {user && !passwordChangeState ? (
        <form onSubmit={(e) => {handleSubmitForm(e)}} autoComplete="off">
          <div className='fakeeeeeeeeeee7777777'>
            <input type="text" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
            <input type="password" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
          </div>
          <div className='fakeeeeeeeeeee7777777'>
            <input type="text" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
            <input type="password" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
          </div>
          <div className='fakeeeeeeeeeee7777777'>
            <input type="text" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
            <input type="password" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
          </div>
          <div className='fakeeeeeeeeeee7777777'>
            <input type="text" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
            <input type="password" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
          </div>
          <div className='fakeeeeeeeeeee7777777'>
            <input type="text" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
            <input type="password" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
          </div>
          <div className='fakeeeeeeeeeee7777777'>
            <input type="text" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
            <input type="password" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
          </div>
          <div className='fakeeeeeeeeeee7777777'>
            <input type="text" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
            <input type="password" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
          </div>
          <div>
            <Input
              type="text"
              placeholder="Nome completo"
              label="Nome completo"
              value={user.full_name}
              readOnly
            />
          </div>
          <div>
            <Input
              type="text"
              placeholder="E-mail"
              label="E-mail"
              value={user.email}
              readOnly
            />
          </div>
          <div>
            <Input
              type="text"
              placeholder="Como você gostaria de ser chamado?"
              label="Apelido (opcional)"
              defaultValue={user?.nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
          </div>
          <div>
            <Select
              placeholder="Selecione..."
              className="react-select"
              noOptionsMessage={() => foreignLanguage.length >= 1 ? 'Limite máximo atingido' : 'Nenhuma opção encontrada'}
              //@ts-ignore
              options={foreignLanguage.length > 0 ? [] : opts}
              loadingMessage={() => 'Carregando...'}
              isClearable
              isMulti
              defaultValue={() => {
                if(user?.language) {
                  setForeignLanguage(user.language);

                  return {
                    value: user.language, label: user.language
                  }
                }
              }
            }
              onChange={(selected, triggeredAction) => {
                  if (triggeredAction.action === 'clear' || triggeredAction.action === 'remove-value') {
                    setForeignLanguage('');
                  }
                  else {
                    //setForeignLanguage((oldForeignLanguage: any) => [...oldForeignLanguage, selected])}
                    //@ts-ignore
                    setForeignLanguage(selected[0].value);
                  }
                }
              }
              styles={customStyles}
            />
            <label>Língua estrangeira</label>
          </div>
          <button type="submit">Atualizar perfil</button>

          <footer>
            {validation !== '' && <ValidationError><strong>{validation}</strong></ValidationError>}
          </footer>
        </form>
      )
    :
      (
        <form onSubmit={(e) => {handleSubmitForm(e)}} autoComplete="off">
          <div className='fakeeeeeeeeeee7777777'>
            <input type="text" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
            <input type="password" style={{opacity: '0', height: 0, width: 0, position: 'absolute'}} />
          </div>
          <div>
            <Input
              type="password"
              placeholder="Senha atual"
              label="Senha atual"
              defaultValue=""
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div>
            <Input
              type="password"
              placeholder="Nova senha"
              label="Nova senha"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <Input
              type="password"
              placeholder="Repita a nova senha"
              label="Repita a nova senha"
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>
          <button type="submit">Alterar senha</button>

          <footer>
            {validation !== '' && <ValidationError>⛔ <strong>{validation}</strong></ValidationError>}
          </footer>
        </form>
      )
    }
    </Container>
  );
}

export default ModalEditProfile;
