import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { UAParser } from "ua-parser-js";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { trim } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";

import { useAuth } from "../../hooks/auth";
import { ModalChromeAndW7Detect } from "../../hooks/styles";

import Input from "../../components/Input";
import Button from "../../components/Button";
import ForgetPasswordModal from "./ForgetPasswordModal";

import livro from "../../assets/icons/livro.png";
import globo from "../../assets/icons/globo.png";
import redacao from "../../assets/icons/redacao.png";
import dna from "../../assets/icons/dna.png";
import cacto from "../../assets/icons/cacto.png";
import logo from "../../assets/logo2-dark.png";
import px from "../../assets/px-tecnologia.svg";

import { Container } from "./styles";

const Login: React.FC = () => {
  const { login } = useAuth();

  const inputEmail = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const recaptchaRef = useRef<any>(null);

  useEffect(() => {
    const bd = document.querySelector("body");
    if (bd) {
      bd.style.overflowX = "hidden";
    }
  }, []);

  useEffect(() => {
    inputEmail.current?.focus();
  }, []);

  const handleDOMChange = useCallback(() => {
    const iframe = document.querySelector(
      'iframe[src*="recaptcha/api2/bframe"]'
    );

    const container = iframe?.parentNode?.parentNode?.firstChild as
      | HTMLElement
      | undefined;

    if (!container) {
      return;
    }

    container.onclick = () => {
      setLoading(false);

      toast.error("Erro no CAPTCHA! Cumpra a etapa de verificação.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
  }, []);

  useEffect(() => {
    const domObserver = new MutationObserver(handleDOMChange);

    domObserver.observe(document.body, {
      childList: true,
    });
  }, [handleDOMChange]);

  const handleLogin = useCallback(
    async (e: any) => {
      e.preventDefault();
      recaptchaRef.current?.execute();

      const emailTrined = trim(email.replace(/\s/g, "")).trim();

      try {
        setLoading(true);

        const token = await recaptchaRef.current?.executeAsync();
        if (showRecaptcha && !token) {
          toast.error("Erro no CAPTCHA! Cumpra a etapa de verificação.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setLoading(false);
          return;
        }

        await login({ email: emailTrined, password });
        window.scrollTo(0, 0);
      } catch (err: any) {
        recaptchaRef.current?.reset();
        if (axios.isAxiosError(err)) {
          const { browser, os } = UAParser();

          if (
            browser.name === "Chrome" &&
            os.name === "Windows" &&
            os.version === "7"
          ) {
            let MySwal = withReactContent(Swal);
            MySwal.fire({
              html: (
                <ModalChromeAndW7Detect>
                  <img src={logo} alt="" />
                  <h1>Seu navegador pode ser incompatível</h1>
                  <p>
                    Detectamos que você utiliza uma{" "}
                    <strong>versão antiga do Windows 7 (2009)</strong> e seu
                    navegador
                    <strong>
                      {" "}
                      ({browser.name} - {browser.version})
                    </strong>{" "}
                    não atende aos protocolos de segurança necessários para
                    fazer login na Plataforma. <br />
                    <br />
                    Nesses casos, indicamos que faça Download do navegador
                    Mozila Firefox.
                  </p>
                  <a
                    className="download"
                    href="https://www.mozilla.org/pt-BR/firefox/new/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download do Mozila Firefox
                  </a>
                  <a
                    href="https://educacione.com.br/contato/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Se preferir, entre em contato com o Suporte
                  </a>
                </ModalChromeAndW7Detect>
              ),
              background: "#fff",
              backdrop: "#1d23334e",
              showConfirmButton: false,
              showCloseButton: true,
              showCancelButton: false,
              allowOutsideClick: true,
              allowEscapeKey: true,
              padding: "32px 16px",
            });

            return;
          }
        }

        toast.error("🤷‍♂️ E-mail ou senha não conferem!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setShowRecaptcha(true);

      } finally {
        setLoading(false);
      }
    },
    [showRecaptcha, email, password, login]
  );

  const handleForgetPassword = useCallback(() => {
    let forgetPasswordModal = withReactContent(Swal);

    forgetPasswordModal.fire({
      html: <ForgetPasswordModal />,
      background: "#fff",
      backdrop: "rgba(110, 97, 198, .1)",
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, []);

  return (
    <Container style={{ overflow: "hidden" }}>
      <div className="paralaxx-abs t0 r0">
        <div className="element-pic">
          <img src={globo} alt="" />
        </div>
      </div>

      <div className="paralaxx-abs t0 l0">
        <div className="element-pic2">
          <img src={livro} alt="" />
        </div>
      </div>

      <div className="paralaxx-abs t50 l0">
        <div className="element-pic3">
          <img src={redacao} alt="" />
        </div>
      </div>

      <div className="paralaxx-abs b0 r0">
        <div className="element-pic4">
          <img src={dna} alt="" />
        </div>
      </div>

      <div className="paralaxx-abs b0 l0">
        <div className="element-pic5">
          <img src={cacto} alt="" />
        </div>
      </div>

      <main>
        <img src={logo} alt="" />
        <form onSubmit={(e) => handleLogin(e)}>
          <div>
            <Input
              title="E-mail"
              placeholder="E-mail"
              label="E-mail"
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="false"
            />
          </div>
          <div>
            <Input
              type="password"
              title="Senha"
              placeholder="Senha"
              label="Senha"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="false"
            />
          </div>
          <div>
            <p>
              Não está conseguindo acessar?{" "}
              <span onClick={handleForgetPassword}>Redefina a sua senha</span>
            </p>
          </div>
          <div>
            <Button type="submit" color="success" loading={loading}>
              Entre em sua conta
            </Button>
          </div>
          <div>
            <h6>Plataforma Educacione v1.0 - Todos os direitos reservados</h6>
          </div>
        </form>
      </main>
      {showRecaptcha && (
        <div style={{ color: "red", marginBottom: "16px" }}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
            size="invisible"
            onErrored={() => console.log(`error: void`)}
            onExpired={() => console.log(`expired: void`)}
          />
        </div>
      )}
      <footer>
        <a
          href="https://www.pxtecnologia.com.br"
          target="_blank"
          rel="noreferrer"
        >
          <p>desenvolvido por:</p>
          <img src={px} alt="PX Tecnologia" />
        </a>
      </footer>
    </Container>
  );
};

export default Login;
