import styled from 'styled-components';

interface IInputSelectValidateProps {
  padding?: string;
  margin?: string;
  onlyWhite?: boolean | false;
}

export const Container = styled.div<IInputSelectValidateProps>`
  position: relative;
  line-height: 14px;
  display: inline-block;
  width: 100%;
  padding: ${props => props.padding ? props.padding : null};
  margin: ${props => props.margin ? props.margin : null};

  > span {
    background: #090029;
    color: #fff;
    font-size: 10px;
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 5px);
    transform: rotate(90deg);
  }
  > select {
    width: 100%;
    padding: 1rem 1.5rem;
    border-radius: 12px;
    border: 1px solid #3e3f5e;
    color: #fff;
    background: #090029;
    font-size: 14px;
    font-weight: 600;
    transition: .3s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: '';
    &::-ms-expand {
      display: none;
    }
    &:focus {
      border-color: #615dfa;
    }
    &:valid + label {
      font-size: .8rem;
      transform: translateY(-1.7rem);
    }
    &:invalid + label {
      transform: translateY(0);
    }
    &:focus + label {
      font-size: .8rem;
      transform: translateY(-1.7rem);
    }
  }
`;

export const Label = styled.label<IInputSelectValidateProps>`
  color: #fff;
  font-size: .8rem;
  font-weight: 600;
  position: absolute;
  top: 20px;
  left: 1rem;
  padding: 0 5px;
  pointer-events: none;
  background: #090029;
  transition: .3s ease;
  transform: translateY(-1.7rem);
`;

export const Erro = styled.p`
  color: #ff6961;
  text-align: left;
  padding: .25rem 0 .25rem .25rem;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  > span {
    margin-right: .5rem;
  }
`;
