import styled from 'styled-components';

export const ModalDisciplineDetailsContent = styled.div`
  position: relative;
  width: 100%;

  > section {
    margin: -1rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > h1 {
      margin: 0 0 1rem 0;
    }

    > form  {
      width: 100%;

      > div {
        margin: 1.25rem 0;

        input {
          padding: 0.75rem 1.5rem;
        }

        > button {
          width: 100%;
          background: rgb(3, 187, 133);
          color: rgb(255, 255, 255);
          border: none;
          border-radius: 7px;
          padding: 0.9rem 0px;
          text-align: center;
          justify-content: center;
          font-weight: bold;
          align-items: center;
          font-size: 0.75rem;
          box-shadow: rgb(94 92 154 / 6%) 0px 0px 40px 0px;
          text-transform: uppercase;
          margin: 0 0 -1rem 0;
        }
      }
    }
  }
`;

export const Error = styled.h5`
  color: #f72020;
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
