import styled, { css } from 'styled-components';

interface IHetaryGroupProps {
  age_group:
  | '18'
  | '16'
  | '14'
  | '12'
  | '10'
  | 'L'
}

const colorVariations = {
  18: css`
    background: #191311;
    color: #fff;
  `,
  16: css`
    background: #e2332e;
    color: #fff;
  `,
  14: css`
    background: #ee772d;
    color: #fff;
  `,
  12: css`
    background: #fab02b;
    color: #fff;
  `,
  10: css`
    background: #5ba0bd;
    color: #fff;
  `,
  L: css`
    background: #32a540;
    color: #fff;
  `,
};

export const Container = styled.div<IHetaryGroupProps>`
  background: red;
  ${props => colorVariations[props.age_group]}
  height: 22px;
  font-size: 12px;
  width: 22px;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin-left: .5rem;
  padding: 0 5px;
  font-weight: bold;
`;
