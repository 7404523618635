import styled from 'styled-components';

interface IProgressBarProps {
  percentage: number;
  width: string;
  height: string;
  bgColor: string;
  barColor: string;
  trackBorderColor: string;
  rectBorderRadius?: string;
  animate: boolean;
}

export const Container = styled.div<IProgressBarProps>`
  background: ${props => props.bgColor};
  height: ${props => props.height};
  width: ${props => props.width};
  transition: ${props => props.animate ? '1.5s all ease-in-out' : 'unset'};
  border-radius: ${props => props.rectBorderRadius ? props.rectBorderRadius : '0px'};
  overflow: hidden;


  > main {
    background: ${props => props.barColor};
    height: 100%;
    width: ${props => props.percentage}%;
    transition: ${props => props.animate ? '1.5s all ease-in-out' : 'unset'};
    border-radius: ${props => props.rectBorderRadius ? props.rectBorderRadius : '0px'};
  }
`;
