import styled from 'styled-components';

interface ITaskProps {
  background: string;
  completed: boolean;
}

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 .5rem !important;

  .fade-overlay {

    /* &:after {
      content: '';
      height: 50px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 0 0 10px 10px;
      background-image: linear-gradient(to bottom,transparent 0%,#090029 100%);
    } */
  }

  > h4 {
    display: flex;
    justify-content: space-between;
    color: #fff;
    width: 100%;
    padding: 0 .75rem .2rem .75rem;
    margin: 1rem 0 0 0;

    > button {
      display: flex;
      align-items: center;
      border: none;
      background: transparent;
      font-weight: 700;
      font-size: 11px;
      color: #e32828;
    }
  }

  > h2 {
    text-align: left;
    padding: 0 .5rem;
    display: flex;
  }

  > form {

    > div {
      margin: 2rem 0 0 0;
      display: flex;
      flex-direction: column;

      > header {
        display: flex;

        > button {
          background: #615dfa;
          border-radius: 0 6px 6px 0;
          margin-left: -16px;
          padding: 0 16px;
          z-index: 9999;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          color: #fff;
          text-transform: uppercase;
          font-weight: bold;
          transition: all .2s ease-in-out;

          &:hover {
            background: #3531eb;
          }
        }

        > div {

          > input {
            padding: .75rem 1rem;
            font-size: 14px;
            background: #090029;
            border: 1px solid #3e3f5e;
            color: #fff;
            border-radius: 6px;
            text-transform: uppercase !important;

            &:focus {
              border-color: #615dfa;
            }

            ::placeholder {
              color: #adafca;
              opacity: 1;
              text-transform: none !important;
            }
          }

          > label {
            background: #090029;
            color: #fff;
          }
        }
      }
    }
  }

  > main {
    min-height: 100px;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 0 .5rem;

    &::-webkit-scrollbar {
      visibility: hidden;
      width: 4px;
      height: 4px;
    }

    &:hover::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      opacity: 1;
      transition: all .3s ease-in-out;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: #615dfa9e;
      border-radius: 10px;
    }

    &:hover::-webkit-scrollbar-thumb:hover{
    }

    &:hover::-webkit-scrollbar-track{
      border-radius: 10px;
      box-shadow: inset 7px 10px 12px #615dfa9e;
    }
  }
`;

export const Task = styled.div<ITaskProps>`
  background: ${props => props.background};
  width: 100%;
  margin: .25rem .15rem .25rem 0;
  border-radius: 4px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75rem 1rem;
  transition: overlay .3s ease-in-out;
  gap: .5rem;

  &:last-child {
    margin-bottom: 1.5rem;
    animation: 2s ease 0s infinite normal none running pulse;
  }

  @keyframes pulse {
    0% {
      box-shadow: ${props => props.background} 0px 0px 0px 0px;
    }
    70% {
      box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px .5rem;
    }
    100% {
      box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
  }

  > main {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    > label {
      display: flex;
      align-items: center;
      text-transform: uppercase !important;
      text-decoration: ${props => props.completed ? 'line-through' : 'unset'};
      gap: .5rem;
      cursor: pointer;

      > input {
        cursor: pointer;
      }
    }
  }

  > aside {
    height: 100%;
    display: flex;
    align-items: center;
    gap: .30rem;

    > button {
      display: flex;
      background: transparent;
      border: none;
      z-index: 99999999;
    }
  }
`;

export const ValidationError = styled.p`
  color: #e32828;
  margin-top: 1rem;
  font-weight: 600;
  font-size: 12px;
`;
