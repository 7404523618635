import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;

  .active {
    color: #ffffff;
    background: ${props => props.theme.colors.primary};
  }
`;

export const MainContainer = styled.section`
  position: relative;
  width: 100%;
  padding: 2rem !important;
  color: ${props => props.theme.colors.textColor};
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* TABLET */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* TABLET */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      > li {
        margin: .25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${props => props.theme.colors.textColor};
          transition: all .25s ease-in-out;
          padding: 1rem;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${props => props.theme.colors.primary};
          }

          > svg {
            margin-right: .5rem;
          }
        }
      }
    }
  }


  > main {
    display: flex;
    flex-direction: column;

    > section:nth-child(1) {
      border-radius: 10px;
      padding: 1rem;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};
    }

  }
`;

/*  */
export const PuBliCard = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  transition: all .45s ease-in-out;

  .blink-animation {
    animation: blink-animation .45s infinite alternate;
  }

  @keyframes blink-animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  > p {
    margin: 0.25rem 0 0 0.5rem !important;
  }

  > a {
    text-decoration: none;
    width: 100%;

    > img {
      width: 100%;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  > p {
    margin-top: 1rem;
    font-size: 12px;
    margin-left: 0.5rem;
  }
`;

export const MainContent = styled.main`
  position: relative;

  > p {
    text-align: justify;
    margin-bottom: 0.5rem;
  }

  > h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  > h3 {
    margin-bottom: 1rem;
  }

  > ul {
    list-style: none;
    margin-bottom: 2rem;

    > li {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      font-weight: 800;
    }

    > p {
      margin-left: 2rem;
      margin-bottom: 1rem;
    }
  }
`;

export const Footer = styled.footer`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }

  > aside {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      > h5 {
        margin: 0;
        font-size: 16px;
      }

      > p {
        font-size: 12px;
        margin: 0;
      }
    }
  }

  > main {
    position: relative;

    @media (max-width: 768px) {
      width: 100%;
    }

    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      user-select: none;
      border-radius: 0.375rem;
      font-weight: 600;
      font-size: 14px;
      transition: all .3s ease-in-out;
      line-height: 0;
      padding: 1.5rem 2rem;
      cursor: pointer;
      width: 100%;
      background: #6c23c0;
      color: #fff;
      text-decoration: none;

      &:hover {
        background: #4f46e5;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

export const PlayerContainer = styled.section`
  position: relative;
  width: 100%;
  cursor: pointer;
  margin: 2rem 0;

  > main {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 450px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.5rem;

    @media (max-width: 768px) {
      min-height: 280px;
    }

    @media (max-width: 500px) {
      min-height: 180px;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;
      border-radius: 50%;
      background: #ffffffc7;
      backdrop-filter: blur(2px);
      box-shadow: 0rem 0rem 1.25rem rgb(30 34 40 / 4%);
      animation: 2s ease 0s infinite normal none running pulse;

      @keyframes pulse {
        0% {
          box-shadow: rgb(255 255 255 / 50%) 0px 0px 0px 0px;
        }

        70% {
          box-shadow: rgb(255 255 255 / 0%) 0px 0px 0px 1rem;
        }
        100% {
          box-shadow: rgb(255 255 255 / 0%) 0px 0px 0px 0px;
        }
      }
    }
  }
`;
