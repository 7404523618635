import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {

  }

  /* MOBILE */
  @media (max-width: 480px) {

  }

  > h1 {
    font-size: 2rem;
    line-height: 1;
    color: ${props => props.theme.colors.textColor};
  }

  > h6 {
    font-size: 1rem;
    line-height: 1;
    text-transform: uppercase;
    color: #adafca;
    font-size: .75rem;
  }
`;

export const ContentGrid1 = styled.div`
  position: relative;
  display: grid;
  gap: 1rem;
  margin: 0 0 1rem 0;
  grid-template-columns: 6fr 3fr;
  min-height: 200px;

  /* TABLET */
  @media (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > aside:nth-child(1) {
    border-radius: 12px;
    overflow: hidden;

    > main {
      position: relative;
      width: 100%;

    }

    > footer {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 1.5rem;
      background: rgb(110 97 198 / 12%);
      border-radius: 0 0 12px 12px;
      justify-content: space-between;

      > aside:nth-child(1){
        display: flex;

        div:nth-child(1) {
          display: flex;
          align-items: center;

          > span {
            font-size: 14px;
            font-weight: 700;
            color: #adafca;
            margin-left: 10px;
            line-height: 1;
          }

          > img {
            width: 20px;
          }
        }

        div:nth-child(2) {
          margin-left: 1rem;
          display: flex;
          align-items: center;

          > ul {
            list-style: none;
            display: flex;
            align-items: center;

            > li {
              display: inline-flex;
              align-items: center;
              margin-right: 5px;

              > img {
                width: 18px;
              }

              > a {
                font-size: 12px;
                font-weight: 700;
                color: #adafca;
                margin-left: 5px;
                line-height: 1;
                text-transform: uppercase;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      > aside:nth-child(2) {
        display: flex;
        position: relative;

        > main {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index:999;
        }
      }
    }

  }

  > aside:nth-child(2) {
    display: flex;
    flex-direction: column;
    max-height: 456px;
    overflow-y: scroll;
    padding-right: .5rem;

    &::-webkit-scrollbar {
      visibility: hidden;
      opacity: 0;
      width: 4px;
      height: 4px;
      transition: all 4s ease-in-out;
    }

    &:hover::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      opacity: 1;
      transition: all .3s ease-in-out;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: #cccccc80;
      border-radius: 10px;
    }
    &:hover::-webkit-scrollbar-thumb:hover{

    }
    &:hover::-webkit-scrollbar-track{
      border-radius: 10px;
      box-shadow: inset 7px 10px 12px #cccccc10;
    }

    /* TABLET */
    @media (max-width: 1200px) {
      display: grid;
      grid-gap: .5rem;
      grid-template-columns: repeat(2, 2fr);
      max-height: unset;
      overflow: visible;
      padding: 0;

      > div {
        margin: 0;
      }
    }
    /* MOBILE */
    @media (max-width: 1000px) {
      display: grid;
      grid-gap: .5rem;
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const YoutubeVideoContainer = styled.div`
  overflow: hidden;
  position: relative;
  width:100%;
  border-radius: 12px 12px 0 0;

  > a {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #060e2ba8;
    z-index: 99999;
    text-decoration: none;
    transition: .2s all ease-in-out;

    &:hover {
      background: #060e2b40;
    }

    > p {
      border: 2px solid #ffffff;
      color: #ffffff;
      border-radius: 4px;
      padding: 10px 18px;
      background: #7b6fca;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      opacity: 1;
    }
  }

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > div {

    > canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //background: #f8f8fb;
    }
  }

`;
