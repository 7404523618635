import React from 'react';
import { IconBaseProps } from 'react-icons';

import { Badge, Fake } from './styles';

interface MenuItemFakeProps {
  title: string;
  location: string;
  icon: React.ComponentType<IconBaseProps>;
  className?: string;
  badge?: string;
  onClick: () => void;
}

const MenuItemFake: React.FC<MenuItemFakeProps> = ({
  location,
  title,
  icon: Icon,
  className,
  badge,
  onClick,
}) => {

  return (
    <Fake className={className} onClick={onClick}>
      {Icon && <Icon size={20} strokeWidth={2.5} />}
      {title} {badge && <Badge>{badge}</Badge>}
    </Fake>
  );
};

export default MenuItemFake;
