import React, { useCallback, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '../../hooks/theme';

import api from '../../services/api';

import ReactStars from 'react-stars';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FiEdit, FiX } from 'react-icons/fi';
import ReactPlayer from 'react-player';
import ReactMarkdown from 'react-markdown';

import HetaryGroup from '../MovieCard/HetaryGroup';
import MovieCardOnlyCover from '../MovieCardOnlyCover';

import { Container, MainContent, Chanels, ModalMoviesDetails, ModalMoviesRating, Head } from './styles';

import truncateStrings from '../../utils/truncateStrings';
import ModalMovieReview from './ModalMovieReview';

interface IReviewProps {
  id: string;
  user_id: string;
  movie_id: string;
  rating: number;
  notes: string;
  created_at: string;
  updated_at: string;
}
interface IMainHeaderProps {
  id: string;
  cover_url: string;
  poster_url: string;
  trailer: string;
  age_group: '18' | '16' | '14' | '12' | '10' | 'L';
  rating: number;
  name: string;
  tagline: string;
  year: number | string;
  description: string;
  duration: string;
  channels?: {
    id: string;
    name: string;
    link: string;
    logo: string;
    logo_url: string;
    created_at: string;
    updated_at: string;
  }[];
  genres?: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
  }[];
  review?: IReviewProps;
}


const MainHeaderMovie: React.FC<IMainHeaderProps> = ({ id, cover_url, poster_url, trailer, rating, age_group, name, tagline, year, description, duration, genres, channels, review }) => {
  const history = useHistory();
  const { setThemeToDark } = useTheme();

  const [reviewState, setReviewState] = useState(review);

  useEffect(() => {
    setReviewState(review || undefined);
  }, [review, id]);

  useEffect(() => {
    setThemeToDark();
  }, [setThemeToDark]);


  const handleOpenDetailsModal = useCallback(() => {
    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        <ModalMoviesDetails>
          <main>
            <section>
              <aside>
                <ReactPlayer url={`https://www.youtube.com/watch?v=${trailer}`}
                  //light={true}
                  width="100%"
                  height="450px"
                  config={{
                    youtube: {
                      playerVars: { showinfo: 0, autoplay: 1, }
                    },
                  }}
                />
              </aside>
            </section>
          </main>
        </ModalMoviesDetails>
      ),
      background:'#fff',
      backdrop: '#1d23334e',
      showConfirmButton: false,
      showCloseButton: true,
      showCancelButton: false,
      allowOutsideClick: true,
      allowEscapeKey : true,
      padding: 32,
      width: '800px',
    });
  }, [trailer]);

  const handleOpenRatingModal = useCallback((rating?: number, comments?: string) => {
    console.log('rating 1:', rating);

    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        <ModalMovieReview id={id} rating={rating || 0} comments={comments} setReviewState={setReviewState} />
      ),
      background:'#090029',
      backdrop: '#1d23334e',
      showConfirmButton: false,
      showCloseButton: true,
      showCancelButton: false,
      allowOutsideClick: true,
      allowEscapeKey : true,
      padding: 32,
    });
  }, [id]);

  return (
    <>
      <Container cover_url={cover_url} poster_url={poster_url}>
      <Head>
          <main>
            <section>
              <button onClick={() => history.push('/')}>
                <FiX size={25} strokeWidth={2.5} />
              </button>
            </section>
          </main>
        </Head>
        <MainContent>
          <section>
            <aside>
              <MovieCardOnlyCover
                onClick={handleOpenDetailsModal}
                cover={poster_url}
                height="450px"
              />
            </aside>
            <main>
              {name && <h1>{name} <span>({year})</span></h1>}
              <h2>
                {genres?.map((genre, index) => (
                  <>
                    {genre.name} {(genres.length > 1) && (genres.length - 1 !== index) && <span>, </span>}
                  </>
                ))}
               • {duration}
              </h2>
              <div>
                <ReactStars
                  key={1}
                  count={5}
                  size={25}
                  color1="#adafca"
                  color2="#d3df37"
                  half={true}
                  value={reviewState ? reviewState.rating/20 : 0} //puxar do banco aqui
                  onChange={(new_rating: number) => {handleOpenRatingModal(new_rating, reviewState?.notes)}}
                />
                <HetaryGroup age_group={age_group} />
                <button onClick={handleOpenDetailsModal}>Assista ao trailer</button>
              </div>
              <h4>{tagline}</h4>
              {!reviewState &&
                <>
                  <h5>Sinopse</h5>
                  <p>{truncateStrings(description, 400)}</p>
                </>
              }
              <main>
                {reviewState ?
                  <div>
                    <button onClick={() => handleOpenRatingModal(undefined, reviewState.notes)}><FiEdit size={14} strokeWidth={2.5} /></button>
                    <h5>Suas observações</h5>
                    <p style={{whiteSpace: 'pre-wrap'}}>{reviewState.notes}</p>
                  </div>
                  :
                  <button onClick={() => handleOpenRatingModal()}>😱 Você ainda não fez observações sobre este filme!</button>
                }
              </main>
              {channels && channels?.length > 0 && (
                <>
                  <h5 style={{marginTop: '2rem'}}>Assista agora</h5>
                  <Chanels>
                    {channels?.map((chanel, index) => (
                      <a key={index} href={chanel.link} target="_blank" rel="noreferrer">
                        <img src={chanel.logo_url} alt={chanel.name} />
                      </a>
                    ))}
                  </Chanels>
                </>
              )}
            </main>
          </section>
        </MainContent>
      </Container>
    </>
  );
}

export default MainHeaderMovie;
