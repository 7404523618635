import styled from 'styled-components';

interface IMovieCardProps {
  cover: string;
  height?: string;
}

export const Container = styled.div<IMovieCardProps>`
  position: relative;

  > main {
    position: relative;
    background-image: url(${props => props.cover && props.cover});
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100%;
    background-position: center center;
    width: 100%;
    height: ${props => props.height ? props.height : '350px'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;
    box-shadow: ${props => props.theme.colors.shadowBox};
    transition: all .2s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      inset: 0px;
      background-image: linear-gradient(to bottom, transparent 10%, ${props => props.theme.colors.bgBox} 100%);
      opacity: 0.5;
      transition: all .3s ease-in-out 0s;
      border-radius: 7px;
    }

    &:hover {
      background-size: 110%;

      &::before {
        content: "";
        position: absolute;
        inset: 0px;
        background-image: linear-gradient(to bottom, transparent, ${props => props.theme.colors.bgBox} 100%);
        opacity: 1;
        transition: all 0.3s ease-in-out 0s;
        border-radius: 0;
        border-radius: 7px;
      }

      > div {
        background: ${props => props.theme.colors.primary};
        border: 4px solid #ffffff;
        color: #ffffff;
      }
    }

    > div {
      width: 120px;
      height: 120px;
      border: 4px solid #ffffff77;
      color: #ffffff77;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      cursor: pointer;
      transition: all .3s ease-in-out;
      position: absolute;
      top: 40%;

      &:hover {
        background: ${props => props.theme.colors.primary};
        border: 4px solid #ffffff;
        color: #ffffff;
      }
      /* TABLET */
      @media (max-width: 768px) {
        transform: scale(.8);
      }
      /* MOBILE */
      @media (max-width: 480px) {
        transform: scale(.6);
      }
    }
  }

  > header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: red;
    z-index: 9;

    > p {

    }
  }

  > footer {
    position: relative;
    z-index: 9;
    margin-top: .5rem;

    > h1 {
      color: ${props => props.theme.colors.textColor};
      font-size: 14px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;


export const ModalMoviesDetails = styled.div`
  display: flex;
  margin: -32px -53px;
  overflow: hidden;

  > main {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: center center;
    background-size: cover;
    overflow: hidden;

    &:after {
      content: '';
      background: #21114780;
      position: absolute;
      width: 110%;
      height: 110%;
      left: -15px;
      top: -15px;
      backdrop-filter: blur(5px);
      border-radius: 10px;
    }

    > section {
      z-index: 999;
    }
  }

`;
